import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

const DATE_PATTERN = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/\d{4}$/;

export function DateFormatValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const value = control.value;
        if (!value) {
            // Return null for empty values (no validation error)
            return null;
        }

        // Check if the value matches the date pattern
        if (!DATE_PATTERN.test(value)) {
            // If it doesn't match, return a validation error object
            return { 'dateFormat': true };
        }

        // If the value matches the pattern, it's a valid date
        return null;
    }
}