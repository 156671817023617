import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { AppSettingsService } from '../shared/services/app-settings.service';
import { HttpClient } from '@angular/common/http';

import { ExchangeCompanyVariation } from './models/exchange-company-variation.model';

@Injectable({
  providedIn: 'root'
})
export class ExchangeService {


  constructor(private http: HttpClient, private appSettings: AppSettingsService) { }

  public getCompanyVaritaionsFromAPI(): Observable<ExchangeCompanyVariation[]> {
    return this.http.get<ExchangeCompanyVariation[]>(this.appSettings.API_ENDPOINT + '/exchange');
  }

  public getCompanyByNameFromAPI(company: string): Observable<ExchangeCompanyVariation[]> {
    return this.http.get<ExchangeCompanyVariation[]>(this.appSettings.API_ENDPOINT + '/exchange/get-company?name=' + company);
  }

  public getCallCenterId(agentName: string): Observable<number> {
    return this.http.get<number>(this.appSettings.API_ENDPOINT_ENROLLMENT + '/agents?name=' + agentName);
  }
}
