import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

const EMAIL_PATTERN = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,3}$/;

export function EmailFormatValidator(): ValidatorFn {

  // The outer function returns an inner function, which is the actual validator function.
  return (control: AbstractControl): ValidationErrors | null => {

    const value = control.value;
    if (!value) {
        // Return null for empty values (no validation error)
        return null;
    }

    // Check if the value matches the date pattern
    if (!EMAIL_PATTERN.test(value)) {
        // If it doesn't match, return a validation error object
        return { 'emailFormat': true };
    }

    // If the value matches the pattern, it's a valid date
    return null;
  };
}