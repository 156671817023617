import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { AppSettingsService } from './app-settings.service';
import { Observable } from 'rxjs';
import { Geo } from '../models/geo.model';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  constructor(private http: HttpService,
    private appSettings: AppSettingsService) { }

  public getGeoFromAPI(zip: number): Observable<Geo[]> {
    return this.http.get(this.appSettings.GEO_API_ENDPOINT + '/geo?zip=' + zip)
      .pipe(
        map(response => {
          return <Geo[]>response;
        }));
  }

  public getDefaultGeoFromAPI(stateAbbr: string): Observable<Geo[]> {
    return this.http.get(this.appSettings.GEO_API_ENDPOINT + '/geo/default?stateAbbr=' + stateAbbr)
      .pipe(
        map(response => {
          return <Geo[]>response;
        }));
  }
}
