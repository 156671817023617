import { Component, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer-exchange',
    templateUrl: './footer-exchange.component.html',
    styleUrls: ['./footer-exchange.component.scss'],
    standalone: true,
    imports: [DatePipe]
})
export class FooterExchangeComponent implements OnInit {
  public currentDate = new Date();
  constructor() { }

  ngOnInit(): void {
  }

}
