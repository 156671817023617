<!-- Common Procedures -->
<section class="common-procedures mb-10 mt-10">
  <div class="plan-subtitles-w-caret-container" (click)="togglePlanDetailsElement(planBodyCommonProcedure, imgElementCommonProcedure)">
    <span class="subtitle-text">Common Procedures</span>
    <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementCommonProcedure/>
  </div>
  <div class="procedures-table" #planBodyCommonProcedure>
    <div class="procedures-item">
      <span class="procedure">Eye Exams:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.eyeExams}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Rx Frames:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.rxFrames}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Rx Lenses:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.rxLenses}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Contact Lenses:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.contactLenses}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Notes:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.benefitsNotes}}</span>
    </div>
  </div>
</section>
<!-- Additional Details -->
<section class="additional-details mb-10">
  <div class="plan-subtitles-w-caret-container" (click)="togglePlanDetailsElement(planBodyDetails, imgElementDetails)">
    <span class="subtitle-text">Details</span>
    <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementDetails/>
  </div>
  <div #planBodyDetails>
    <div class="procedures-table">
      <div class="procedures-item">
        <span class="procedure">Dentis Search:</span>
        <div class="description" *ngFor="let plan of plans">
          <a class="dentist-search" *ngIf="plan.doctorSearchUrl" href="{{plan.doctorSearchUrl}}" target="_blank">Provider
            Search</a><span *ngIf="!plan.doctorSearchUrl">Provider Search: N/A</span>
        </div>
      </div>
      <div class="procedures-item">
        <span class="procedure">Eye Exams:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.eyeExamsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Frames:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.framesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Basic Lenses:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.basicLensesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Lens Enhancements:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.lensEnhancementsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Contact Lenses:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.contactLensesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Additional Network Information:</span>
        <span class="description" *ngFor="let plan of plans">
          <span [innerHTML]="plan.doctorNetworkDetails"></span>
          <a *ngIf="plan.exclusionsAndLimitationsUrl"
            href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/exclusions_and_limitations/{{plan.exclusionsAndLimitationsUrl}}"
            target="_blank">Exclusions and Limitations</a>
          <span *ngIf="!plan.exclusionsAndLimitationsUrl">Exclusions and Limitations: N/A</span>
          <br />
          <ng-container
            *ngIf="!(plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'ameritasny')">
            <a *ngIf="plan.privacyPolicyUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/privacy_policies/{{plan.privacyPolicyUrl}}"
              target="_blank">Privacy Policy</a>
            <span *ngIf="!plan.privacyPolicyUrl">Privacy
              Policy: N/A</span>
          </ng-container>
          <br />
          <a *ngIf="plan.planBrochureUrl"
            href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/plan_brochures/{{plan.planBrochureUrl}}"
            target="_blank">Plan Brochure</a>
        </span>
      </div>
      <div class="procedures-item" *ngFor="let plan of plans">
        <ng-template *ngIf="plan.benefitsDetailsNotes">
          <span class="procedure">Notes:</span>
          <span class="description" [innerHTML]="plan.benefitsDetailsNotes"></span>
        </ng-template>
      </div>
    </div>
  </div>
</section>