import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CanonicalService {

  private readonly mainDomain = 'https://www.dentalinsurance.com';

  constructor(@Inject(DOCUMENT) private dom: any) { }

  createCanonicalURL(url: string) {
    let oldlink: HTMLLinkElement = this.dom.querySelector('link[rel=canonical]');
    if (oldlink) {
      oldlink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', this.mainDomain + (url === '/'? '' : url));
  }
}
