import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'OnlyOneError',
    standalone: true
})
export class OnlyOneErrorPipe implements PipeTransform {

    // The transform method is required for all Angular pipes.
    // It takes two arguments:
    // - allErrors: An object containing multiple error messages.
    // - errorsPriority: An array specifying the priority order of error messages.

    transform(allErrors: any, errorsPriority: string[]): any {

        // Check if the input 'allErrors' object is null or undefined.
        if (!allErrors) {
            // If 'allErrors' is falsy, return null
            return null;
        }

        // Initialize an empty object to hold the single highest-priority error.
        const onlyOneError: any = {};

        // Loop through the 'errorsPriority' array to find the first error that exists in 'allErrors'.
        for (let error of errorsPriority) {
            if (allErrors[error]) {
                // If an error with the specified key 'error' exists in 'allErrors':
                // - Add it to the 'onlyOneError' object.
                // - Use 'break' to exit the loop, as we want to prioritize the first error found.
                onlyOneError[error] = allErrors[error];
                break;
            }
        }

        // Return the 'onlyOneError' object, which contains only the highest-priority error found.
        return onlyOneError;
    }
}