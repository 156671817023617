import { AbstractControl, FormGroup, ValidationErrors, ValidatorFn } from "@angular/forms";
import { ChildForm } from "src/app/plan-info/census-popup/census-popup.component";

// Define a constant representing the maximum age for a not-disabled child 
const MAX_CHILD_AGE = 26;

// Define a custom Angular Reactive Forms validator function for child age validation.
export function childAgeValidator(): ValidatorFn {

    // The validator function takes a formGroup (AbstractControl) as its input.
    return (formGroup: AbstractControl): ValidationErrors | null => {

        // Cast the formGroup to a FormGroup with the expected structure (ChildForm).
        const childForm = formGroup as FormGroup<ChildForm>;

        // Retrieve the 'dob' (date of birth) and 'isDisabled' form controls from the child form.
        const dobControl = childForm.controls.dob;
        const disabledControl = childForm.controls.isDisabled;

        // Calculate the age based on the provided date of birth (dobControl).
        const age = new Date().getFullYear() - new Date(dobControl.value as string).getFullYear();

        // Get the value of the 'isDisabled' control to determine if the child is disabled.
        const isDisabled = disabledControl.value;

        // Check if the child is not disabled and their age exceeds the maximum allowed age.
        if (!isDisabled && age > MAX_CHILD_AGE) {

            // Set a validation error on the 'dob' control with the key 'ageTooHigh'.
            dobControl.setErrors({ ...dobControl.errors, 'ageTooHigh': true });

            // Return a validation error object indicating that the age is too high.
            return { 'ageTooHigh': true };
        } else {

            // If the age is within the allowed range, clear any previous validation errors on 'dob'.

            // Get a copy of the current errors object
            const currentErrors = { ...dobControl.errors };

            // Remove the 'customError' property from the errors object
            delete currentErrors['ageTooHigh'];

            // Set the updated errors object back to the control
            dobControl.setErrors(Object.keys(currentErrors).length > 0 ? currentErrors : null);

            // Return null to indicate that there are no validation errors.
            return null;
        }
    };
}