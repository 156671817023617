<div *ngIf="loading" class="loader"></div>
<app-census-popup *ngIf="showCensusPopup" (popupClosed)="onCensusPopupClosed()"></app-census-popup>
<main class="section no-page-title">
  <section class="section" id="plans">
    <div class="section-wrapper block content-1170 center-relative" id="container">
      <div class="blocks-section">

        <!-- filters section -->
        <div class="flex filters-section" id="filter-fixed">
          <section id="filters">
            <div class="back-button-container">
              <div class="gray-button" id="back-button">
                <span><i class="fas fa-chevron-left"></i>Back</span>
              </div>

            </div>
            <div class="product-selection" *ngIf="filter && productsAvailable && productsAvailable.length > 1">
              <h2>Product Type</h2>
              <div class="grouped-buttons">
                <a class="button-holder" [ngClass]="
                    filter.productFilter[0].value === products.DENTAL
                      ? 'grouped-btn-selected'
                      : 'grouped-btn'
                  " name="btnSelectDental" id="btnSelectDental" (click)="onSelectDentalClick()">Dental</a>
                <a class="button-holder" [ngClass]="
                    filter.productFilter[0].value === products.VISION
                      ? 'grouped-btn-selected'
                      : 'grouped-btn'
                  " name="btnSelectVision" id="btnSelectVision" (click)="onSelectVisionClick()">Vision</a>
              </div>
            </div>
            <div class="product-selection-mobile" *ngIf="filter && productsAvailable && productsAvailable.length > 1">
              <h2>Product Type</h2>
              <div class="grouped-buttons">
                <a class="button-holder" [ngClass]="
                    filter.productFilter[0].value === products.DENTAL
                      ? 'grouped-btn-selected'
                      : 'grouped-btn'
                  " name="btnSelectDental" id="btnSelectDental" (click)="onSelectDentalClick()">Dental</a>
                <a class="button-holder" [ngClass]="
                    filter.productFilter[0].value === products.VISION
                      ? 'grouped-btn-selected'
                      : 'grouped-btn'
                  " name="btnSelectVision" id="btnSelectVision" (click)="onSelectVisionClick()">Vision</a>
              </div>
            </div>
            <div class="filter-header">
              <h2>Filter</h2>
              <button class="clear-button" (click)="clearAllFilters()">Clear All</button>
            </div>
            <div class="dropdowns">
              <div class="filters-container" id="providers">
                <div class="providers-header" (click)="toggleMenuBody(menuBodyProviders, imgElementProvider)">
                  <span>Carriers</span>
                  <img src="../../../assets/img/svg/arrow-up.svg" alt="up" #imgElementProvider />
                </div>
                <div #menuBodyProviders>
                  <div class="items-container" *ngIf="filter && filter.carriersFilter">
                    <div class="filter-item" *ngFor="let carrier of filter.carriersFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="carrier.isChecked"
                          (ngModelChange)="filterSelected()" [disabled]="getPlanCountByCarrier(carrier.label) == 0" />
                        {{ carrier.label }} ({{getPlanCountByCarrier(carrier.label)}})</label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="filter.productFilter[0].value === products.DENTAL" class="filters-container" id="plan-types">
                <div class="providers-header" (click)="toggleMenuBody(menuBodyPlanTypes, imgElementPlanTypes)">
                  <span>Plan Types</span>
                  <img src="../../../assets/img/svg/arrow-up.svg" alt="up" style="height: 24px; width: 24px"
                    #imgElementPlanTypes />
                </div>
                <div #menuBodyPlanTypes>
                  <div class="items-container" *ngIf="filter && filter.planTypesFilter">
                    <div class="filter-item" *ngFor="let planType of filter.planTypesFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="planType.isChecked"
                          (ngModelChange)="filterSelected()" [disabled]="getPlanCountByType(planType.value) == 0" />
                        {{ planType.label }} ({{getPlanCountByType(planType.value)}})</label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="filter.productFilter[0].value === products.DENTAL" class="filters-container" id="premium">
                <div class="providers-header" (click)="toggleMenuBody(menuBodyPremium, imgElementPremium)">
                  <span>Premiums</span>
                  <img src="../../../assets/img/svg/arrow-up.svg" alt="up" style="height: 24px; width: 24px"
                    #imgElementPremium />
                </div>
                <div #menuBodyPremium>
                  <div class="items-container" *ngIf="filter && filter.premiumFilter">
                    <div class="filter-item" *ngFor="let premium of filter.premiumFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="premium.isChecked"
                          (ngModelChange)="filterSelected()"
                          [disabled]="getPlanCountByPremium(premium.value.min, premium.value.max) == 0" />
                        {{ premium.label }} ({{getPlanCountByPremium(premium.value.min, premium.value.max)}})</label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="filter.productFilter[0].value === products.DENTAL" class="filters-container" id="plan-max">
                <div class="providers-header" (click)="toggleMenuBody(menuBodyPlanMax, imgElementPlanMax)">
                  <span>Plan Maximum</span>
                  <img src="../../../assets/img/svg/arrow-up.svg" alt="up" style="height: 24px; width: 24px"
                    #imgElementPlanMax />
                </div>
                <div #menuBodyPlanMax>
                  <div class="items-container" *ngIf="filter && filter.planMaxFilter">
                    <div class="filter-item" *ngFor="let planMax of filter.planMaxFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="planMax.isChecked"
                          (ngModelChange)="filterSelected()"
                          [disabled]="getPlanCountByPlanMax(planMax.value.min, planMax.value.max) == 0" />
                        {{ planMax.label }} ({{getPlanCountByPlanMax(planMax.value.min, planMax.value.max)}})</label>
                    </div>
                  </div>
                </div>
              </div>

              <div *ngIf="filter.productFilter[0].value === products.DENTAL" class="filters-container"
                id="plan-benefits">
                <div class="providers-header" (click)="toggleMenuBody(menuBodyPlanBenefits, imgElementPlanBenefits)">
                  <span>Specialty Procedures</span>
                  <img src="../../../assets/img/svg/arrow-up.svg" alt="up" style="height: 24px; width: 24px"
                    #imgElementPlanBenefits />
                </div>
                <div #menuBodyPlanBenefits>
                  <!-- <div class="items-container" *ngIf="filter && filter.implantBenefitFilter">-->
                  <div class="filter-item" *ngFor="let planBenefit of filter.implantBenefitFilter">
                    <label>
                      <input name="filterItem" type="checkbox" [(ngModel)]="planBenefit.isChecked"
                        (ngModelChange)="filterSelected()" [disabled]="getPlanCountByImplantBenefit() == 0" />
                      {{ planBenefit.label }} ({{getPlanCountByImplantBenefit()}})</label>
                  </div>
                  <!-- </div> -->
                  <!--  <div class="items-container" *ngIf="filter && filter.childOrthoBenefitFilter">-->
                  <ng-container *ngIf="!exchangeCompany">
                    <div class="filter-item" *ngFor="let planBenefit of filter.childOrthoBenefitFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="planBenefit.isChecked"
                          (ngModelChange)="filterSelected()" [disabled]="getPlanCountByChildOrthoBenefit() == 0" />
                        {{ planBenefit.label }} ({{getPlanCountByChildOrthoBenefit()}})</label>
                    </div>
                    <!--</div>-->
                    <!-- <div class="items-container" *ngIf="filter && filter.adultOrthoBenefitFilter">-->
                    <div class="filter-item" *ngFor="let planBenefit of filter.adultOrthoBenefitFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="planBenefit.isChecked"
                          (ngModelChange)="filterSelected()" [disabled]="getPlanCountByAdultOrthoBenefit() == 0" />
                        {{ planBenefit.label }} ({{getPlanCountByAdultOrthoBenefit()}})</label>
                    </div>
                    <!-- </div>-->
                    <!-- <div class="items-container" *ngIf="filter && filter.teethWhiteningBenefitFilter">-->
                    <div class="filter-item" *ngFor="let planBenefit of filter.teethWhiteningBenefitFilter">
                      <label>
                        <input name="filterItem" type="checkbox" [(ngModel)]="planBenefit.isChecked"
                          (ngModelChange)="filterSelected()" [disabled]="getPlanCountByTeethWhiteningBenefit() == 0" />
                        {{ planBenefit.label }} ({{getPlanCountByTeethWhiteningBenefit()}})</label>
                    </div>
                  </ng-container>
                  <!-- </div>-->
                </div>
              </div>
            </div>
          </section>
        </div>

        <!-- results section -->
        <div class="flex">
          <section id="results">
            <div class="title">
              <span>Explore these {{getFilteredPlanCount()}} plans to find your best fit</span>
              <button (click)="seeAllPlans()">(See All <span class="available-qty">{{ getPlanCountOfCurrentProduct()
                  }}</span> plans)</button>
            </div>
            <div class="sticky-mobile">
              <div class="parameters">
                <app-census-widget [census]="census" (click)="censusPopupClick()"></app-census-widget>
                <div class="chip-container">
                  <div class="chip-item" *ngFor="let filterChip of getSelectedFilters()">
                    <span>{{filterChip.label}}</span>
                    <span class="filter-close" (click)="removeFilter(filterChip)">&times;</span>
                  </div>
                </div>
              </div>
              <div class="recommended-container">
                <span>Sort</span>
                <select id="sortBy" [(ngModel)]="sortBy" (ngModelChange)="onSortByModelChange($event)">
                  <option [ngValue]="sortOptions.RECOMMENDED">
                    Recommended
                  </option>
                  <option [ngValue]="sortOptions.PREMIUM">
                    Premium (Low to High)
                  </option>
                  <option [ngValue]="sortOptions.PREMIUMHIGH">
                    Premium (High to Low)
                  </option>
                </select>
                <div class="single-button-container sbc-mobile">
                  <div class="button-holder" id="filter-btn">
                    <span><i class="fas fa-sliders-h"></i>Filter {{getSelectedFilters().length}}</span>
                    <!-- <span class="item-amount">3</span> -->
                  </div>
                </div>
              </div>
            </div>

            <div class="result-container" *ngFor="let plan of plans">
              <app-plan-summary *ngIf="plan.planType.productId === 1" [plan]="plan"
                [compareEnabled]="comparePlansCount() < maxPlansToCompare"
                [compareButtonEnabled]="comparePlansCount() > 1">
              </app-plan-summary>
              <app-vision-plan-summary *ngIf="plan.planType.productId === 2" [plan]="plan"
                [compareEnabled]="comparePlansCount() < maxPlansToCompare"
                [compareButtonEnabled]="comparePlansCount() > 1">
              </app-vision-plan-summary>
            </div>

            <div class="single-button-container see-all-btn"
              *ngIf="!showAllPlans && plans.length >= defaultNumberOfPlans && filter.productFilter[0].value === products.DENTAL">
              <a class="button-holder" id="full-btn" (click)="onSelectAllPlans()">
                <span>See More Plans</span>
              </a>
            </div>
            <div class="single-button-container see-all-btn"
              *ngIf="showAllPlans && plans.length >= defaultNumberOfPlans && filter.productFilter[0].value === products.DENTAL">
              <a class="button-holder" id="full-btn" (click)="onSelectLessPlans()">
                <span>See Less Plans</span>
              </a>
            </div>

            <!--Disclaimers-->
            <div class="notice-container">
              <span class="notice-title">Important Notices:</span>
              <span class="notice-text">For comparison purposes, monthly rates are used in this chart.
              </span>
              <span class="notice-text">* Please click on "Plan Details" for detailed information
                regarding the asterisk and/or superscription.
              </span>
              <span class="notice-text">N/A: Not applicable to the plan.</span>
            </div>
            <div class="disclaimer-section">
              <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
              <!-- <div class="plan-subtitles-w-caret-container" *ngIf="disclaimers && disclaimers.length > 0">
                <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
                <img src="../../../assets/img/svg/arrow-up.svg" alt="up" id="disclaimerArrow"
                  (click)="toggleDisclaimerBody(disclaimerBody, imgElementProvider)" #imgElementProvider />
              </div> -->
              <div class="content-section" #disclaimerBody>
                <span *ngIf="affiliate | async; let affiliate" [innerHTML]="affiliate.disclaimer"></span>
                <span *ngFor="let disclaimer of disclaimers" [innerHTML]="disclaimer"></span>
                <hr />
                <span>Rates shown are based upon the information you provided, and
                  are subject to change based on the dental and/or vision plan's
                  underwriting practices and your selection of available optional
                  benefits, if any. Final rates and effective dates are subject to
                  underwriting and are always determined by the dental insurance
                  and/or vision insurance company. To be considered for
                  reimbursement, expenses must qualify as covered expenses.
                </span>
                <span>This site was designed to provide you with a general
                  description of the plans you requested. Keep in mind that it
                  does not include all the benefits and limitations outlined in
                  the policies -- it is the insurance contract, not the general
                  descriptions on this website, which forms the contract between
                  you and the insurance company.</span>
                <span>DentalInsurance.com is paid by the Insurance Company we
                  represent. We earn commissions for each policy we sell. The
                  commission rates vary by Insurance Company and may increase
                  based on sales volume. There may also be a bonus or incentive.
                  For more information on a purchased plan or plan quoted to you,
                  please reach out through our contact us page.
                </span>
                <span>DentalInsurance.com Inc, its suppliers or any third parties
                  named in this site are not liable for any damages that result
                  from using, or inability to use, the website and material listed
                  within it, whether based on warranty, contract, tort or any
                  other legal theory and whether or not DentalInsurance.com Inc is
                  advised of the possibility of such damages. This includes, but
                  is not limited to, incidental and consequential damages, lost
                  profits or damages resulting from lost data or business
                  interruptions.</span>
                <span>The use of the website and its material, some of it supplied by
                  third parties, is at your own risk. Although we strive to
                  provide the most current and complete information on this
                  website, we make no representations about the accuracy,
                  completeness or timeliness of the material found here. Please
                  note that updates are made periodically to the website and we
                  reserve the right to make them at any time.</span>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
  <div class="fixed-component" [style.display]="plansForCompare().length > 0 ? 'block' : 'none'">
    <!-- ATTENTION!!! THIS SECTION REFERS TO THE CLOSED COMPARE SECTION -->
    <div class="compare-wrapper" id="compare-wrapper-closed">
      <span class="compare-mobile mobile-open">Plan comparison ({{plansForCompare().length}} of 2 selected)</span>
      <div class="closed-compare-card desktop" *ngFor="let cmpPlan of plansForCompare()">
        <div class="closed-card-header">
          <img class="logo" src="{{cmpPlan.carrier.logoUrl}}" />
          <span class="close-card" (click)="removePlanFromCompare(cmpPlan)">&#x2715;</span>
        </div>
        <div class="price-container">
          <span class="price">{{cmpPlan.premium | currency:'USD':'symbol'}}</span>
          <span class="month">/mo.</span>
        </div>
      </div>
      <div class="compare-btn desktop">
        <button [disabled]="comparePlansCount() < 2" (click)="comparePlans()">Compare</button>
      </div>
      <img id="comp-open" class="arrow" src="../../../assets/img/svg/arrow-up.svg" alt="up">
    </div>

    <!-- ATTENTION!!! THIS SECTION REFERS TO THE OPENED COMPARE SECTION -->
    <div class="compare-wrapper-opened" id="compare-wrapper-opened">
      <div class="text-header-section" id="opened-header">
        <span class="compare-mobile">Plan comparison</span>
        <img id="comp-close" class="arrow" src="../../../assets/img/svg/arrow-down.svg" alt="down">
      </div>
      <div class="cards">
        <div *ngFor="let cmpPlan of plansForCompare()" class="compare-card-container">
          <div class="compare-card-header">
            <img class="logo" src="{{cmpPlan.carrier.logoUrl}}" />
            <span class="close-card" (click)="removePlanFromCompare(cmpPlan)">&#x2715;</span>
          </div>
          <div class="price-container">
            <span class="price">{{cmpPlan.premium | currency:'USD':'symbol'}}</span>
            <span class="month">/mo.</span>
          </div>
          <span class="plan">{{cmpPlan.name}}</span>
        </div>
      </div>
      <div class="controls-section">
        <div class="compare-btn">
          <button [disabled]="comparePlansCount() < 2" (click)="comparePlans()">Compare</button>
        </div>
        <div class="arrow-container desktop">
          <img class="arrow" id="comp-close-desktop" src="../../../assets/img/svg/arrow-down.svg" alt="down"
            (click)="toggleCompareSections()">
        </div>
      </div>
    </div>
  </div>
</main>