import { Component, OnInit, ViewChild } from '@angular/core';

import { Census } from '../shared/models/census.model';
import { FamilyMember } from '../shared/models/family-member.model';
import { Gender } from '../shared/models/gender.enum';
import { FamilyRelationship } from '../shared/models/family-relationship.enum';
import { CensusService } from '../shared/services/census.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Geo } from '../shared/models/geo.model';
import { environment } from '../../environments/environment';
import { PlansService } from '../shared/services/plans.service';
import { AffiliateService } from '../shared/services/affiliate.service';
import { NgForm, FormsModule } from '@angular/forms';
import { Observable } from 'rxjs';
import { Affiliate } from '../shared/models/affiliate.model';
import { ExchangeCompany } from '../exch/models/exchange-company.model';
import { Tracking } from '../shared/models/tracking.model';
import { DateComponent } from './date/date.component';
import { NgFor, NgSwitch, NgSwitchCase, NgIf, NgClass, AsyncPipe } from '@angular/common';
import { GeoComponent } from './geo/geo.component';

@Component({
    selector: 'app-census',
    templateUrl: './census.component.html',
    styleUrls: ['./census.component.scss'],
    standalone: true,
    imports: [FormsModule, GeoComponent, NgFor, NgSwitch, NgSwitchCase, NgIf, DateComponent, NgClass, AsyncPipe]
})
export class CensusComponent implements OnInit {
  @ViewChild('censusForm', { static: true }) public censusForm!: NgForm;

  public MAX_CHILD_LIMIT = 9;
  public census!: Census;
  public geo!: Geo;
  public genderOptions = Gender;
  public familyRelationshipOptions = FamilyRelationship;
  public minEffectiveDate!: string;
  public maxEffectiveDate!: string;
  public availableEffectveDates!: Array<string>;
  public affiliate!: Observable<Affiliate| null> ;

  public exchangeExistingCoverage: boolean | undefined;
  public exchangeDental!: boolean;
  public exchangeExistingCoverageCompany!: string;
  public exchangeExistingCoveragePeriod!: boolean | undefined;
  public exchangeCompany!: ExchangeCompany | null;
  public proofOfCoverage!: boolean;
  public highlightPreviousCoverage = false;
  public showVision!: boolean;
  public showValidation = false;
  //public isExchangeMercer!: boolean;
  //public campaign: string;

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private censusService: CensusService,
    private plansService: PlansService,
    private affiliateService: AffiliateService) {

    this.affiliate = this.affiliateService.affiliate$;

    this.census = this.censusService.census;
    this.censusService.updated = false;
    this.geo = new Geo();

    const currentDate = new Date();
  }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {


      if (environment.prepopulateCensus) {
        this.census.zip = '90066';
        this.census.county = 'LOS ANGELES';
        this.census.stateAbbr = 'CA';
        this.census.familyMembers[0].birthdate = '01/01/1980';
        this.census.familyMembers[0].gender = Gender.UNKNOWN;//Gender.MALE;
      }

      const requestId = params['requestId'];
      const carrierPage = params['carrier'];
      const isVision = params['prod'];
      this.plansService.carrierPage = carrierPage;
      //console.log('census this plansservice tracking', this.plansService.tracking);
      
      // const newSession = params['newSession'];

      // if (newSession) {
      //   localStorage.clear();
      // }

      const affiliateId = localStorage.getItem('affiliateId');
      const agentId = localStorage.getItem('agentId');
      const isExchange = localStorage.getItem('exchange');
      const zipParam = params['zip'];
      const dobParam = params['dob'];
      const spouseDobParam = params['spousedob'];
      const childDobParam = params['childdob'];
      

      if (requestId || affiliateId || isExchange) {
        if (affiliateId != '1019') 
        {
          this.affiliateService.affiliate_id = +affiliateId!;
        }
        this.censusService.loadFromLocalStorage();
       
        if (localStorage.getItem('tracking')) {
          const tracking = <Tracking>JSON.parse(localStorage.getItem('tracking')!);
          this.plansService.tracking = tracking;
          if (localStorage.getItem('campaign')){
            //this.campaign = localStorage.getItem('campaign');
            const sourceCampaign = localStorage.getItem('campaign');
            this.plansService.campaign = sourceCampaign;
          }
          localStorage.setItem('source', this.plansService.tracking.utm_source!);
          localStorage.setItem('medium', this.plansService.tracking.utm_medium!);
          localStorage.setItem('agentId', this.plansService.tracking.utm_term!);
          localStorage.setItem('content', this.plansService.tracking.utm_content!);
            
        }

        this.census = this.censusService.census;
        if (zipParam != undefined && zipParam) {
          this.census.zip = zipParam;
        }
        if (dobParam != undefined && dobParam) {
          this.census.familyMembers[0].birthdate = dobParam;
        }
        if (spouseDobParam != undefined && spouseDobParam) {
          this.census.familyMembers[1].birthdate = spouseDobParam;
        }
        if (childDobParam != undefined && childDobParam) {
          this.census.familyMembers[0].birthdate = childDobParam;
        }
        if (isVision != undefined && isVision){
          this.showVision = (isVision === 'vision') ? true : false;
        }
        if (affiliateId === '2574' || affiliateId === '2575')
          this.showVision = true;
        const geo = new Geo();
        geo.zip = this.census.zip;
        geo.stateAbbr = this.census.stateAbbr;
        geo.county = this.census.county;
        this.geo = geo;
      } else {
        //localStorage.clear();
        if (zipParam != undefined && zipParam) {
          this.census.zip = zipParam;
        }
        if (dobParam != undefined && dobParam) {
          this.census.familyMembers[0].birthdate = dobParam;
        }

        const geo = new Geo();
        geo.zip = this.census.zip;
        geo.county = this.census.county;
        geo.stateAbbr = this.census.stateAbbr;
        this.geo = geo;

        if (isVision != undefined && isVision){
          this.showVision = (isVision === 'vision') ? true : false;
        }
        if (affiliateId === '2574' || affiliateId === '2575')
          this.showVision = true;
      }
      this.getExchangeData();

      this.availableEffectveDates = this.getAvailableEffectiveDates();

      if (this.census.requestedEffectiveDate === undefined || !this.census.requestedEffectiveDate) {
        if (this.censusService.exchange) {
          this.census.requestedEffectiveDate = this.availableEffectveDates.find(x => x.startsWith('1/1/'))!;
        } else {
          this.census.requestedEffectiveDate = this.availableEffectveDates[0];
        }
      }
    });
  }

  getExchangeData() {
    if (this.censusService.exchangeVision && this.censusService.exchangeDental) {
      this.censusService.exchangeVision = false;
      this.highlightPreviousCoverage = true;
    }
    this.censusService.showLinks.next(false);
    this.exchangeDental = this.censusService.exchangeDental;
    this.exchangeCompany = this.censusService.exchangeCompany;
    this.exchangeExistingCoverage = this.censusService.exchangeExistingCoverage;
    this.exchangeExistingCoverageCompany = this.censusService.exchangeExistingCoverageCompany;
    this.exchangeExistingCoveragePeriod = this.censusService.exchangeExistingCoveragePeriod;
    this.proofOfCoverage = this.censusService.proofOfCoverage;
    //this.isExchangeMercer = (this.censusService.exchangeDental || this.censusService.exchangeVision) ? ( this.exchangeCompany!.exchangeCampaignId === 25 ? true : false ) : false;
  }

  getAvailableEffectiveDates(): Array<string> {

    if (this.censusService.exchange) {
      return this.getExchnageAvailableEffectiveDates();
    }

    const availableEffectiveDates: Array<string> = new Array<string>();
    let availableDate = new Date();
    let currentMonth = availableDate.getMonth();

    availableDate.setDate(1);

    availableDate.setMonth(currentMonth + 1);
    availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/[^ -~]/g, ''));

    if (currentMonth >= 11) {
      currentMonth -= 12;
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).replace(/[^ -~]/g, ''));
    } else {


      availableDate.setDate(1);
      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).replace(/[^ -~]/g, ''));


      availableDate = new Date();

      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 3);
      availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
        day: 'numeric',
        month: 'numeric',
        year: 'numeric'
      }).replace(/[^ -~]/g, ''));
    }

    return availableEffectiveDates;
  }

  getExchnageAvailableEffectiveDates(): Array<string> {
    const availableEffectiveDates: Array<string> = new Array<string>();
    let availableDate = new Date();
    let currentMonth = availableDate.getMonth();

    availableDate.setDate(1);


    // TODO remove
     if (this.exchangeCompany!.exchangeCompanyId === 230 ) {
       availableDate.setMonth(currentMonth + 2);
       availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
         day: 'numeric',
         month: 'numeric',
         year: 'numeric'
       }).replace(/[^ -~]/g, ''));

       return availableEffectiveDates;
     }

    availableDate.setMonth(currentMonth + 1);


    availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/[^ -~]/g, ''));

    if (currentMonth >= 11) {
      currentMonth -= 12;
    //}

    availableDate.setMonth(currentMonth + 2);
    availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/[^ -~]/g, ''));
  } else {
    availableDate = new Date();
  availableDate.setDate(1);
    availableDate.setMonth(currentMonth + 2);
    availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/[^ -~]/g, ''));
  
    availableDate = new Date();
    availableDate.setDate(1);
    availableDate.setMonth(currentMonth + 3);
    availableEffectiveDates.push(availableDate.toLocaleString('en-US', {
      day: 'numeric',
      month: 'numeric',
      year: 'numeric'
    }).replace(/[^ -~]/g, ''));
  }
    return availableEffectiveDates;
  }

  onSubmit(): void {
    this.showValidation = true;
    if (!this.censusForm.form.valid)
      return;

    this.census.familyMembers.forEach(member => {
      if ( member.gender == null) {
        member.gender = Gender.UNKNOWN;
      }
    });
    if (this.census.requestedEffectiveDate == null) {
      this.census.requestedEffectiveDate = this.availableEffectveDates[0];
    }
    this.censusService.census = this.census;
    this.censusService.updated = true;
    this.censusService.showVision = this.showVision;
    if (this.exchangeCompany) {
      let affiliateId = 0;
      const callCenterId = this.censusService.callCenterId;
     /* if (this.isExchangeMercer)
      {
        if (callCenterId !== undefined && callCenterId !== null && callCenterId !== 0) {
          if (callCenterId === this.exchangeCompany.exchangeCampaign.callCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentNoWait;
          } else if (callCenterId === this.exchangeCompany.exchangeCampaign.overflowCallCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentOverflowNoWait;
          }
        } else {
          affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdWebNoWait;
        }
        this.exchangeExistingCoverage = true;
        this.exchangeExistingCoverageCompany = 'kelsey';
        this.exchangeExistingCoveragePeriod = true;
        this.proofOfCoverage = true;
      }
      else*/ if (this.exchangeExistingCoverage && this.exchangeExistingCoveragePeriod) {
        if (callCenterId !== undefined && callCenterId !== null && callCenterId !== 0) {
          if (callCenterId === this.exchangeCompany.exchangeCampaign.callCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentNoWait;
          } else if (callCenterId === this.exchangeCompany.exchangeCampaign.overflowCallCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentOverflowNoWait;
          }
        } else {
          affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdWebNoWait;
        }
      } else {
        if (callCenterId !== undefined && callCenterId !== null && callCenterId !== 0) {
          if (callCenterId === this.exchangeCompany.exchangeCampaign.callCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentWait;
          } else if (callCenterId === this.exchangeCompany.exchangeCampaign.overflowCallCenterId) {
            affiliateId = this.exchangeCompany.exchangeCampaign.affiliateIdAgentOverflowWait;
          }
        } else {
          affiliateId = this.exchangeCompany.exchangeCampaign.affiliteIdWebWait;
        }
      }
      if (!this.plansService.tracking) {
        this.plansService.tracking = new Tracking();
      }
      this.plansService.tracking.utm_campaign = affiliateId.toString();
      this.plansService.tracking.exchangeCompanyId = this.exchangeCompany.exchangeCompanyId;
      this.plansService.tracking.exchangeCompanyName = this.exchangeCompany.name;

      if (localStorage.getItem('agentId'))
        this.plansService.tracking.utm_term = localStorage.getItem('agentId');


      if (window !== undefined) {
        this.affiliateService.affiliate$.subscribe(x => {
          window.affiliate_name = x?.name; window.utm_campaign = affiliateId;
        });
      }

      this.affiliateService.affiliate_id = affiliateId;

      this.censusService.exchangeExistingCoverage = this.exchangeExistingCoverage;
      this.censusService.exchangeExistingCoverageCompany = this.exchangeExistingCoverageCompany;
      this.censusService.exchangeExistingCoveragePeriod = this.exchangeExistingCoveragePeriod;
      this.censusService.proofOfCoverage = this.proofOfCoverage;
    }
    this.censusService.saveToLocalStorage();

    if (this.plansService.tracking) {
      localStorage.setItem('tracking', JSON.stringify(this.plansService.tracking));
      localStorage.setItem('agentId', this.plansService.tracking.utm_term!);
      localStorage.setItem('campaign', this.plansService.campaign!);
    }
    this.router.navigateByUrl('/plans');

  }

  isValidCensus(): boolean {
    // TODO FIX IT
    if (this.census.county == null || this.census.requestedEffectiveDate == null) {
      return false;
    }

    this.census.familyMembers.forEach(member => {
      if (member.birthdate == null || member.gender == null) {
        return false;
      }
      return true;

    });

    return true;
  }

  onRemoveClick(familyMember: FamilyMember): void {
    const index: number = this.census.familyMembers.indexOf(familyMember);
    this.census.familyMembers.splice(index, 1);
  }

  onAddChildClick(): void {
    const child: FamilyMember = new FamilyMember(FamilyRelationship.CHILD, Gender.UNKNOWN, null, false, false, false);
    this.census.familyMembers.push(child);
  }

  onAddSpouseClick(): void {
    const spouse: FamilyMember = new FamilyMember(FamilyRelationship.SPOUSE, Gender.UNKNOWN, null, false, false, false);
    this.census.familyMembers.push(spouse);
  }

  spouseExists(): boolean {
    return !(this.census.familyMembers.find(familyMember =>
      familyMember.familyRelationship === FamilyRelationship.SPOUSE) == null);
  }

  getChildCount(): number {
    return this.census.familyMembers.filter(familyMember =>
      familyMember.familyRelationship === FamilyRelationship.CHILD).length;
  }

  getChildNumber(familyMember: FamilyMember): number {
    //const offset: number = this.spouseExists() ? 1 : 0;
    //const index: number = this.census.familyMembers.indexOf(familyMember);
    const index: number = this.census.familyMembers.filter(familyMember => familyMember.familyRelationship === FamilyRelationship.CHILD).indexOf(familyMember);
    return index + 1;// ( index - offset));
  }

  getMemberId(familyMember: FamilyMember): number {
    const index: number = this.census.familyMembers.indexOf(familyMember);
    return index;
  }

  getMaxDate(familyMember: FamilyMember): Date {
    if (familyMember.familyRelationship === FamilyRelationship.APPLICANT) {
      const maxAge: Date = new Date();
      maxAge.setFullYear(maxAge.getFullYear() - 18);
      return maxAge;
    } else {
      return new Date();
    }
  }

  getMinChildDate(familyMember: FamilyMember): Date {
    if (familyMember.familyRelationship !== FamilyRelationship.CHILD || familyMember.isDisabled) {
      return new Date(1900, 1, 1);
    } else {
      const maxAge: Date = new Date();
      maxAge.setFullYear(maxAge.getFullYear() - 26);
      return maxAge;
    }
  }

  onDisabledClick(familyMember: FamilyMember) {
    for (let i = 0; i <= this.MAX_CHILD_LIMIT; i++) {
      const bdControl = this.censusForm.controls['Birthdate' + i.toString()];
      if (bdControl) {
        setTimeout(() => bdControl.updateValueAndValidity(), 0);
      }
    }
  }


  onGeoModelChange(model: Geo) {
    if (model) {
      this.census.zip = model.zip;
      this.census.county = model.county;
      this.census.stateAbbr = model.stateAbbr;
    }
  }


  quickQuoteClick() {
    this.census.familyMembers = new Array<FamilyMember>();
    //const applicant: FamilyMember = new FamilyMember(FamilyRelationship.APPLICANT, Gender.FEMALE, '01/01/1980', false, false, false);
    const applicant: FamilyMember = new FamilyMember(FamilyRelationship.APPLICANT, Gender.UNKNOWN, '01/01/1980', false, false, false);
    this.census.familyMembers.push(applicant);
    this.onSubmit();
  }

}
