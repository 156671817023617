<main>
    <section id="edit-info" [ngClass]="{'showValidation': showValidation === true}">
        <div class="edit-container">
            <form [formGroup]="censusForm" id="form" class="census" novalidate>
                <div class="heading-container">
                    <h2>Edit Your Info</h2>
                    <div class="close-button-container" (click)="onClosePopup()">
                        <img src="assets/img/svg/close.svg" alt="close">
                    </div>
                </div>
                <div class="input-group input-with-info input">
                    <div class="input-group geo-zip">
                        <label [ngClass]="{'ng-invalid' : censusForm.controls.zip.invalid}">Zip Code</label>
                        <input type="tel" id="zip" formControlName="zip" (input)="onZipInput()" #zip >
                    </div>
                    <div
                        *ngIf="showValidation && censusForm.controls.zip.errors | OnlyOneError:['required', 'pattern', 'zipNotExist'] as error">
                        <div *ngIf="error['required']" class="invalid-feedback">Enter valid zip code.</div>
                        <div *ngIf="error['pattern']" class="invalid-feedback">Invalid zip code format.</div>
                        <div *ngIf="error['zipNotExist']" class="invalid-feedback">Provided zip doesn't exist.</div>
                    </div>
                </div>
                <div *ngIf="(counties$ | async)?.length > 0" class="input-group">
                    <label for="county" [ngClass]="{'ng-invalid' : censusForm.controls.countyIndex.invalid}">County: </label>
                    <span *ngIf="countiesLoading">Loading...</span>
                    <select id="county" *ngIf="!countiesLoading" formControlName="countyIndex" >
                        <option [ngValue]="null">Select county</option>
                        <option *ngFor="let cnt of counties$ | async; let i = index" [ngValue]="i">
                            {{cnt.county | titlecase}}, {{cnt.stateAbbr}}
                        </option>
                    </select>
                </div>
                <div *ngIf="showValidation && censusForm.controls.countyIndex.errors as error">
                    <div *ngIf="error['required']" class="invalid-feedback">Please select a county.</div>
                </div>

                <div class="input-group input-with-info input">
                    <label [ngClass]="{'ng-invalid' : censusForm.controls.applicant_dob.invalid}">Applicant Date of Birth</label>
                    <input type="tel" id="applicant_dob" formControlName="applicant_dob"
                        (input)="onDateInput($event.target!, censusForm.controls.applicant_dob)"
                        placeholder="mm/dd/yyyy" >
                    <div
                        *ngIf="showValidation && censusForm.controls.applicant_dob.errors | OnlyOneError:['required', 'dateFormat', 'ageRange'] as error">
                        <div *ngIf="error['required']" class="invalid-feedback">Enter a valid date of birth. Applicant
                            must be 18 and over.
                        </div>
                        <div *ngIf="error['dateFormat']" class="invalid-feedback">Invalid date format (use mm/dd/yyyy).
                        </div>
                        <div *ngIf="error['ageRange']" class="invalid-feedback">Enter a valid date of birth. Applicant
                            must be 18 and over.</div>
                    </div>
                </div>
                <div class="input-group input-with-info">
                    <label [ngClass]="{'ng-invalid' : censusForm.controls.email.invalid}">Email (Optional)</label>
                    <input type="email" id="email" formControlName="email"  />
                    <div *ngIf="showValidation && censusForm.controls.email.errors as error">
                        <div *ngIf="error['emailFormat']" class="invalid-feedback">Enter a valid email address.
                        </div>
                    </div>
                </div>

                <div id="family_members">
                    <div id="applicant" class="family-members">
                        <div class="member-dob" *ngIf="!censusForm.controls.spouse_dob.disabled">
                            <div class="input-group input-with-info" id="spouse-input">
                                <div>
                                    <label [ngClass]="{'ng-invalid' : censusForm.controls.spouse_dob.invalid}">Spouse Date of Birth</label>
                                    <input type="tel" id="spouse_dob" formControlName="spouse_dob"
                                        (input)="onDateInput($event.target!, censusForm.controls.spouse_dob)"
                                        placeholder="mm/dd/yyyy" >
                                    <img src="assets/img/svg/close.svg" alt="close" id="delete-spouse"
                                        (click)="removeSpouseClick()">
                                    <div class="mobile-delete spouse-container" id="delete-spouse-mobile"
                                        (click)="removeSpouseClick()">
                                        <div class="spouse" id="delete-mobile">
                                            <img src="assets/img/svg/trash.svg" alt="trash" id="spouse-tr">
                                        </div>
                                    </div>
                                    <div
                                        *ngIf="showValidation && censusForm.controls.spouse_dob.errors | OnlyOneError:['required', 'dateFormat', 'ageRange'] as error">
                                        <div *ngIf="error['required']" class="invalid-feedback">Enter a valid date of
                                            birth.</div>
                                        <div *ngIf="error['dateFormat']" class="invalid-feedback">Invalid date format
                                            (use mm/dd/yyyy).</div>
                                        <div *ngIf="error['ageRange']" class="invalid-feedback">Enter a valid date of
                                            birth.</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!-- child -->
                        <div class="input-group input-with-info input"
                            *ngFor="let child of this.censusForm.controls.children.controls; let i=index">
                            <div class="input-group input-with-info input" id="child-input">
                                <label for="child_dob{{i}}" [ngClass]="{'ng-invalid' : child.invalid}">Child Date of Birth: </label>
                                <input type="tel" id="child_dob{{i}}" [formControl]="child.controls.dob"
                                    (input)="onDateInput($event.target!, child.controls.dob)" placeholder="mm/dd/yyyy">
                                <div
                                    *ngIf="showValidation && child.controls.dob.errors | OnlyOneError:['required', 'dateFormat', 'ageTooHigh'] as error">
                                    <div *ngIf="error['required']" class="invalid-feedback">EEnter a valid date of birth (A disabled child over age 26 is an eligible dependent).</div>
                                    <div *ngIf="error['dateFormat']" class="invalid-feedback">Invalid date format (use
                                        mm/dd/yyyy).</div>
                                    <div *ngIf="error['ageTooHigh']" class="invalid-feedback">Enter a valid date of birth (A disabled child over age 26 is an eligible dependent).</div>
                                </div>

                                <img src="assets/img/svg/close.svg" alt="close" id="delete-child"
                                    (click)="removeChildClick(i)">
                                <div class="mobile-delete" id="delete-child-mobile" (click)="removeChildClick(i)">
                                    <div class="delete-mobile-btn" id="delete-mobile">
                                        <img src="assets/img/svg/trash.svg" alt="trash" id="child-tr">
                                    </div>
                                </div>
                                <div class="child-section">
                                    <div class="child-checkbox">
                                        <div class="child-checkbox-container">
                                            <input type="checkbox" id="child_is_student{{i}}"
                                                [formControl]="child.controls.isStudent">
                                            <label for="child_is_student{{i}}">Student</label>
                                        </div>
                                        <div class="child-checkbox-container">
                                            <input type="checkbox" id="child_is_disabled{{i}}"
                                                [formControl]="child.controls.isDisabled">
                                            <label for="child_is_disabled{{i}}">Disabled</label>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="buttons mt-12" id="family_members_controls">
                    <button class="button-holder button" id="addSpouseBtn" (click)="addSpouseClick()" [disabled]="!censusForm.controls.spouse_dob.disabled">
                        <span class="material-symbols-outlined">person</span>
                        <span>Add spouse</span>
                    </button>
                    <button class="button-holder button" id="addChildBtn" (click)="addChildClick()"
                    [disabled]="this.censusForm.controls.children.controls.length >= MAX_CHILD">
                        <span class="material-symbols-outlined">person</span>
                        <span id="add-child-text">Add child</span>
                    </button>
                </div>
                <button class="button-holder button submit" id="continueBtn" (click)="onSubmitClick()">
                    <span>Update &amp; View Plans</span>
                </button>

            </form>
        </div>
        <div id="overlay"></div>
    </section>
</main>