<div *ngIf="loading" class="loader"></div>
<app-census-popup *ngIf="showCensusPopup" (popupClosed)="onCensusPopupClosed()"></app-census-popup>
<section id="copy-sent-popup" *ngIf="emailSent">
  <div class="popup-container">
    <div class="popup-header">
      <span class="popup-title">Success!</span>
      <span class="close" (click)="emailSent = false">&#x2715;</span>
    </div>
    <div class="image-holder">
      <img src="assets/img/png/check.png" alt="check">
    </div>
    <div class="popup-body">
      <span>A summary of this plan information has been sent. Thank you. <br> If you do not see the
        email from DentalInsurance.com in a few minutes, please check your “junk mail” or “spam”
        folder.</span>
    </div>
    <!-- <div class="popup-footer">
      <span (click)="emailSent = false">Close</span>
    </div> -->
  </div>
  <div id="overlay"></div>
</section>

<main class="section no-page-title">
  <section class="section" id="plan-details">
    <div class="section-wrapper block content-1170 center-relative" id="container">
      <div *ngIf="plan" class="blocks-section">
        <div class="gray-button header-elements">
          <span>
            <i class="fas fa-chevron-left"></i>
            <a [routerLink]="['/plans']">Return to Plan List</a>
          </span>
          <div *ngIf="emailAQuote" class="input-container-details-plan">
            <div>
              <input type="text" class="form-control" #txtEmail placeholder="Email me a copy of this plan"
                aria-label="Email" aria-describedby="Email" id="email" name="email">
              <i class="fas fa-paper-plane" id="send-copy-icon" (click)="emailDetails(txtEmail.value)"></i>
            </div>
          </div>
        </div>
        <div class="flex">
          <section id="results">
            <div class="parameters">
              <app-census-widget [census]="census" (click)="censusPopupClick()"></app-census-widget>
              <div *ngIf="emailAQuote" class="input-container-details-plan">
                <div>
                  <input type="text" class="form-control" #txtEmail placeholder="Email me a copy of this plan"
                    aria-label="Email" aria-describedby="Email" id="email" name="email">
                  <i class="fas fa-paper-plane" id="send-copy-icon" (click)="emailDetails(txtEmail.value)"></i>
                </div>
              </div>
            </div>
            <section class="details-section">
              <div class="details-container">
                <section class="details-card">
                  <div class="details-card-header">
                    <div class="recommended-badge-container" *ngIf="plan.planLabel">
                      <span>{{plan.planLabel}}</span>
                      <img src="assets/img/svg/stars.svg" alt="stars">
                    </div>
                  </div>
                  <div class="details-card-body">
                    <div class="first-grid">
                      <img src="{{plan.carrier.logoUrl}}" alt="Company logo" />
                      <div class="price-container price-container-mobile">
                        <span *ngIf="plan.premium > 0" class="price">{{plan.premium | currency:"USD":'symbol'}}</span>
                        <span class="month">/mo.</span>
                      </div>
                      <a *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist
                        Search</a>
                      <a *ngIf="plan.doctorSearchUrl" href="{{plan.doctorSearchUrl}}" target="_blank">Provider
                        Search</a>
                    </div>
                    <div class="second-grid">
                      <div class="plan-name-container">
                        <span class="plan-name">
                          <!--<a *ngIf="!censusService.exchangeDental && !careington"
                            [attr.href]="'/'+getCarrierUrl(plan.carrier.name.toLowerCase().split(' ').join('-'), plan.name) + '/'">{{plan.carrier.name}}</a>
                          <span *ngIf="censusService.exchangeDental || careington">{{plan.carrier.name}}</span> -->
                          {{plan.name}}
                        </span>
                      </div>
                      <div class="plan-specs">
                        <div class="plan-type-container">
                          <span class="plan-type">Plan Type</span>
                          <span class="ppo">{{plan.planType.name}}</span>
                          <div class="recommended-mobile" *ngIf="plan.planLabel">
                            <span>{{plan.planLabel}}</span>
                            <img src="assets/img/svg/stars.svg" alt="stars">
                          </div>
                        </div>
                      </div>
                      <div class="warning">
                        <span [innerHTML]="plan.effectiveDateDetails"></span>
                      </div>
                      <a *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist
                        Search</a>
                    </div>
                    <div class="third-grid">
                      <div class="price-container price-container-desktop">
                        <span *ngIf="plan.premium > 0" class="price">{{plan.premium | currency:"USD":'symbol'}}</span>
                        <span class="month">/mo.</span>
                      </div>
                      <div class="enroll">
                        <a *ngIf="!defaultCensus && isFlag"
                          href="{{enrollmentWebUrl}}?planId={{plan.planId}}&quoteRequestId={{requestId}}&isFlag={{isFlag}}"><strong>Enroll
                            Now</strong></a>
                        <a *ngIf="!defaultCensus && !isFlag"
                          href="{{enrollmentWebUrl}}?planId={{plan.planId}}&quoteRequestId={{requestId}}"><strong>Enroll
                            Now</strong></a>
                        <a *ngIf="defaultCensus" [routerLink]="['/census']"><strong>Enroll
                            Now</strong></a>
                      </div>
                    </div>
                  </div>

                  <section class="plan-subtitles">
                    <div class="plan-subtitles-w-caret-container"
                      (click)="togglePlanDetailsElement(planBodyHighlights, imgElementHighlights)">
                      <span class="subtitle-text">Plan Highlights</span>
                      <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementHighlights />
                    </div>
                    <div class="highlights-list" [innerHTML]="plan.planHighlights" #planBodyHighlights>
                    </div>
                  </section>
                  <!-- <section class="customer-review customer-review-desktop">
                    <div class="customer-review-container">
                      <span>"I love my Hollywood Smile Premier Plus 2000 plan. Cynthia, an
                        agent, helped me enroll. Very quick and easy."</span>
                    </div>
                  </section> -->
                  <!-- <section class="customer-review customer-review-mobile">
                    <div class="customer-review-container">
                      <span>"I love my Hollywood Smile Premier Plus 2000 plan. Cynthia, an
                        agent, helped me enroll. Very quick and easy."</span>
                    </div>
                  </section> -->

                  <app-dental-details-table *ngIf="plan.planType.productId === 1"
                    [plan]="plan"></app-dental-details-table>
                  <app-vision-details-table *ngIf="plan.planType.productId === 2"
                    [plan]="plan"></app-vision-details-table>

                  <section class="faq mt-10">
                    <div class="plan-subtitles-w-caret-container"
                      (click)="togglePlanDetailsElement(planBodyFaq, imgElementFaq)">
                      <span class="subtitle-text">FAQ</span>
                      <img src="assets/img/svg/arrow-down.svg" #imgElementFaq />
                    </div>

                    <div #planBodyFaq [style.display]="'none'">
                      <div class="faq-container" *ngFor="let faq of plan.faQs; let i = index">
                        <div class="faq-title-container"
                          (click)="togglePlanDetailsElement(planBodyFaqEl, imgElementFaqEl)">
                          <span class="faq-title">{{faq.question}}</span>
                          <img [src]="(i===0)  ? 'assets/img/svg/arrow-up.svg' : 'assets/img/svg/arrow-down.svg'"
                            #imgElementFaqEl />
                        </div>
                        <div class="faq-answer-container" [innerHTML]="faq.answer" #planBodyFaqEl
                          [style.display]="i==0 ? 'block': 'none'">
                        </div>
                      </div>
                    </div>

                  </section>
                  <section class="insurance-company-contact">
                    <div class="contact" [innerHTML]="plan.carrier.address">
                    </div>
                  </section>

                </section>
                <div class="disclaimer-section">
                  <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
                  <section class="content-section">
                    <!-- <div class="plan-subtitles-w-caret-container"
                    (click)="togglePlanDetailsElement(planBodyDisclaimers, imgElementDisclaimers)">
                    <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
                    <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementDisclaimers />
                  </div> -->

                    <div *ngIf="affiliate | async; let affiliate;" [innerHTML]="affiliate.disclaimer"></div>
                    <p *ngIf="plan.disclaimers" [innerHTML]="plan.disclaimers"></p>
                    <p *ngIf="!plan.disclaimers" [innerHTML]="plan.carrier.disclaimers"></p>
                    <hr />
                    <p>This website provides a very brief description of some of the important features of this plan.
                      It is not
                      the
                      insurance contract, nor does it represent the insurance contract. A full description of
                      benefits, exclusions
                      and limitations is contained in the Schedule of Benefits and your policy.</p>
                    <p>Rates shown are based upon the information you provided, and are subject to change based on the
                      dental
                      and/or vision plan's underwriting practices and your selection of available optional benefits,
                      if any.
                      Final rates and effective dates are subject to underwriting and are always determined by the
                      dental
                      insurance and/or vision insurance company. To be considered for reimbursement, expenses must
                      qualify as
                      covered expenses.</p>
                    <p>This site was designed to provide you with a general description of the plans you requested.
                      Keep in mind
                      that it does not include all the benefits and limitations outlined in the policies -- it is the
                      insurance
                      contract, not the general descriptions on this website, which forms the contract between you and
                      the
                      insurance company.</p>
                    <p>DentalInsurance.com is paid by the Insurance Company we represent. We earn commissions for each
                      policy we
                      sell. The commission rates vary by Insurance Company and may increase based on sales volume.
                      There may also
                      be
                      a bonus or incentive. For more information on a purchased plan or plan quoted to you, please
                      reach out
                      through
                      our contact us page.</p>
                    <p>DentalInsurance.com Inc, its suppliers or any third parties named in this site are not liable
                      for any
                      damages that result from using, or inability to use, the website and material listed within it,
                      whether
                      based on warranty, contract, tort or any other legal theory and whether or not
                      DentalInsurance.com Inc is
                      advised of the possibility of such damages. This includes, but is not limited to, incidental and
                      consequential damages, lost profits or damages resulting from lost data or business
                      interruptions.</p>
                    <p>The use of the website and its material, some of it supplied by third parties, is at your own
                      risk.
                      Although we strive to provide the most current and complete information on this website, we make
                      no
                      representations about the accuracy, completeness or timeliness of the material found here.
                      Please note that
                      updates are made periodically to the website and we reserve the right to make them at any time.
                    </p>
                  </section>
                </div>
                <div class="single-button-container">
                  <div class="button-holder" id="full-btn" (click)="backToTop()">
                    <span>Back To Top</span>
                  </div>
                </div>
              </div>
            </section>
          </section>
        </div>
      </div>
    </div>
  </section>
</main>