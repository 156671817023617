import { Component, OnInit, Input } from '@angular/core';
import { DentalPlanSummary } from '../../shared/models/dental-plan-summary.model';
import { PlansService } from '../../shared/services/plans.service';
import { AppSettingsService } from '../../shared/services/app-settings.service';
import { Plan } from '../../shared/models/plan.model';
import { Router, RouterLink } from '@angular/router';
import { NgIf, CurrencyPipe, NgClass } from '@angular/common';
import { FormsModule } from '@angular/forms';

@Component({
  selector: 'app-plan-summary',
  templateUrl: './plan-summary.component.html',
  styleUrls: ['./plan-summary.component.scss'],
  standalone: true,
  imports: [FormsModule, NgIf, RouterLink, CurrencyPipe, NgClass],
})
export class PlanSummaryComponent implements OnInit {
  @Input() public plan!: any;
  @Input() public compareEnabled!: boolean;
  @Input() public compareButtonEnabled!: boolean;
  public requestId!: string;
  public enrollmentWebUrl!: string;

  constructor(
    private router: Router,
    private plansService: PlansService,
    private appSettingsService: AppSettingsService
  ) {}

  ngOnInit() {
    this.requestId = this.plansService.quoteResponse.requestId;
    this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
  }

  getPlanUrl(plan: Plan) {
    // tslint:disable-next-line: max-line-length
    return (
      '../' +
      plan.carrier.name.toLowerCase().split(' ').join('-') +
      '/' +
      plan.name
        .toLowerCase()
        .split(' ')
        .join('-')
        .split(')')
        .join('')
        .split('(')
        .join('')
        .split('+')
        .join('-')
        .split('/')
        .join('-')
        .split(',')
        .join('')
    );
  }

  comparePlans() {
    this.router.navigateByUrl('/plans/compare');
  }

  //Plan body toggled(show/hide) if user click on 'Show More'
  togglePlanBody(
    divPlanBodyExpanded: HTMLElement,
    spanElement: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divPlanBodyExpanded.style.display === 'block';
    divPlanBodyExpanded.style.display = isShowed ? 'none' : 'block';
    spanElement.textContent = isShowed ? 'More Details' : 'Close Details';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-down.svg'
      : '../../../assets/img/svg/arrow-up.svg';
  }
}
