import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, NavigationEnd, RouteConfigLoadEnd, RouteConfigLoadStart, Router, RouterOutlet } from '@angular/router';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { ExchangeCompany } from './exch/models/exchange-company.model';
import { Tracking } from './shared/models/tracking.model';
import { AffiliateService } from './shared/services/affiliate.service';
import { CanonicalService } from './shared/services/canonical.service';
import { CensusService } from './shared/services/census.service';
import { PlansService } from './shared/services/plans.service';
import { FooterExchangeComponent } from './exch/footer-exchange/footer-exchange.component';
import { FooterComponent } from './footer/footer.component';
import { HeaderExchangeComponent } from './exch/header-exchange/header-exchange.component';
import { HeaderComponent } from './header/header.component';
import { NgIf } from '@angular/common';

declare global {
  interface Window { dataLayer: any; Trustpilot: any; utm_campaign: any; affiliate_name: any; sub_affiliate: any; }
}
@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [NgIf, HeaderComponent, HeaderExchangeComponent, RouterOutlet, FooterComponent, FooterExchangeComponent]
})
export class AppComponent {
  title = 'DentalInsurance.com';
  public exchangeCompany!: ExchangeCompany;
  public exchange!: boolean;
  public careington = false;
  public loadingRouteConfig!: boolean;
  public campaign!: string;

    constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private plansService: PlansService,
    private affiliateService: AffiliateService,
    private censusService: CensusService,
    private titleService: Title,
    private canonicalService: CanonicalService) {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.canonicalService.createCanonicalURL(event.url);
      }
    });


    // Using Rx's built in `distinctUntilChanged ` feature to handle url change c/o @dloomb's answer
    router.events.pipe(distinctUntilChanged((previous: any, current: any) => {
      // Subscribe to any `NavigationEnd` events where the url has changed
      if (current instanceof NavigationEnd && (window !== undefined)) {
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          event: 'Pageview',
          'pageTitle': this.titleService.getTitle()
        });
        plansService.previousPage = previous.url;
        return previous.url === current.url;
      }
      return true;
    })).subscribe((x: any) => {
      //  window.gtm('set', 'page', x.url);
      //  window.gtm('send', 'pageview');
    });
  }

  extractSubAffiliate(utm_source: string | null): string | null{
    if (!utm_source) {
      return null;
    }

    const firstIndex = utm_source.toString().indexOf('|');
    const lastIndex = utm_source.toString().lastIndexOf('|');

    if (lastIndex > firstIndex) {
      return utm_source.substring(lastIndex + 1);
    } else {
      return null;
    }
  }

  ngOnInit() {
    // -- NEW -- //
    this.activatedRoute.queryParams.subscribe(params => {
      const utm_source = params['utm_source'];
      const utm_medium = params['utm_medium'];
      const utm_campaign = params['utm_campaign'];
      const utm_term = params['utm_term'];
      const utm_content = params['utm_content'];
      const newSession = params['newSession'];     

      if (newSession) {
        localStorage.clear();
        
      }     
      
      //if (typeof this.plansService.tracking === 'undefined' && (
      if ( typeof utm_source !== 'undefined' ||
        typeof utm_medium !== 'undefined' ||
        typeof utm_campaign !== 'undefined' ||
        typeof utm_term !== 'undefined' ||
        typeof utm_content !== 'undefined') {

        const tracking = new Tracking();
        tracking.utm_source = utm_source;
        tracking.utm_medium = utm_medium;
        tracking.utm_campaign = utm_campaign;
        tracking.utm_term = utm_term;
        tracking.utm_content = utm_content;
        this.plansService.tracking = tracking;
        
        let sub_affiliate = this.extractSubAffiliate(this.plansService.tracking.utm_source);
        if (sub_affiliate === null)
          sub_affiliate = localStorage.getItem('campaign');
        this.campaign = sub_affiliate!;
        this.plansService.campaign = sub_affiliate!;

        localStorage.setItem('tracking', JSON.stringify(this.plansService.tracking)); 
        if (newSession)
          localStorage.setItem('agentId', this.plansService.tracking.utm_term!);              
               
      }

      if (!isNaN(+utm_campaign)) {
        this.affiliateService.affiliate_id = +utm_campaign;
      }      
    });
    // -- END NEW -- //    
    this.router.events.subscribe(event => {
      if (event instanceof RouteConfigLoadStart) {
        this.loadingRouteConfig = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loadingRouteConfig = false;
      }
    });

    this.censusService.exchCompany.subscribe(x => { this.exchangeCompany = x!; });
    this.censusService.exchObs.subscribe(x => this.exchange = x!);
    this.affiliateService.affiliate$.subscribe(x => {
      if (x!.affiliateId >= 2572 && x!.affiliateId <= 2576)
        this.careington = true;
      if (x!.affiliateId === 2584 || x!.affiliateId === 2585)
        { 
          this.campaign = localStorage.getItem('campaign')!;
          if (typeof this.campaign === 'undefined' || this.campaign === null){
            //this.campaign = localStorage.getItem('campaign')!;
            //if (this.campaign === null)
            {
              this.campaign = this.extractSubAffiliate(this.plansService.tracking.utm_source)!;
              this.plansService.campaign = this.campaign;
              localStorage.setItem('campaign', this.campaign);console.log('planservice campaign', this.plansService.campaign);
            }
          }
         // localStorage.setItem('campaign', this.campaign);console.log('planservice campaign', this.plansService.campaign);
        }
        else localStorage.removeItem('campaign');
    });
  }
}
