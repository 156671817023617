import { Component, OnInit, HostBinding } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { PlansService } from '../../shared/services/plans.service';
import { CensusService } from '../../shared/services/census.service';
import { Product } from 'src/app/shared/models/product.enum';
import { Plan } from 'src/app/shared/models/plan.model';
import { map, Observable } from 'rxjs';
import { Affiliate } from 'src/app/shared/models/affiliate.model';
import { AffiliateService } from 'src/app/shared/services/affiliate.service';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { DentalPlan } from 'src/app/shared/models/dental-plan.model';
import { VisionPlan } from 'src/app/shared/models/vision-plan.model';
import { environment } from 'src/environments/environment';
import { VisionPlanCompareDetailsComponent } from '../vision-plan-compare-details/vision-plan-compare-details.component';
import { DentalPlanCompareDetailsComponent } from '../dental-plan-compare-details/dental-plan-compare-details.component';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';

@Component({
  selector: 'app-plan-compare',
  templateUrl: './plan-compare.component.html',
  styleUrls: ['./plan-compare.component.scss'],
  standalone: true,
  imports: [
    NgIf,
    RouterLink,
    DentalPlanCompareDetailsComponent,
    VisionPlanCompareDetailsComponent,
    NgFor,
    AsyncPipe,
    CurrencyPipe
  ],
})
export class PlanCompareComponent implements OnInit {
  @HostBinding('class.wrap') wrap = true;

  private planNames: string[];
  public plans!: any[];
  public loading!: boolean;
  public disclaimers!: string[];
  public product!: Product;
  public affiliate: Observable<Affiliate | null>;
  public emailAQuote = false;
  public emailSent = false;
  public enrollmentWebUrl!: string;
  public requestId!: string;

  public Products = Product;

  constructor(
    private router: Router,
    private plansService: PlansService,
    private censusService: CensusService,
    private appSettingsService: AppSettingsService,
    private affiliateService: AffiliateService
  ) {
    if (!this.plansService.plans) {
      this.router.navigate(['/plans']);
    }

    this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
    this.requestId = this.plansService.quoteResponse.requestId;

    this.affiliate = this.affiliateService.affiliate$;
    this.affiliate.subscribe(x => {
      this.emailAQuote = (environment.showEmailAffiliates.findIndex(p => p === x?.affiliateId) != -1 || environment.hideEmailAffiliates.findIndex(p => p === x?.affiliateId) == -1
        && x?.affiliateId! > 900);
    });
    this.planNames = this.plansService.plans
      .filter((p) => p.isCheckedForCompare)
      .map(
        (plan) =>
          plan.carrier.name
            .toLowerCase()
            .split(' ')
            .join('-')
            .split(')')
            .join('')
            .split('(')
            .join('') +
          '/' +
          plan.name
            .toLowerCase()
            .split(' ')
            .join('-')
            .split(')')
            .join('')
            .split('(')
            .join('')
            .split('+')
            .join('-')
            .split('/')
            .join('-')
            .split(',')
            .join('')
      );
  }

  ngOnInit() {
    this.censusService.showLinks.next(true);
    this.loading = true;
    this.loadDetails();
  }

  loadDetails() {
    this.product = this.determineProductType(this.planNames[0]);
    switch (this.product) {
      case Product.DENTAL:
        //this.emailAQuote = false;//true;
        this.loadDentalDetails();
        break;
      case Product.VISION:
        this.loadVisionDetails();
        //this.emailAQuote = false;
        break;
    }
  }

  loadDentalDetails() {
    this.plansService
      .getDentalPlanDetailsFromAPI(this.planNames, this.censusService.census)
      .subscribe((plans) => {
        if (plans && plans.length > 0) {
          this.plans = plans;
          this.loading = false;
          this.disclaimers = new Array<string>();

          plans.forEach((plan) => {
            if (
              plan.disclaimers &&
              this.disclaimers.indexOf(plan.disclaimers) === -1
            ) {
              this.disclaimers.push(plan.disclaimers);
            }
          });
          plans.forEach((plan) => {
            if (
              !plan.disclaimers &&
              plan.carrier.disclaimers &&
              this.disclaimers.indexOf(plan.carrier.disclaimers) === -1
            ) {
              this.disclaimers.push(plan.carrier.disclaimers);
            }
          });
        } else {
          this.router.navigate(['/plans']);
        }
      });
  }

  loadVisionDetails() {
    this.plansService
      .getVisionPlanDetailsFromAPI(this.planNames, this.censusService.census)
      .subscribe((plans) => {
        if (plans && plans.length > 0) {
          this.plans = plans;
          this.loading = false;
          this.disclaimers = new Array<string>();

          plans.forEach((plan) => {
            if (
              plan.disclaimers &&
              this.disclaimers.indexOf(plan.disclaimers) === -1
            ) {
              this.disclaimers.push(plan.disclaimers);
            }
          });
          plans.forEach((plan) => {
            if (
              !plan.disclaimers &&
              plan.carrier.disclaimers &&
              this.disclaimers.indexOf(plan.carrier.disclaimers) === -1
            ) {
              this.disclaimers.push(plan.carrier.disclaimers);
            }
          });
        } else {
          this.router.navigate(['/plans']);
        }
      });
  }

  determineProductType(planName: string): Product {
    if (this.plansService.plans && this.plansService.plans.length > 0) {
      // tslint:disable-next-line: max-line-length
      const plan = this.plansService.plans.find(
        (x) =>
          x.carrier.name
            .toLowerCase()
            .split(' ')
            .join('-')
            .split(')')
            .join('')
            .split('(')
            .join('') +
          '/' +
          x.name
            .toLowerCase()
            .split(' ')
            .join('-')
            .split(')')
            .join('')
            .split('(')
            .join('')
            .split('+')
            .join('-')
            .split('/')
            .join('-')
            .split(',')
            .join('') ===
          planName
      );
      return <Product>plan?.planType.productId;
    } else {
      return Product.DENTAL; // DENTAL is default product
    }
  }

  emailDetails(email: string): void {
    //this.affiliateService.affiliate$.pipe(map(aff => aff.phone)).subscribe( p => {
    //this.plansService.emailDentalPlanDetails(email, <DentalPlan>this.plan, this.plansService.quoteResponse.requestId, p);
    if (this.validateEmail(email)) {
      this.affiliateService.affiliate$
        .pipe(map((aff) => aff))
        .subscribe((p) => {
          let quotesUrl = p?.isPrerendered ? this.appSettingsService.QUOTES_URL : this.appSettingsService.AFF_QUOTES_URL;

          this.emailSent = true;
          const emailedPlan: Array<DentalPlan> = new Array<DentalPlan>();
          const emailedVisionPlan: Array<VisionPlan> = new Array<VisionPlan>();
          this.plans.forEach((plan) => {
            this.product === Product.VISION ? emailedVisionPlan.push(plan as VisionPlan) : emailedPlan.push(plan as DentalPlan);
          });
          this.plansService.requestEmailQuote(
            email,
            this.product === Product.VISION ? emailedVisionPlan : emailedPlan,
            this.plansService.quoteResponse.requestId,
            'Plan Comparison'
          );
          if (this.product === Product.VISION) {
            console.log(emailedVisionPlan); console.log('emailedPlan', emailedPlan);
            this.plansService.emailVisionPlanCompareDetails(email, emailedVisionPlan, this.plansService.quoteResponse.requestId, p?.phone, p?.affiliateId, quotesUrl);
          }
          else
            this.plansService.emailDentalPlanCompareDetails(
              email,
              emailedPlan,
              this.plansService.quoteResponse.requestId,
              p?.phone!,
              p?.affiliateId!,
              quotesUrl
            );
        });
    }
    // });
  }

  validateEmail(email: string): boolean {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(emailRegex)) return true;
    else return false;
  }

  backToTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }

  removePlan(plan: any) {
    const indexOfPlan = this.plans.findIndex((x: any) => x.planId === plan.planId);
    this.plans.splice(indexOfPlan, 1);
    if (this.plans.length === 0) {
      this.router.navigate(['/plans']);
    }
  }

  togglePlanDetailsElement(
    divPlanBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divPlanBodyExpanded.style.display === 'none';
    divPlanBodyExpanded.style.display = isShowed ? 'block' : 'none';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }
}
