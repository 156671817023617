import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AffiliateService } from '../shared/services/affiliate.service';
import { NgIf, NgClass, AsyncPipe } from '@angular/common';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [NgIf, NgClass, AsyncPipe]
})
export class HeaderComponent implements OnInit {
  public showMenu = false;
  public logoUrl$!: Observable<string | undefined | null>;
  public phone$!: Observable<string | undefined>;
  public hoursOfOperation$!: Observable<string | null | undefined>;
  public showPages$!: Observable<boolean>;
  public showVision$!: Observable<boolean | undefined>;
  public showBundled$!: Observable<boolean | undefined>;
  public careington = false;

  constructor(private router: Router,
    private affiliateService: AffiliateService) {
  }

  ngOnInit(): void {

    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showMenu = false;
      }
    });

    this.phone$ = this.affiliateService.affiliate$.pipe(map(aff => {
      return (aff?.availableCampaigns != null && aff.availableCampaigns.findIndex(x => x.codeName === localStorage.getItem('campaign')) >= 0) ?
        aff.availableCampaigns.find(x => x.codeName === localStorage.getItem('campaign'))?.phone : aff?.phone
    }));
    this.hoursOfOperation$ = this.affiliateService.affiliate$.pipe(map(aff => aff?.hoursOfOperation));
    this.logoUrl$ = this.affiliateService.affiliate$.pipe(map(aff => aff?.logoUrl));
    this.showPages$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.isPrerendered ? true : false; }));
    this.showVision$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.hasVision }));
    this.showBundled$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.hasBundled }));
    this.affiliateService.affiliate$.subscribe(x => {
      if (x?.affiliateId! >= 2572 && x?.affiliateId! <= 2576)
        this.careington = true;
    });
    this.setupMobileMenu();    
  }

  setupMobileMenu() {
    const mobilePlansChevronRight = document.getElementById('plans-closed');
    const mobilePlansChevronDown = document.getElementById('plans-opened');

    const mobileLearnChevronRight = document.getElementById('learn-closed');
    const mobileLearnChevronDown = document.getElementById('learn-opened');

    const plansMobile = document.getElementById('plans-mobile');
    const learnMobile = document.getElementById('learn-mobile');

    const findMyPlanSection = document.getElementById('find-my-plan');
    const learnSection = document.getElementById('learn');

    findMyPlanSection!.style.maxHeight = '100%';
    learnSection!.style.maxHeight = '0';


    if (mobilePlansChevronDown) {
      mobilePlansChevronDown!.style.display = 'block';
    }
    if (mobileLearnChevronDown) {
      mobileLearnChevronDown!.style.display = 'none';
    }
    if (mobilePlansChevronRight) {
      mobilePlansChevronRight!.style.display = 'none';
    }
    if (mobileLearnChevronRight) {
      mobileLearnChevronRight!.style.display = 'block';
    }

    if (plansMobile) {
      plansMobile!.addEventListener('click', () => {
        if (findMyPlanSection?.style.maxHeight == '100%') {
          mobilePlansChevronRight!.style.display = 'block';
          mobilePlansChevronDown!.style.display = 'none';
          findMyPlanSection.style.maxHeight = '0';
        } else {
          mobilePlansChevronRight!.style.display = 'none';
          mobilePlansChevronDown!.style.display = 'block';
          findMyPlanSection!.style.maxHeight = '100%';
        }
      });
    }

    if (learnMobile) {
      learnMobile!.addEventListener('click', () => {
        if(learnSection!.style.maxHeight == '100%') {          
          mobileLearnChevronRight!.style.display = 'block';
          mobileLearnChevronDown!.style.display = 'none';
          learnSection!.style.maxHeight = '0';
      } else {          
          mobileLearnChevronRight!.style.display = 'none';
          mobileLearnChevronDown!.style.display = 'block';
          learnSection!.style.maxHeight = '100%';
      }
      })
    }
  }

  menuTogglerClick() {
    this.showMenu = !this.showMenu;
  }

  isCarrierAvailable(carrierShortName: string): Observable<boolean> {
    return this.affiliateService.affiliate$.pipe(map(affiliate => {
      if (affiliate?.availableCarriers === null) {
        return true;
      }
      return (affiliate?.availableCarriers.findIndex(x => x.shortName.toLowerCase() === carrierShortName.toLowerCase())! >= 0);
    }));
  }
}
