<section class="census main-wrapper">
  <div class="container">
    <h1>Find the plan that's right for YOU</h1>
    <div class="separator-left"></div>
    <form #censusForm="ngForm" (submit)="onSubmit()">
      <app-geo name="Geo" [(ngModel)]="geo" (ngModelChange)="onGeoModelChange($event)" [showValidation]="showValidation"
        required class="data">
      </app-geo>
      <div class="separator-left"></div>
      <h3>Who is seeking services?</h3>
      <!-- Family member div -->
      <div *ngFor="let familyMember of census.familyMembers; let i = index;" class="family-members">
        <!--Applicant-->
        <div class="member-type">
          <ng-container [ngSwitch]="familyMember.familyRelationship">
            <span *ngSwitchCase="familyRelationshipOptions.APPLICANT">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
              </svg> Applicant
            </span>
            <span *ngSwitchCase="familyRelationshipOptions.SPOUSE">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                <path
                  d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
              </svg> Spouse
            </span>
            <span *ngSwitchCase="familyRelationshipOptions.CHILD">
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                <path
                  d="M120 72c0-39.765 32.235-72 72-72s72 32.235 72 72c0 39.764-32.235 72-72 72s-72-32.236-72-72zm254.627 1.373c-12.496-12.497-32.758-12.497-45.254 0L242.745 160H141.254L54.627 73.373c-12.496-12.497-32.758-12.497-45.254 0-12.497 12.497-12.497 32.758 0 45.255L104 213.254V480c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V368h16v112c0 17.673 14.327 32 32 32h16c17.673 0 32-14.327 32-32V213.254l94.627-94.627c12.497-12.497 12.497-32.757 0-45.254z" />
              </svg> Child
            </span>
          </ng-container>
          <span
            *ngIf="familyMember.familyRelationship === familyRelationshipOptions.CHILD">{{getChildNumber(familyMember)}}</span>
        </div>
        <!-- Date of Birth -->
        <div class="member-dob">
          <span>Date Of Birth</span>
          <div class="input-prepend">
            <app-date name="Birthdate{{getMemberId(familyMember)}}" [(ngModel)]="familyMember.birthdate"
              [maxDate]="getMaxDate(familyMember)" [minDate]="getMinChildDate(familyMember)" required #dob="ngModel"
              [ngClass]="{'showValidation': showValidation == true}">
            </app-date>
            <div [hidden]="dob.valid || !showValidation" class="invalid-feedback">
              <ng-container
                *ngIf="dob.errors && familyMember.familyRelationship === familyRelationshipOptions.APPLICANT">
                Enter a valid date of birth. Applicant must be 18 and over.
              </ng-container>
              <ng-container *ngIf="dob.errors && familyMember.familyRelationship === familyRelationshipOptions.SPOUSE">
                Enter a valid date of birth.
              </ng-container>
              <ng-container *ngIf="dob.errors && familyMember.familyRelationship === familyRelationshipOptions.CHILD">
                Enter a valid date of birth. A dependent over the age of 26 must be disabled.
              </ng-container>
            </div>
          </div>
        </div>
        <!-- End Date of Birth -->
        <!-- Student -->
        <div *ngIf="familyMember.familyRelationship === familyRelationshipOptions.CHILD" class="member-student">
          <input type="checkbox" name="isStudent{{getMemberId(familyMember)}}" [(ngModel)]="familyMember.isStudent"
            id="isStudent{{getMemberId(familyMember)}}">
          <label for="isStudent{{getMemberId(familyMember)}}">Student</label>
        </div>
        <!-- End Student -->
        <!-- Disabled -->
        <div *ngIf="familyMember.familyRelationship === familyRelationshipOptions.CHILD" class="member-disabled">
          <input type="checkbox" name="isDisabled{{getMemberId(familyMember)}}"
            (ngModelChange)="onDisabledClick(familyMember)" [(ngModel)]="familyMember.isDisabled"
            id="isDisabled{{getMemberId(familyMember)}}">
          <label for="isDisabled{{getMemberId(familyMember)}}">Disabled</label>
        </div>
        <!--  End Disabled -->
        <div class="member-remove">
          <!--Remove button-->
          <button *ngIf="!(familyMember.familyRelationship === familyRelationshipOptions.APPLICANT)"
            (click)="onRemoveClick(familyMember)" class="button button-medium buttom-danger">Remove</button>
          <!--End Remove button-->
        </div>
        <!--End Applicant-->
      </div>
      <button *ngIf="!spouseExists()" type="button" (click)="onAddSpouseClick()"
        class="button button-medium button-primary">Add
        Spouse</button>
      <span>&nbsp;</span>
      <button type="button" [disabled]="getChildCount() >= MAX_CHILD_LIMIT" (click)="onAddChildClick()"
        class="button button-medium button-primary">Add
        Child</button>
      <div *ngIf="exchangeDental">
        <div class="separator-left"></div>
        <div class="exchange-question"
          [ngClass]="{'alert alert-danger highlight': highlightPreviousCoverage && exchangeExistingCoverage == undefined}">
          <p>Do you currently have dental insurance coverage?</p>
          <div class="radio-group enroll-radio-group">
            <label>
              <input type="radio" name="ExistingCoverageYes" [value]="true" [(ngModel)]="exchangeExistingCoverage"
                required>Yes
            </label>
            <label>
              <input type="radio" name="ExistingCoverageNo" [value]="false" [(ngModel)]="exchangeExistingCoverage"
                required>No
            </label>
          </div>
        </div>
        <ng-container *ngIf="exchangeExistingCoverage">
          <div class="exchange-question input-group">
            <p>Which insurance Company is the coverage through?</p>
            <input type="text" class="exchange-input" name="ExistingCoverageCompany"
              [(ngModel)]="exchangeExistingCoverageCompany" required>
          </div>
          <div class="exchange-question">
            <p>Will you have been actively enrolled in your current plan for the 12 months prior to
              your requested effective date?</p>
            <div class="radio-group enroll-radio-group">
              <label>
                <input type="radio" name="ExistingCoveragePeriodYes" [value]="true"
                  [(ngModel)]="exchangeExistingCoveragePeriod" required>Yes
              </label>
              <label>
                <input type="radio" name="ExistingCoveragePeriodNo" [value]="false"
                  [(ngModel)]="exchangeExistingCoveragePeriod" required>No
              </label>
            </div>
          </div>
          <div class="exchange-question input-checkbox">
            <input type="checkbox" name="ProofOfCoverage" [(ngModel)]="proofOfCoverage" id="confirmCheckbox" required>
            <label for="confirmCheckbox">I understand that I may be required to verify proof of
              previous coverage.</label>
          </div>
        </ng-container>
      </div>
      <div class="separator-left"></div>
      <button type="submit" class="button button-medium button-warning">Continue</button>
    </form>
    <div *ngIf="affiliate | async; let affiliate;" [innerHTML]="affiliate.disclaimer" class="disclaimer-plan"></div>
  </div>
</section>