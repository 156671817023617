<footer>
    <section id="footer" class="footer">
        <div class="section-wrapper block content-1170 center-relative">
            <div class="content-wrapper">

                <div class="one_half">
                    <div class="col-1">
                        <div class="brand">
                            <img id="footerLogo" [src]="logoUrl$ | async" alt="Brand">
                        </div>
                        <div class="phone">
                            <p>{{phone$ | async}}</p>
                        </div>
                    </div>
                </div>

                <div id="footerLearningCenter" class="one_half last">
                    <div class="sep-1">
                        <ul>
                            <li *ngIf="showPages$ | async"><a href="/resources/"
                                    title="Link to the learning center to access all resources.">Learning Center</a>
                            </li>
                        </ul>
                    </div>

                    <div class="sep-2" *ngIf="showPages$ | async">
                        <ul>
                            <li><a href="/about/" title="Link to the About Us page with company information.">About
                                    Us</a></li>
                            <li><a href="/contact-us/"
                                    title="Link to the Contact Us page for additional ways to reach us.">Contact Us</a>
                            </li>
                        </ul>
                    </div>
                </div>

            </div>

            <div class="content-wrapper">
                <section class="copyright copyright-holder">
                    <div class="privacy">
                        <div class="year">© 1999 - {{currentDate | date:'yyyy'}}</div>
                        <div class="info">
                            Kelsey National Corporation or its affiliates.
                        </div>
                        <div class="all-rights">All rights reserved.</div>
                    </div>

                    <div class="site-map">
                        <ul>
                            <li><a href="/privacy-policy/" title="Link to our full privacy policy.">Privacy Policy</a>
                            </li>
                            <li><a href="/legal-terms-and-conditions/"
                                    title="Link to the Terms for using this site.">Terms of Use</a></li>
                            <li><a *ngIf="showPages$ | async" href="/site-map/"
                                    title="Link to the our full site map of all pages.">Sitemap</a>
                            </li>
                        </ul>
                    </div>
                </section>
            </div>

        </div>

    </section>

</footer>