<div *ngIf="loading" class="loader"></div>


<section class="showcase showcase-exchange">
    <div class="quote-box">

        <div class="quote-box-action">
            <h1 class="yde">Learn more about <strong>Dental</strong> <br />and <strong>Vision</strong> plans</h1>
            <p>To get started, please enter your company or organization name.<br />What company did you/will you retire
                from?
            </p>

            <form #validationForm="ngForm" (submit)="btnContinueClick()">
                <div class="exchange-group">
                    <input type="text" name="companyVariation" [disabled]="selectedCompany != null" required
                        [(ngModel)]="companyVariation">&nbsp;

                    <button type="submit" *ngIf="!selectedCompany"
                        class="button button-medium button-warning">Continue</button>

                    <button *ngIf="selectedCompany" (click)="btnResetClick()"
                        class="button button-medium button-primary">Reset Company</button>
                </div>
                <div *ngIf="selectedCompany">
                    <p>Click on a product to see available plans.</p>
                    <button *ngIf="selectedCompany.exchangeCompanyId != 236" (click)="btnDentalClick()" class="button button-medium button-warning">Dental</button>&nbsp;
                    <button (click)="btnVisionClick()" class="button button-medium button-warning">Vision</button>
                </div>
                <div *ngIf="showNotFoundError" class="not-found">
                    <div class="alert alert-danger">
                        <p> Sorry, No Match Found</p>
                        <p>Please verify that the information you entered is correct.</p>
                        <p>If you continue to have trouble, please contact your Benefit Representative at
                            <strong>855-427-4488.</strong>
                        </p>
                    </div>

                </div>
                <div *ngIf="showNotAvailableError" class="not-found">
                    <div class="alert alert-danger">
                        <p>This program is no longer available for new purchases. </p>
                        <p>Please contact your current or former employer for further information. If you currently have a dental or vision plan and have questions, please call 855-427-4488.</p>
                    </div>
                </div>
                <div *ngIf="noInputError" class="not-found">
                    <div class="alert alert-danger">
                        <p><strong>Please enter you company name or organization name.</strong></p>
                    </div>

                </div>

            </form>
        </div>
    </div>
</section>