<!-- Common Procedures -->
<section class="common-procedures mb-10 mt-10">
  <div class="plan-subtitles-w-caret-container"
    (click)="togglePlanDetailsElement(planBodyCommonProcedure, imgElementCommonProcedure)">
    <span class="subtitle-text">Common Procedures</span>
    <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementCommonProcedure />
  </div>
  <div class="procedures-table" #planBodyCommonProcedure>
    <div class="procedures-item">
      <span class="procedure">Cleaning:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.cleaning}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.cleaning !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">White filling:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.filling}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.filling !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Crown:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.crown}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.crown !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Root Canals:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.rootCanal}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.rootCanal !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Simple Extraction:</span>
      <span class="description" *ngFor="let plan of plans">{{plan.simpleExtractions}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.simpleExtractions !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Plan Maximum:</span>
      <div class="description" *ngFor="let plan of plans" [innerHTML]="plan.planMaximumDetails">
      </div>
    </div>
    <div class="procedures-item">
      <span class="procedure">Waiting Periods:</span>
      <div class="description" *ngFor="let plan of plans">
        <strong><span [innerHTML]="plan.waitingPeriods"></span></strong>
        <p *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo'">
          <em>Waiting Periods: For Vermont residents, any applicable waiting periods are limited to a maximum of 6
            months. For Maine
            residents, waiting periods do not apply to children under 19 years of age. Once enrolled, this will be
            reflected in your policy.</em>
        </p>
      </div>
    </div>
    <div class="procedures-item">
      <span class="procedure">Deductible:</span>
      <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.deductible"></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Notes:</span>
      <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.benefitsNotes"></span>
    </div>
  </div>
</section>
<!-- Additional Details -->
<section class="additional-details mb-10">
  <div class="plan-subtitles-w-caret-container" (click)="togglePlanDetailsElement(planBodyDetails, imgElementDetails)">
    <span class="subtitle-text">Details</span>
    <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementDetails />
  </div>
  <div #planBodyDetails>

    <div class="procedures-table">
      <div class="procedures-item">
        <span class="procedure">Dentist Search:</span>
        <div class="description" *ngFor="let plan of plans">
          <a class="dentist-search" *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist
            Search</a><span *ngIf="!plan.dentistSearchUrl">Dentist
            Search: N/A</span>
        </div>
      </div>
      <div class="procedures-item">
        <span class="procedure">Exams:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.examsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Cleanings:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.regularCleaningsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">X-Rays:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.xRaysDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Fillings:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.fillingsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Extractions:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.extractionsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Crowns:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.crownsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Root Canals:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.rootCanalDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Dentures:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.denturesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Deep Cleanings:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.deepCleaningsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Implants:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.implantsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Braces/Orthodontia:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.bracesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Teeth Whitening:</span>
        <span class="description" *ngFor="let plan of plans" [innerHTML]="plan.teethWhiteningDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Additional Network Information:</span>
        <span class="description" *ngFor="let plan of plans">
          <span [innerHTML]="plan.dentistNetworkDetails"></span>
          <a *ngIf="plan.exclusionsAndLimitationsUrl && plan.exclusionsAndLimitationsUrl.toLowerCase().startsWith('http'); else internalExclusions"
            href="{{plan.exclusionsAndLimitationsUrl}}" target="_blank">Exclusions and Limitations</a>
          <ng-template #internalExclusions>
            <a *ngIf="plan.exclusionsAndLimitationsUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/exclusions_and_limitations/{{plan.exclusionsAndLimitationsUrl}}"
              target="_blank">Exclusions and Limitations</a>
            <span *ngIf="!plan.exclusionsAndLimitationsUrl">Exclusions and Limitations: N/A</span>
          </ng-template>
          <br />
          <ng-container *ngIf="plan.carrier.shortName.toLowerCase() === 'deltadentaltn'">
            <a href="/assets/pdfs/deltadentaltn/privacy_policies/hipaa_notice.pdf" target="_blank">HIPAA Privacy
              Notice</a><br>
          </ng-container>
          <ng-container
            *ngIf="!(plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'ameritasny')">
            <a *ngIf="plan.privacyPolicyUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/privacy_policies/{{plan.privacyPolicyUrl}}"
              target="_blank">Privacy Policy</a>
            <span *ngIf="!plan.privacyPolicyUrl">Privacy
              Policy: N/A</span>
          </ng-container>
          <br />
          <a *ngIf="plan.planBrochureUrl && plan.planBrochureUrl.toLowerCase().startsWith('http'); else internalBrochure"
            href="{{plan.planBrochureUrl}}" target="_blank">Plan Brochure</a>
          <ng-template #internalBrochure>
            <a *ngIf="plan.planBrochureUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/plan_brochures/{{plan.planBrochureUrl}}"
              target="_blank">Plan Brochure</a>
            <span *ngIf="!plan.planBrochureUrl">Plan Brochure: N/A</span>
          </ng-template>
          <br />
          <ng-container
            *ngIf="plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'humana' || plan.carrier.shortName.toLowerCase() === 'metlife'">
            <a *ngIf="plan.dentalBenefitsUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/state_matrix/{{plan.dentalBenefitsUrl}}"
              target="_blank">Summary of Dental Benefits and Coverage Disclosure Matrix</a><br>
          </ng-container>
        </span>
      </div>
      <div class="procedures-item" *ngFor="let plan of plans">
        <ng-template *ngIf="plan.benefitsDetailsNotes">
          <span class="procedure">Notes:</span>
          <span class="description" [innerHTML]="plan.benefitsDetailsNotes"></span>
        </ng-template>
      </div>
    </div>
  </div>
</section>