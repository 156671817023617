import { Component, OnInit, Input } from '@angular/core';
import { VisionPlan } from 'src/app/shared/models/vision-plan.model';
import { NgIf } from '@angular/common';

@Component({
  selector: 'app-vision-details-table',
  templateUrl: './vision-details-table.component.html',
  styleUrls: ['./vision-details-table.component.scss'],
  standalone: true,
  imports: [NgIf],
})
export class VisionDetailsTableComponent implements OnInit {
  @Input() plan!: VisionPlan;

  constructor() {}

  ngOnInit() {}
  
  togglePlanDetailsElement(
    divPlanBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divPlanBodyExpanded.style.display === 'none';
    divPlanBodyExpanded.style.display = isShowed ? 'block' : 'none';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }
}
