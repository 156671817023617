<header class="header-holder">
    <nav>
        <div class="wrapper">
            <div class="logo">                
                <a [routerLink]="[homeUrl$ | async]"><img [src]="logoUrl$ | async" alt="Brand" /></a>
            </div>
        
            <ul class="nav-links">
                <li class="phone">
                    <a [attr.href]="'tel:' + (phone$ | async)">{{phone$ | async}}</a>
                </li>
            </ul>
            <div class="btn menu-btn phone-mobile"><a [attr.href]="'tel:' + (phone$ | async)" id="phone_text_mobile"><i class="fas fa-phone"></i></a></div>            
        </div>
    </nav>
</header>