import { Injectable } from '@angular/core';

import { Census } from '../models/census.model';
import { FamilyMember } from '../models/family-member.model';
import { FamilyRelationship } from '../models/family-relationship.enum';
import { HttpService } from './http.service';
import { map } from 'rxjs/operators';
import { AppSettingsService } from './app-settings.service';
import { Tracking } from '../models/tracking.model';
import { ExchangeCompany } from 'src/app/exch/models/exchange-company.model';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CensusService {

  public census!: Census;
  public defaultCensus!: boolean;
  public updated!: boolean;
  public exchange!: boolean;
  public exchObs = new BehaviorSubject<boolean | null>(null);

  public exchangeDental!: boolean;
  public exchangeVision!: boolean;
  public exchangeCompany!: ExchangeCompany  | null;
  public callCenterId!: number;

  public exchCompany = new BehaviorSubject<ExchangeCompany | null>(null);

  public exchangeExistingCoverage: boolean | undefined;
  public exchangeExistingCoverageCompany!: string;
  public exchangeExistingCoveragePeriod: boolean | undefined;
  public proofOfCoverage!: boolean;
  public hideFooter!: boolean;
  public showLinks = new BehaviorSubject(false);
  public showVision!: boolean;

  saveToLocalStorage() {
    if (this.census) {
      localStorage.setItem('census', JSON.stringify(this.census));
    }
    if (this.defaultCensus !== undefined) {
      localStorage.setItem('defaultCensus', this.defaultCensus.toString());
    }
    if (this.exchange !== undefined) {
      localStorage.setItem('exchange', this.exchange.toString());
    }
    if (this.exchangeDental !== undefined) {
      localStorage.setItem('exchangeDental', this.exchangeDental.toString());
    }
    if (this.exchangeVision !== undefined) {
      localStorage.setItem('exchangeVision', this.exchangeVision.toString());
    }

    if (this.exchangeCompany) {
      localStorage.setItem('exchangeCompany', JSON.stringify(this.exchangeCompany));
    }

    if (this.callCenterId) {
      localStorage.setItem('callCenterId', this.callCenterId.toString());
    }

    if (this.exchangeExistingCoverage !== undefined) {
      localStorage.setItem('exchangeExistingCoverage', this.exchangeExistingCoverage.toString());
    }
    if (this.exchangeExistingCoverageCompany !== undefined) {
      localStorage.setItem('exchangeExistingCoverageCompany', this.exchangeExistingCoverageCompany);
    }
    if (this.exchangeExistingCoveragePeriod !== undefined) {
      localStorage.setItem('exchangeExistingCoveragePeriod', this.exchangeExistingCoveragePeriod.toString());
    }
    if (this.proofOfCoverage !== undefined) {
      localStorage.setItem('proofOfCoverage', this.proofOfCoverage.toString());
    }
    if (this.hideFooter !== undefined) {
      localStorage.setItem('hideFooter', this.hideFooter.toString());
    }
    if (this.showVision != undefined){
      localStorage.setItem('showVision', this.showVision.toString());
    }
  }

  loadFromLocalStorage() {
    if (localStorage.getItem('census')) {
      this.census = <Census>JSON.parse(localStorage.getItem('census')!);
    }
    this.defaultCensus = localStorage.getItem('defaultCensus') === 'true';
    this.showVision = localStorage.getItem('showVision') === 'true';
    this.exchange = localStorage.getItem('exchange') === 'true';
    this.exchangeDental = localStorage.getItem('exchangeDental') === 'true';
    this.exchangeVision = localStorage.getItem('exchangeVision') === 'true';

    if (localStorage.getItem('exchangeCompany')) {
      this.exchangeCompany = <ExchangeCompany>JSON.parse(localStorage.getItem('exchangeCompany')!);
      this.exchCompany.next(this.exchangeCompany);
    

    this.callCenterId = +localStorage.getItem('callCenterId')!;
    this.exchangeExistingCoverage = localStorage.getItem('exchangeExistingCoverage') === 'true';
    this.exchangeExistingCoverageCompany = localStorage.getItem('exchangeExistingCoverageCompany')!;
    this.exchangeExistingCoveragePeriod = localStorage.getItem('exchangeExistingCoveragePeriod') === 'true';

    this.proofOfCoverage = localStorage.getItem('proofOfCoverage') === 'true';
    this.hideFooter = localStorage.getItem('hideFooter') === 'true';
    }
  }


  constructor(private http: HttpService,
    private appSettings: AppSettingsService) {
    this.census = new Census(
      null,
      null,
      null,
      null,
      null,
      [new FamilyMember(FamilyRelationship.APPLICANT, null, null, false, false, false)]
    );
  }

  public getCensusFromAPI(requestId: string) {
    return this.http.get(this.appSettings.API_ENDPOINT + '/quotes/census?requestId=' + requestId)
      .pipe(
        map((response: any) => { return { census: <Census>response.census, tracking: <Tracking>response.tracking } }));
  }
}
