import { Component, Input, ElementRef, forwardRef, OnChanges } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR, NG_VALIDATORS, UntypedFormControl, FormsModule } from '@angular/forms';

export function createDateRangeValidator(minValue: Date, maxValue: Date) {
  return function validateDateRange(c: UntypedFormControl) {
    const value = new Date(c.value);
    const minValueDate = new Date(minValue);
    const maxValueDate = new Date(maxValue);
    const err = {

      rangeError: {
        given: value,
        min: minValue,
        max: maxValue,
      }
    };

    return (value > maxValueDate || value < minValueDate) ? err : null;
  };
}

@Component({
    selector: 'app-date',
    templateUrl: './date.component.html',
    styleUrls: ['./date.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateComponent), multi: true }
    ],
    standalone: true,
    imports: [FormsModule]
})
export class DateComponent implements ControlValueAccessor, OnChanges {
  public disabled = false;
  public readonly = false;
  @Input() minDate!: Date;
  @Input() maxDate!: Date;

  public month!: number;
  public day!: number;
  public year!: number;

  private validateFn: any = () => { };

  private propagateChange = (_: any) => { };
  private propagateTouched = (_: string) => { };

  constructor(private element: ElementRef) { }

  private stripNonDigits(value: string): string {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    return newValue;
  }

  // this function converts double-digit entered year into proper 4-digit year
  private normalizeYear(year: number): number {
    year = +year;
    const maxYear = new Date().getFullYear() - 2000 + 5;

    if (year > maxYear && year <= 99 && year < 1900) {
      year = 1900 + year;
    } else if (year <= maxYear && year < 1900) {
      year = 2000 + year;
    }
    return year;
  }

  isValidDate(year: number, month: number, day: number): boolean {

  //  year = this.normalizeYear(year);
    // Check the ranges of month and year
    if (isNaN(year) || isNaN(month) || isNaN(day) || year < 1900 || month === 0 || month > 12) {
      return false;
    }

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    // Adjust for leap years
    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
      monthLength[1] = 29;
    }

    // Check the range of the day
    return day > 0 && day <= monthLength[month - 1];
  }

  onModelChange() {
    this.propagateChange(this.value);
  }

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
    if (target.name === 'month' && target.value.length === 2) {
      this.element.nativeElement.children[0].children[1].focus();
    } else if (target.name === 'day' && target.value.length === 2) {
      this.element.nativeElement.children[0].children[2].focus();
    }
  }

  onFocusDayFunction() {
    // if (this.element.nativeElement.children[0].children[0].value === '') {
    //   this.element.nativeElement.children[0].children[0].focus();
    // }
  }

  onFocusYearFunction() {
    // if (this.element.nativeElement.children[0].children[1].value === '') {
    //   this.element.nativeElement.children[0].children[1].focus();
    // }
  }

  get value(): string | null {
    if (this.isValidDate(this.year, this.month, this.day)) {
      return `${this.month}/${this.day}/${this.year}`;
    } else {
      return null;
    }
  }

  set value(newValue: string) {
    if (newValue) {
      const firstSlashIndex = newValue.indexOf('/');
      const seconSlashIndex = newValue.indexOf('/', firstSlashIndex + 1);
      const monthString = newValue.substr(0, firstSlashIndex);
      const dayString = newValue.substr(firstSlashIndex + 1, seconSlashIndex - firstSlashIndex - 1);
      const yearString = newValue.substr(seconSlashIndex + 1);
      this.month = +monthString;
      this.day = +dayString;
      this.year = +yearString;
      this.propagateChange(this.value);
    }
  }

  writeValue(value: string) {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  ngOnChanges(changes:any) {
    if (changes.minDate || changes.maxDate) {
      this.validateFn = createDateRangeValidator(this.minDate, this.maxDate);
    }
  }

  validate(c: UntypedFormControl) {
    return this.validateFn(c);
  }
}
