import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-plans-available',
    templateUrl: './no-plans-available.component.html',
    styleUrls: ['./no-plans-available.component.scss'],
    standalone: true
})
export class NoPlansAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}