import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AffiliateService } from '../shared/services/affiliate.service';
import { NgIf, AsyncPipe, DatePipe } from '@angular/common';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss'],
    standalone: true,
    imports: [NgIf, AsyncPipe, DatePipe]
})
export class FooterComponent implements OnInit {
  private readonly defaultLogo = 'assets/img/svg/new-logo.svg';
  private readonly poweredByLogo = 'assets/img/powered-by-dicom-logo-small.png'

  public currentDate = new Date();
  public logoUrl$: Observable<string>;
  public phone$!: Observable<string | undefined>;
  public showPages$: Observable<boolean>;

  constructor(private affiliateService: AffiliateService) {
    this.phone$ = this.affiliateService.affiliate$.pipe(map(aff => {
      return (aff?.availableCampaigns != null && aff.availableCampaigns.findIndex(x => x.codeName === localStorage.getItem('campaign')) >= 0) ?
        aff.availableCampaigns.find(x => x.codeName === localStorage.getItem('campaign'))?.phone : aff?.phone
    }));
    this.logoUrl$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.isPrerendered ? this.defaultLogo : this.poweredByLogo; }));
    this.showPages$ = this.affiliateService.affiliate$.pipe(map(aff => { return aff?.isPrerendered ? true : false; }));
  }

  ngOnInit(): void {
  }

}
