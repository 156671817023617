import { FilterOption } from './filter-option.model';

export class PlanFilter {
    public productFilter!: FilterOption[];
    public carriersFilter!: FilterOption[];
    public planTypesFilter!: FilterOption[];
    public premiumFilter!: FilterOption[];
    public planMaxFilter!: FilterOption[];
    public implantBenefitFilter!: FilterOption[];
    public childOrthoBenefitFilter!: FilterOption[];
    public adultOrthoBenefitFilter!: FilterOption[];
    public teethWhiteningBenefitFilter!: FilterOption[];
}
