<div *ngIf="loading" class="loader"></div>

<section id="copy-sent-popup" *ngIf="emailSent">
  <div class="popup-container">
    <div class="popup-header">
      <span class="popup-title">Success!</span>
      <span class="close" (click)="emailSent = false">&#x2715;</span>
    </div>
    <div class="image-holder">
      <img src="assets/img/png/check.png" alt="check">
    </div>
    <div class="popup-body">
      <span>A summary of this plan information has been sent. Thank you. <br> If you do not see the
        email from DentalInsurance.com in a few minutes, please check your “junk mail” or “spam”
        folder.</span>
    </div>
  </div>
  <div id="overlay"></div>
</section>

<main id="content" class="section no-page-title">
  <section id="plans-comparison">
    <div class="section-wrapper block content-1170 center-relative">
      <!-- back btn and email field -->
      <div class="header-elements">
        <div class="single-button-container">
          <div class="gray-button">
            <span>
              <i class="fas fa-chevron-left"></i>
              <a [routerLink]="['/plans']">Return to Plan List</a>
            </span>
          </div>
        </div>
        <div *ngIf="emailAQuote" class="input-container-details-plan">
          <div>
            <input type="text" class="form-control" #txtEmail placeholder="Email me a copy of this comparison"
              aria-label="Email" aria-describedby="Email" id="email" name="email">
            <i class="fas fa-paper-plane" id="send-copy-icon" (click)="emailDetails(txtEmail.value)"></i>
          </div>
        </div>
      </div>
      <!-- Cards section -->
      <section class="comparison-section">
        <h2 *ngIf="product === Products.DENTAL">Select the right dental plan for you</h2>
        <h2 *ngIf="product === Products.VISION">Select the right vision plan for you</h2>
        <div class="comparison-cards">
          <div class="cards-empty"></div>
          <div class="card-item" *ngFor="let plan of plans">
            <div class="card-comp-header">
              <div *ngIf="plan.planLabel" class="recommended-badge-container">
                <span>{{plan.planLabel}}</span>
                <img src="assets/img/svg/stars.svg" alt="stars">
              </div>
              <div *ngIf="plan.planLabel" class="recommended-badge-mobile">
                <img src="assets/img/svg/stars.svg" alt="stars">
              </div>
              <div class="close-btn">
                <span (click)="removePlan(plan)">&#x2715;</span>
              </div>
            </div>
            <div class="badges-section">
              <img class="logo" src="{{plan.carrier.logoUrl}}" alt="Company logo" />
              <div class="price-container">
                <span class="price">{{plan.premium | currency:"USD":'symbol'}}</span>
                <span class="month">/mo.</span>
              </div>
            </div>
            <div class="mt-12">
              <div class="plan-name-container">
                <span>{{plan.name}}</span>
              </div>
              <div class="plan-type-container">
                <span class="hide_on_mobile">Plan Type: </span><span>{{plan.planType.name}}</span>
              </div>
              <div class="enroll-btn">
                <a href="{{enrollmentWebUrl}}?planId={{plan.planId}}&quoteRequestId={{requestId}}"><strong>Enroll
                    Now</strong></a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <app-dental-plan-compare-details *ngIf="product === Products.DENTAL" [plans]="plans">
      </app-dental-plan-compare-details>
      <app-vision-plan-compare-details *ngIf="product === Products.VISION" [plans]="plans">
      </app-vision-plan-compare-details>
      <!-- Disclaimers -->
      <div class="disclaimer-section">
        <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
        <section class="content-section">
          <hr>
          <!-- <div class="plan-subtitles-w-caret-container"
          (click)="togglePlanDetailsElement(planBodyDisclaimers, imgElementDisclaimers)">
          <span class="subtitle-text">Disclaimers &amp; Disclosures</span>
          <img src="assets/img/svg/arrow-up.svg" alt="up" #imgElementDisclaimers />
        </div> -->
          <div *ngIf="affiliate | async; let affiliate;" [innerHTML]="affiliate.disclaimer"></div>
          <p *ngFor="let disclaimer of disclaimers" [innerHTML]="disclaimer"></p>
          <!-- <ng-container *ngFor="let plan of plans">
            <p *ngIf="plan.disclaimers" [innerHTML]="plan.disclaimers"></p>
            <p *ngIf="!plan.disclaimers" [innerHTML]="plan.carrier.disclaimers"></p>
          </ng-container> -->
          <hr/>
          <p>This website provides a very brief description of some of the important features of this plan. It is not
            the
            insurance contract, nor does it represent the insurance contract. A full description of benefits, exclusions
            and limitations is contained in the Schedule of Benefits and your policy.</p>
          <p>Rates shown are based upon the information you provided, and are subject to change based on the dental
            and/or vision plan's underwriting practices and your selection of available optional benefits, if any.
            Final rates and effective dates are subject to underwriting and are always determined by the dental
            insurance and/or vision insurance company. To be considered for reimbursement, expenses must qualify as
            covered expenses.</p>
          <p>This site was designed to provide you with a general description of the plans you requested. Keep in mind
            that it does not include all the benefits and limitations outlined in the policies - it is the insurance
            contract, not the general descriptions on this website, which forms the contract between you and the
            insurance company.</p>
          <p>DentalInsurance.com is paid by the Insurance Company we represent. We earn commissions for each policy we
            sell. The commission rates vary by Insurance Company and may increase based on sales volume. There may also
            be
            a bonus or incentive. For more information on a purchased plan or plan quoted to you, please reach out
            through
            our contact us page.</p>
          <p>DentalInsurance.com Inc, its suppliers or any third parties named in this site are not liable for any
            damages that result from using, or inability to use, the website and material listed within it, whether
            based on warranty, contract, tort or any other legal theory and whether or not DentalInsurance.com Inc is
            advised of the possibility of such damages. This includes, but is not limited to, incidental and
            consequential damages, lost profits or damages resulting from lost data or business interruptions.</p>
          <p>The use of the website and its material, some of it supplied by third parties, is at your own risk.
            Although we strive to provide the most current and complete information on this website, we make no
            representations about the accuracy, completeness or timeliness of the material found here. Please note that
            updates are made periodically to the website and we reserve the right to make them at any time.</p>
        </section>
      </div>
      <div class="single-button-container">
        <div class="button-holder" id="full-btn" (click)="backToTop()">
          <span>Back To Top</span>
        </div>
      </div>
    </div>
  </section>
</main>