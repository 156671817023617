import { Component, OnInit } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-plan-not-available',
    templateUrl: './plan-not-available.component.html',
    styleUrls: ['./plan-not-available.component.scss'],
    standalone: true,
    imports: [RouterLink]
})
export class PlanNotAvailableComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
