<section class="common-procedures mt-10">
  <div class="plan-subtitles-w-caret-container"
    (click)="togglePlanDetailsElement(planBodyCommonProcedure, imgElementCommonProcedure)">
    <span class="subtitle-text">Common Procedures</span>
    <img src="assets/img/svg/arrow-down.svg" #imgElementCommonProcedure />
  </div>
  <div class="procedures-table" #planBodyCommonProcedure [style.display]="'none'">
    <div class="procedures-item">
      <span class="procedure">Eye Exams:</span>
      <span class="description">{{plan.eyeExams}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Rx Frames:</span>
      <span class="description">{{plan.rxFrames}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Rx Lenses:</span>
      <span class="description">{{plan.rxLenses}}</span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Contact Lenses:</span>
      <span class="description">{{plan.contactLenses}}</span>
    </div>
    <div class="observations-section">
      <span [innerHTML]="plan.benefitsNotes"></span>
    </div>
  </div>
</section>
<section class="additional-details mt-10">
  <div class="plan-subtitles-w-caret-container"
    (click)="togglePlanDetailsElement(planBodyDetails, imgElementCommonDetails)">
    <span class="subtitle-text">Details</span>
    <img src="assets/img/svg/arrow-down.svg" #imgElementCommonDetails />
  </div>

  <div #planBodyDetails [style.display]="'none'">
    <a class="dentist-search" *ngIf="plan.doctorSearchUrl" href="{{plan.doctorSearchUrl}}" target="_blank">Provider
      Search</a><span *ngIf="!plan.doctorSearchUrl">Provider Search: N/A</span>
    <div class="procedures-table">
      <div class="procedures-item">
        <span class="procedure">Eye Exams:</span>
        <span class="description" [innerHTML]="plan.eyeExamsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Frames:</span>
        <span class="description" [innerHTML]="plan.framesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Basic Lenses:</span>
        <span class="description" [innerHTML]="plan.basicLensesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Lens Enhancements:</span>
        <span class="description" [innerHTML]="plan.lensEnhancementsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Contact Lenses:</span>
        <span class="description" [innerHTML]="plan.contactLensesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Additional Network Information:</span>
        <span class="description">
          <span [innerHTML]="plan.doctorNetworkDetails"></span>
          <a *ngIf="plan.exclusionsAndLimitationsUrl"
            href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/exclusions_and_limitations/{{plan.exclusionsAndLimitationsUrl}}"
            target="_blank">Exclusions and Limitations</a>
          <span *ngIf="!plan.exclusionsAndLimitationsUrl">Exclusions and Limitations: N/A</span>
          <br />
          <ng-container
            *ngIf="!(plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'ameritasny')">
            <a *ngIf="plan.privacyPolicyUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/privacy_policies/{{plan.privacyPolicyUrl}}"
              target="_blank">Privacy Policy</a>
            <span *ngIf="!plan.privacyPolicyUrl">Privacy
              Policy: N/A</span>
          </ng-container>
          <br />
          <a *ngIf="plan.planBrochureUrl"
            href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/plan_brochures/{{plan.planBrochureUrl}}"
            target="_blank">Plan Brochure</a>
        </span>
      </div>
      <div *ngIf="plan.benefitsDetailsNotes" class="procedures-item">
        <span class="procedure">Notes:</span>
        <span class="description" [innerHTML]="plan.benefitsDetailsNotes"></span>
      </div>
    </div>

  </div>
</section>