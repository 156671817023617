<div class="applicant-badge-container badge-fix">
  <span class="material-symbols-outlined icon">person</span>
  <span>{{census.zip}}, {{census.stateAbbr}}</span>
  <span>|</span>
  <span>Applicant, </span>
  <span>{{getApplicantAge()}}</span>
  <ng-container *ngIf="getDependentCount() > 0">
    <span>|</span>
    <span>+{{getDependentCount()}} member</span>
  </ng-container>
  <span class="material-symbols-outlined icon edit">edit</span>
</div>