import { Component, OnInit, HostBinding } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { PlansService } from '../../shared/services/plans.service';
import { CensusService } from '../../shared/services/census.service';
import { Census } from '../../shared/models/census.model';
import { AppSettingsService } from '../../shared/services/app-settings.service';
import { Gender } from '../../shared/models/gender.enum';
import { GeoService } from '../../shared/services/geo.service';
import { Product } from 'src/app/shared/models/product.enum';
import { Plan } from 'src/app/shared/models/plan.model';
import { Affiliate } from 'src/app/shared/models/affiliate.model';
import { map, Observable } from 'rxjs';
import { AffiliateService } from 'src/app/shared/services/affiliate.service';
import { Tracking } from 'src/app/shared/models/tracking.model';
import { DentalPlan } from 'src/app/shared/models/dental-plan.model';
import { VisionPlan } from 'src/app/shared/models/vision-plan.model';
import { environment } from 'src/environments/environment';
import { VisionDetailsTableComponent } from '../vision-details-table/vision-details-table.component';
import { DentalDetailsTableComponent } from '../dental-details-table/dental-details-table.component';
import { CensusWidgetComponent } from '../census-widget/census-widget.component';
import { NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';
import { CensusPopupComponent } from "../census-popup/census-popup.component";

@Component({
    selector: 'app-plan-details',
    templateUrl: './plan-details.component.html',
    styleUrls: ['./plan-details.component.scss'],
    standalone: true,
    imports: [
        NgIf,
        CensusWidgetComponent,
        RouterLink,
        DentalDetailsTableComponent,
        VisionDetailsTableComponent,
        NgFor,
        AsyncPipe,
        CurrencyPipe,
        CensusPopupComponent
    ]
})
export class PlanDetailsComponent implements OnInit {
  @HostBinding('class.wrap') wrap = true;

  public plan!: any;
  private planName!: string;
  private carrierName!: string;
  public census!: Census;
  public requestId!: string;
  public enrollmentWebUrl!: string;
  public loading!: boolean;
  public defaultCensus!: boolean;
  public affiliate!: Observable<Affiliate | null>;
  public emailAQuote = false;
  public emailSent = false;
  public isFlag!: boolean;
  public quotesUrl!: string;
  public careington = false;
  public showCensusPopup = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private plansService: PlansService,
    public censusService: CensusService,
    private affiliateService: AffiliateService,
    private planService: PlansService,
    private appSettingsService: AppSettingsService,
    private geoService: GeoService
  ) {
    this.census = censusService.census;
    this.affiliate = this.affiliateService.affiliate$;
    this.isFlag = false;
    this.affiliate.subscribe((x) => {
      this.emailAQuote =
        environment.showEmailAffiliates.findIndex(
          (p) => p === x?.affiliateId
        ) != -1 ||
        (environment.hideEmailAffiliates.findIndex(
          (p) => p === x?.affiliateId
        ) == -1 &&
          x?.affiliateId! > 900);
    });
    this.affiliateService.affiliate$.subscribe((x) => {
      if (x?.affiliateId! >= 2572 && x?.affiliateId! <= 2576)
        this.careington = true;
    });
  }

  toTitleCase(input: string): string {
    if (!input || input.length < 1) {
      return input;
    } else {
      let output = '';
      for (let i = 0; i < input.length; i++) {
        if (i === 0 || input[i - 1] === '-') {
          output += input[i].toUpperCase();
        } else if (input[i] === '-') {
          output += ' ';
        } else {
          output += input[i];
        }
      }
      return output;
    }
  }

  ngOnInit() {
    this.censusService.showLinks.next(true);
    this.loading = true;
    this.route.params.subscribe((params) => {
      this.planName = params['planName'];
      this.carrierName = this.router.url.substring(
        1,
        this.router.url.indexOf('/', 1)
      );
      const requestId = this.route.snapshot.queryParamMap.get('requestId');
      const fId = this.route.snapshot.queryParamMap.get('isFlag');

      if (fId === 'true') {
        this.isFlag = true;
      }

      const fullPlanName =
        this.carrierName.toLowerCase().split(' ').join('-') +
        '/' +
        this.planName.toLowerCase();

      this.defaultCensus = this.censusService.defaultCensus;
      this.census = this.censusService.census;

      if (!this.census.zip || this.defaultCensus) {
        if (localStorage.getItem('tracking')) {
          const tracking = <Tracking>(
            JSON.parse(localStorage.getItem('tracking')!)
          );
          this.plansService.tracking = tracking;

          if (tracking && tracking.utm_campaign) {
            const affiliateId = +tracking.utm_campaign;
            if (!isNaN(affiliateId) && window !== undefined) {
              this.affiliateService.affiliate$.subscribe((x) => {
                window.affiliate_name = x?.name;
                window.utm_campaign = affiliateId;
              });
              this.affiliateService.affiliate_id = affiliateId;
            }
          }
        }

        if (requestId) {
          this.censusService.getCensusFromAPI(requestId).subscribe((data) => {
            const tracking = data.tracking;
            this.plansService.tracking = tracking;
            if (tracking && tracking.utm_campaign) {
              const affiliateId = +tracking.utm_campaign;
              if (!isNaN(affiliateId) && window !== undefined) {
                this.affiliateService.affiliate$.subscribe((x) => {
                  window.affiliate_name = x?.name;
                  window.utm_campaign = affiliateId;
                });
                this.affiliateService.affiliate_id = affiliateId;
              }
            }
            data.census.requestedEffectiveDate =
              data.census.requestedEffectiveDate;
            this.censusService.census = data.census;
            this.census = this.censusService.census;
            this.censusService.defaultCensus = this.defaultCensus = false;
            this.censusService.updated = true;
            this.censusService.saveToLocalStorage();
            if (this.isFlag) {
              this.plansService.tracking.utm_medium = 'email-quote';
            }
            if (!localStorage.getItem('tracking')) {
              localStorage.setItem(
                'tracking',
                JSON.stringify(this.plansService.tracking)
              );
              localStorage.setItem('source', this.plansService.tracking.utm_source!);
              localStorage.setItem('medium', this.plansService.tracking.utm_medium!);
              localStorage.setItem('agentId', this.plansService.tracking.utm_term!);
              localStorage.setItem('content', this.plansService.tracking.utm_content!);
            }
            this.plansService
              .getQuoteFromAPI(this.census)
              .subscribe((quote) => {
                this.loadDetails();
              });
          });
        } else if (
          localStorage.getItem('census') &&
          this.censusService.defaultCensus !== true
        ) {
          this.censusService.defaultCensus = this.defaultCensus = false;
          this.censusService.loadFromLocalStorage();
          this.census = this.censusService.census;
          this.censusService.updated = true;
          this.plansService.getQuoteFromAPI(this.census).subscribe((quote) => {
            this.loadDetails();
          });
        } else {
          this.censusService.defaultCensus = this.defaultCensus = true;
          this.census.familyMembers[0].birthdate = '01/01/1980';
          this.census.familyMembers[0].gender = Gender.UNKNOWN; //Gender.FEMALE;
          this.census.requestedEffectiveDate =
            this.getAvailableEffectiveDates()[0];
          this.censusService.saveToLocalStorage();

          this.plansService
            .getDefaultPlanGeo(fullPlanName)
            .subscribe((result) => {
              if (
                result &&
                ((<unknown>result.stateAbbr) as string).length > 0
              ) {
                this.geoService.getGeoFromAPI(+result.zip).subscribe((geos) => {
                  if (geos.length > 0) {
                    this.census.zip = geos[0].zip;
                    this.census.stateAbbr = geos[0].stateAbbr;
                    this.census.county = geos[0].county;
                    this.censusService.census = this.census;
                    this.plansService
                      .getQuoteFromAPI(this.census)
                      .subscribe((quote) => {
                        this.loadDetails();
                      });
                  } else {
                    this.router.navigate(['/census']);
                  }
                });
              } else {
                this.router.navigate(['/census']);
              }
            });
        }
      } else {
        this.censusService.defaultCensus = this.defaultCensus = false;
        this.loadDetails();
      }
    });
  }

  loadDetails() {
    let product = this.determineProductType(this.planName);
    if (product) {
      switch (product) {
        case Product.DENTAL:
          this.loadDentalDetails();
          break;
        case Product.VISION:
          this.loadVisionDetails();
          break;
      }
    } else {
      this.router.navigate(['/plan-not-available']);
    }
  }

  loadDentalDetails() {
    this.plansService
      .getDentalPlanDetailsFromAPI(
        [this.carrierName + '/' + this.planName],
        this.census
      )
      .subscribe((plans) => {
        if (plans && plans.length > 0) {
          this.plan = plans[0];
          this.requestId = this.plansService.quoteResponse.requestId;
          this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
          this.loading = false;
        }
        // else {
        //   this.plansService.getDefaultState(this.planName).subscribe(state => {
        //     if (state && (<unknown>state as string).length > 0) {
        //       let defaultGeo;

        //       this.geoService.getDefaultGeoFromAPI(<unknown>state as string).subscribe(geos => {

        //         if (geos.length > 0) {
        //           defaultGeo = geos[0];
        //           this.censusService.census.zip = defaultGeo.zip;
        //           this.censusService.census.stateAbbr = defaultGeo.stateAbbr;
        //           this.censusService.census.county = defaultGeo.county;
        //         }
        //       });
        //       this.censusService.defaultCensus = true;
        //       this.ngOnInit();
        //     } else {
        //       this.router.navigate(['/plans']);
        //     }
        //   });
        // }
      });
  }

  loadVisionDetails() {
    this.plansService
      .getVisionPlanDetailsFromAPI(
        [this.carrierName + '/' + this.planName],
        this.census
      )
      .subscribe((plans) => {
        if (plans && plans.length > 0) {
          this.plan = plans[0];
          this.requestId = this.plansService.quoteResponse.requestId;
          this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
          this.loading = false;
        }
        //  else {
        //   this.plansService.getDefaultState(this.planName).subscribe(state => {
        //     if (state && (<unknown>state as string).length > 0) {
        //       let defaultGeo;

        //       this.geoService.getDefaultGeoFromAPI(<unknown>state as string).subscribe(geos => {

        //         if (geos.length > 0) {
        //           defaultGeo = geos[0];
        //           this.censusService.census.zip = defaultGeo.zip;
        //           this.censusService.census.stateAbbr = defaultGeo.stateAbbr;
        //           this.censusService.census.county = defaultGeo.county;
        //         }
        //       });
        //       this.censusService.defaultCensus = true;
        //       this.ngOnInit();
        //     } else {
        //       this.router.navigate(['/plans']);
        //     }
        //   });
        // }
      });
  }

  determineProductType(planName: string): Product | null {
    if (this.plansService.plans && this.plansService.plans.length > 0) {
      // tslint:disable-next-line: max-line-length
      const plan = this.plansService.plans.find(
        (x) =>
          x.name
            .toLowerCase()
            .split(' ')
            .join('-')
            .split(')')
            .join('')
            .split('(')
            .join('')
            .split('+')
            .join('-')
            .split('/')
            .join('-')
            .split(',')
            .join('') === planName
      );
      if (!plan) {
        return null;
      } else {
        return <Product>plan.planType.productId;
      }
    } else {
      return Product.DENTAL; // DENTAL is default product
    }
  }

  // TODO: make part of service
  getAvailableEffectiveDates(): Array<string> {
    const availableEffectiveDates: Array<string> = new Array<string>();
    const availableDate = new Date();
    const currentMonth = availableDate.getMonth();

    if (availableDate.getDate() <= 20) {
      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 1);
      availableEffectiveDates.push(
        availableDate
          .toLocaleString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
          .replace(/[^ -~]/g, '')
      );

      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(
        availableDate
          .toLocaleString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
          .replace(/[^ -~]/g, '')
      );
    } else {
      availableDate.setDate(1);

      availableDate.setMonth(currentMonth + 2);
      availableEffectiveDates.push(
        availableDate
          .toLocaleString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
          .replace(/[^ -~]/g, '')
      );

      availableDate.setMonth(currentMonth + 3);
      availableEffectiveDates.push(
        availableDate
          .toLocaleString('en-US', {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
          })
          .replace(/[^ -~]/g, '')
      );
    }

    return availableEffectiveDates;
  }

  emailDetails(email: string): void {
    console.log('Trying to send email');
    if (this.validateEmail(email)) {
      console.log('Email is valid');
      this.affiliateService.affiliate$
        .pipe(map((aff) => aff))
        .subscribe((p) => {
          this.quotesUrl = p?.isPrerendered
            ? this.appSettingsService.QUOTES_URL
            : this.appSettingsService.AFF_QUOTES_URL;
          console.log('productId', this.plan.planType.productId);
          if (this.plan.planType.productId == 2)
            this.planService.emailVisionPlanDetails(
              email,
              <VisionPlan>this.plan,
              this.requestId,
              p?.phone,
              p?.affiliateId,
              this.quotesUrl
            );
          else
            this.planService.emailDentalPlanDetails(
              email,
              <DentalPlan>this.plan,
              this.requestId,
              p?.phone,
              p?.affiliateId,
              this.quotesUrl
            );
          this.emailSent = true;
          const emailedPlan: Array<Plan> = new Array<Plan>();
          emailedPlan.push(this.plan);
          this.planService.requestEmailQuote(
            email,
            emailedPlan,
            this.requestId,
            'Plan Details'
          );
        });
    }
  }

  getCarrierUrl(carrierUrl: string, planName: string): string {
    carrierUrl = carrierUrl.toLowerCase();
    if (
      carrierUrl.indexOf('delta-dental-of-ky') >= 0 ||
      carrierUrl.indexOf('delta-dental-of-tn') >= 0
    )
      return 'delta-dental';
    else if (
      carrierUrl.indexOf('anthem') >= 0 ||
      carrierUrl.indexOf('empire') >= 0
    )
      return 'anthem';
    else if (
      carrierUrl.indexOf('ncd-nationwide') >= 0 ||
      carrierUrl.indexOf('nationwide-and-vsp') >= 0
    )
      return 'nationwide';
    else if (
      carrierUrl.indexOf('vsp') >= 0 ||
      planName.indexOf('Clear View Vision') >= 0
    )
      return 'vision-plans';
    else if (carrierUrl.indexOf("ncd-by-metlife") >= 0)
      return "metlife";
    else if (carrierUrl.indexOf("ameritas-life-of-ny") >= 0)
      return "ameritas";
    else return carrierUrl;
  }

  validateEmail(email: string): boolean {
    const emailRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (email.match(emailRegex)) return true;
    else return false;
  }

  backToTop() {    
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }

  togglePlanDetailsElement(
    divPlanBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divPlanBodyExpanded.style.display === 'none';
    divPlanBodyExpanded.style.display = isShowed ? 'block' : 'none';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }

  censusPopupClick() {
    if (this.censusService.exchangeCompany) {
      this.router.navigateByUrl('/census');
    } else {
      this.showCensusPopup = true;
    }
  }

  onCensusPopupClosed(): void {
    this.showCensusPopup = false;
    if (this.censusService.updated) {
      this.census = this.censusService.census;
      this.router.navigateByUrl('/plans');
    }
  }
}
