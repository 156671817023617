import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterLink } from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { CensusService } from 'src/app/shared/services/census.service';
import { ExchangeCompany } from '../models/exchange-company.model';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'app-header-exchange',
    templateUrl: './header-exchange.component.html',
    styleUrls: ['./header-exchange.component.scss'],
    standalone: true,
    imports: [RouterLink, AsyncPipe]
})
export class HeaderExchangeComponent implements OnInit {


  public exchangeCompany!: ExchangeCompany;
  public showLinks!: boolean;

  public showMenu = false;
  public logoUrl$!: Observable<string>;
  public phone$!: Observable<string | undefined>;
  public hoursOfOperation$!: Observable<string | undefined>;
  public homeUrl$!: Observable<string>;

  constructor(private router: Router,
    private censusService: CensusService) {
  }

  ngOnInit(): void {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.showMenu = false;
      }
    });

    this.phone$ = this.censusService.exchCompany.pipe(map(exchCo => exchCo?.phone ?? exchCo?.exchangeCampaign.phone));
    this.hoursOfOperation$ = this.censusService.exchCompany.pipe(map(exchCo => exchCo?.hoursOfOperation ?? exchCo?.exchangeCampaign.hoursOfOperation));
    this.logoUrl$ = this.censusService.exchCompany.pipe(map(exchCo => { return '/assets/img/exchange/' + (exchCo?.logo ?? exchCo?.exchangeCampaign.logo) }));
    this.homeUrl$ = this.censusService.exchCompany.pipe(map(exchCo => { return  exchCo?.exchangeCampaign.name.toLowerCase() === 'amwins' ? '/amwins' : '/exchange'; }));
  }

  menuTogglerClick() {
    this.showMenu = !this.showMenu;
  }

}
