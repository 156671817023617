import { Component, OnInit, forwardRef, Input, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgForm, NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';

import { Geo } from '../../shared/models/geo.model';
import { GeoService } from '../../shared/services/geo.service';
import { NgClass, NgIf, NgFor } from '@angular/common';
import { ZipComponent } from '../zip/zip.component';


@Component({
    selector: 'app-geo',
    templateUrl: './geo.component.html',
    styleUrls: ['./geo.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => GeoComponent), multi: true }
    ],
    standalone: true,
    imports: [FormsModule, ZipComponent, NgClass, NgIf, NgFor]
})
export class GeoComponent implements ControlValueAccessor, OnInit {
  @ViewChild('GeoForm', { static: true }) public geoForm!: NgForm;


  public geo: Geo;
  @Input() disabled = false;
  @Input() public showValidation = false;
  public geos!: Geo[] | null;
  public countyIndex: number  | null = null;
  public message!: string | null;
  public wrongZip = false;

  private propagateChange = (_: Geo | null) => { };
  private propagateTouched = (_: Geo) => { };


  constructor(private geoService: GeoService) {
    this.geo = new Geo();
  }

  ngOnInit(): void {
    this.geoForm.statusChanges?.subscribe(x => { this.propagateChange(this.value); });
  }

  titleCase(str:any) {
    str = str.toLowerCase().split(' ');
    for (let i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1);
    }
    return str.join(' ');
  }

  onZipInput(zip: string | null): void {    
    if (zip && zip.length === 5) {
      this.message = 'Loading...';
      this.geoService.getGeoFromAPI(+zip).subscribe(geos => {
        this.message = null;
        this.geos = geos;

        if (geos.length === 1) {
          this.geo.county = geos[0].county;
          this.geo.stateAbbr = geos[0].stateAbbr;
          this.propagateChange(this.geo);
        } else if (geos.length === 0) {
          this.wrongZip = true;
          this.message = 'Please enter valid zip code';
        } else if (this.geo.county) {
          this.countyIndex = geos.findIndex(c => this.geo?.county?.startsWith(c.county!));
        }

      });
    } else {
      this.message = null;
      this.geos = null;
      this.countyIndex = null;
      this.geo.county = null;
      this.geo.stateAbbr = null;
    }
    this.propagateChange(this.value);
  }

  onModelChange(value: number) {
    if (this.countyIndex != null) {
      const g = this.geos![value];
      this.geo.county = g.county;
      this.geo.stateAbbr = g.stateAbbr;
    } else {
      this.geo.county = null;
      this.geo.stateAbbr = null;
    }
    this.propagateChange(this.geo);
  }

  get value(): Geo | null {
    if (this.geo.county) {
      return this.geo;
    } else {
      return null;
    }
  }

  set value(value: Geo) {

    if (this.geo !== value) {
      this.geo = value;
      if (this.geo && this.geo.zip) {
        this.onZipInput(this.geo.zip.toString());
      }
      this.propagateChange(this.geo);
    }
  }

  writeValue(value: Geo) {
    if (this.geo !== value) {
      this.geo = value;
      if (this.geo && this.geo.zip) {
        this.onZipInput(this.geo.zip.toString());
      }
    }
  }

  registerOnChange(fn: (value: Geo | null) => void) {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => void) {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

}
