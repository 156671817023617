import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Census } from 'src/app/shared/models/census.model';
import { AbstractControl, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { DateFormatValidator } from 'src/app/shared/validators/date-format.validator';
import { AgeRangeValidator } from 'src/app/shared/validators/age-range.validator';
import { CensusService } from 'src/app/shared/services/census.service';
import { GeoService } from 'src/app/shared/services/geo.service';
import { childAgeValidator } from 'src/app/shared/validators/child-age-range.validator';
import { OnlyOneErrorPipe } from "../../shared/pipes/only-one-error.pipe";
import { FamilyRelationship } from 'src/app/shared/models/family-relationship.enum';
import { FamilyMember } from 'src/app/shared/models/family-member.model';
import { Gender } from 'src/app/shared/models/gender.enum';
import { EmailFormatValidator } from 'src/app/shared/validators/email-format.validator';

export interface ChildForm {
  dob: FormControl<string | null>;
  isStudent: FormControl<boolean | null>;
  isDisabled: FormControl<boolean | null>;
}
@Component({
  selector: 'app-census-popup',
  standalone: true,
  templateUrl: './census-popup.component.html',
  styleUrls: ['./census-popup.component.scss'],
  imports: [CommonModule, ReactiveFormsModule, OnlyOneErrorPipe]
})
export class CensusPopupComponent implements OnInit {
  @Input() public census!: Census;
  @Output() public popupClosed = new EventEmitter<void>;

  private ZIP_PATTERN = /^[0-9]{5}$/;
  public MAX_CHILD = 9;
  public countiesLoading = false;
  public showValidation = false;
  public countiesList: any[] | undefined = undefined;

  counties$: Observable<any> | undefined;

  censusForm = this.formBuilder.group({
    zip: ['', [Validators.required, Validators.pattern(this.ZIP_PATTERN)]],
    countyIndex: new FormControl<number | null>(null, [Validators.required]),
    applicant_dob: ['', [Validators.required, DateFormatValidator(), AgeRangeValidator(18, 100)]],
    email: ['', [EmailFormatValidator()]],
    spouse_dob: [{ value: '', disabled: true }, [Validators.required, DateFormatValidator(), AgeRangeValidator(10, 100)]],
    children: this.formBuilder.array<FormGroup<ChildForm>>([])
  });

  constructor(private formBuilder: FormBuilder, private censusService: CensusService, private geoService: GeoService) { }

  ngOnInit(): void {
    const spouse = this.censusService.census.familyMembers.find(member => member.familyRelationship === FamilyRelationship.SPOUSE);

    if (spouse) {
      this.censusForm.controls.spouse_dob.enable();
    }

    this.censusForm.patchValue({
      zip: this.censusService.census.zip,
      applicant_dob: this.convertDateFormat(this.censusService.census.familyMembers.find(member => member.familyRelationship === FamilyRelationship.APPLICANT)?.birthdate),
      email: this.censusService.census.email,
      spouse_dob: this.convertDateFormat(spouse?.birthdate)
    });

    this.censusService.census.familyMembers.filter(member => member.familyRelationship === FamilyRelationship.CHILD)?.forEach(child => {
      this.addChildClick(child);
    });

    this.onZipInput();
  }

  convertDateFormat(inputDate: string | null | undefined): string | null | undefined {
    if (!inputDate)
      return inputDate;
    const dateComponents = inputDate.split('/');

    if (dateComponents.length === 3) {
      let [month, day, year] = dateComponents;

      // Check if month needs padding
      if (month.length === 1) {
        month = '0' + month;
      }

      // Check if day needs padding
      if (day.length === 1) {
        day = '0' + day;
      }

      // Reconstruct the date in "mm/dd/yyyy" format
      return `${month}/${day}/${year}`;
    }

    // If the input format is not recognized, return the input as is
    return inputDate;
  }


  addSpouseClick(): void {
    this.censusForm.controls.spouse_dob.enable();
  }
  removeSpouseClick(): void {
    this.censusForm.controls.spouse_dob.setValue(null);
    this.censusForm.controls.spouse_dob.disable();
  }

  addChildClick(child?: FamilyMember): void {

    // Create a new form group for the child with form controls for date of birth (dob),
    // student status (isStudent), and disability status (isDisabled).
    this.censusForm.controls.children.push(this.formBuilder.group<ChildForm>({
      dob: new FormControl(child ? this.convertDateFormat(child.birthdate)! : '', [Validators.required, DateFormatValidator()]),
      isStudent: new FormControl(child ? child.isStudent : false, [Validators.required]),
      isDisabled: new FormControl(child ? child.isDisabled : false, [Validators.required])
    }, { validators: [childAgeValidator()] })); // Apply a custom cross-field validator to the child form group to validate child age.
  }

  removeChildClick(index: number): void {
    this.censusForm.controls.children.removeAt(index);
  }

  onZipInput(): void {
    if (this.censusForm.value.zip) {

      // Remove non-digit characters from the ZIP code and keep the first 5 digits.
      const onlyDigitsZip = this.censusForm.value.zip.slice(0, 5).replace(/\D/g, '');

      // Update the ZIP code control with the cleaned value.
      this.censusForm.patchValue({ zip: onlyDigitsZip });

      if (this.censusForm.controls.zip.valid) {

        // fetch counties based on the ZIP code.
        this.counties$ = this.geoService.getGeoFromAPI(+this.censusForm.value.zip);

        // Set a flag to indicate that counties data is loading to display a message on the page.
        this.countiesLoading = true;

        this.counties$.subscribe(c => {

          // Turn off the loading flag when the data is loaded (hide loading message).
          this.countiesLoading = false;
          this.countiesList = c;

          // Depending on the length of the counties data array:
          if (c.length === 1) {
            // If there is exactly one county, set it as the selected county in the form.
            this.censusForm.controls.countyIndex.setValue(0);
          } else if (c.length > 1) {
            // If there are multiple counties, clear the selected county in the form - user must manually select it from drop down box.

            const currentCensusCountyIndex = c.findIndex((cnt: any) => cnt.county.toLowerCase() === this.censusService.census.county?.toLowerCase());
            if (currentCensusCountyIndex !== -1) {
              this.censusForm.controls.countyIndex.setValue(currentCensusCountyIndex);
            } else {
              this.censusForm.controls.countyIndex.setValue(null);
            }
          }
          else if (c.length === 0) {
            // If there are no counties, set an error on the ZIP code control to indicate that it doesn't exist.
            this.censusForm.controls.zip.setErrors({ ...this.censusForm.controls.zip.errors, 'zipNotExist': true });
          }
        });
      } else {
        this.counties$ = undefined;
      }
    }
  }

  onDateInput(input: EventTarget, control: AbstractControl): void {
    // preserve caret position within input if any changes made in the middle
    if (input instanceof HTMLInputElement) {
      const caretPosition = input.selectionStart;
      const isLastCaret = caretPosition === input.value.length;

      // remove and non-digits from the input value
      const onlyDigits = input.value.replace(/\D/g, '');

      // extract date components
      const month = onlyDigits.slice(0, 2);
      const day = onlyDigits.slice(2, 4);
      const year = onlyDigits.slice(4);

      // filter any non-truthy values, create an array and join it's values with '/'-separator
      const output = [month, day, year].filter(Boolean).join('/');

      // only use first 10 chars MM/DD/YYYY
      input.value = output.slice(0, 10);
      control.setValue(input.value);

      // restore caret position within input if any changes made in the middle
      if (!isLastCaret) {
        input.setSelectionRange(caretPosition, caretPosition);
      }
    }
  }

  updateCensus(): void {
    this.censusService.census.zip = this.censusForm.value.zip!;
    this.censusService.census.county = this.countiesList![this.censusForm.value.countyIndex!].county;
    this.censusService.census.stateAbbr = this.countiesList![this.censusForm.value.countyIndex!].stateAbbr;
    this.censusService.census.email = this.censusForm.value.email;

    let updatedFamilyMembers: FamilyMember[] = new Array<FamilyMember>();
    updatedFamilyMembers.push(new FamilyMember(FamilyRelationship.APPLICANT, Gender.UNKNOWN, this.censusForm.value.applicant_dob!, false, false, false));
    if (this.censusForm.controls.spouse_dob.enabled) {
      updatedFamilyMembers.push(new FamilyMember(FamilyRelationship.SPOUSE, Gender.UNKNOWN, this.censusForm.value.spouse_dob!, false, false, false));
    }
    if (this.censusForm.controls.children) {
      this.censusForm.controls.children.controls.forEach((x: FormGroup<ChildForm>) => {
        updatedFamilyMembers.push(new FamilyMember(FamilyRelationship.CHILD, Gender.UNKNOWN, x.value.dob!, false, x.value.isStudent!, x.value.isDisabled!));
      });
    }

    this.censusService.census.familyMembers = updatedFamilyMembers;
    this.censusService.updated = true;
    this.censusService.saveToLocalStorage();
  }

  onSubmitClick(): void {
    this.showValidation = true;
    if (!this.censusForm.valid) {
      return;
    }
    this.updateCensus();    
    this.popupClosed.emit();
  }

  onClosePopup() {
    this.censusService.updated = false;
    this.popupClosed.emit();
  }
}
