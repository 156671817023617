<div class="date">
    <input class="month" name="month" type="tel" pattern="[0-9]*" [(ngModel)]="month" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="mm"
        maxlength="2">/
    <input class="day" name="day" type="tel" pattern="[0-9]*" [(ngModel)]="day" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="dd"
        maxlength="2" (focus)="onFocusDayFunction()">/
    <input class="year" name="year" type="tel" pattern="[0-9]*" [(ngModel)]="year" [disabled]="disabled"
        [readonly]="readonly" (ngModelChange)="onModelChange()" (input)="onInput($event.target)" placeholder="yyyy"
        maxlength="4" (focus)="onFocusYearFunction()">
</div>