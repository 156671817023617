import { Injectable } from '@angular/core';

import { Census } from '../models/census.model';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { QuoteResponse } from '../models/quote-response.model';
import { HttpService } from './http.service';
import { AppSettingsService } from './app-settings.service';
import { PlanFilter } from '../models/plan-filter.model';
import { SortOption } from '../models/sort-option.model';
import { DentalPlan } from '../models/dental-plan.model';
import { Tracking } from '../models/tracking.model';
import { QuoteRequest } from '../models/quote-request.model';
import { Plan } from '../models/plan.model';
import { ComparablePlan } from '../models/comparable-plan.model';
import { VisionPlan } from '../models/vision-plan.model';
import { SubsetPlan } from '../models/subset-plan.model';
import { DentalPlanSummary } from '../models/dental-plan-summary.model';
import { VisionPlanSummary } from '../models/vision-plan-summary.model';

@Injectable({
  providedIn: 'root'
})
export class PlansService {
  public tracking!: Tracking;
  public quoteResponse!: QuoteResponse;
  public plans!: ComparablePlan[];
  public selectedFilter!: PlanFilter;
  public sortBy!: SortOption;
  public disclaimers!: string[];
  public subset!: SubsetPlan[];
  public displayAllPlans!: boolean;
  public previousPage!: string;
  public carrierPage!: string;
  public campaign!: string | null;

  constructor(private http: HttpService,
    private appSettings: AppSettingsService) {
  }

  public getQuoteFromAPI(census: Census): Observable<void> {
    const quoteRequest = new QuoteRequest();
    quoteRequest.census = census;
    quoteRequest.tracking = this.tracking;

    return this.http.post(this.appSettings.API_ENDPOINT + '/quotes', quoteRequest)
      .pipe(
        map(response => {
          this.quoteResponse = <QuoteResponse>response;
          this.plans = this.quoteResponse.plans;          
          this.disclaimers = this.getCarrierDisclaimers(this.plans);
        }));
  }

  public emailDentalPlanDetails(email: string, plan: DentalPlan, requestId: string, phone: string | undefined, affiliateId: number | undefined, url: string) {
    this.http.post(`${this.appSettings.API_ENDPOINT_ENROLLMENT}/enrollments/EmailPlanDetails`, {
      carrierLogo: plan.carrier.logoUrl,
      email: email,
      planName: plan.name,
      planType: plan.planType.name,
      premium: plan.premium,
      cleaning: plan.cleaning,
      filling: plan.filling,
      crown: plan.crown,
      rootCanal: plan.rootCanal,
      simpleExtractions: plan.simpleExtractions,
      planMaximum: plan.planMaximumDetails,
      waitingPeriods: plan.waitingPeriods,
      deductible: plan.deductible,
      effectiveDate: plan.effectiveDateDetails,
      benefitsNotes: plan.benefitsNotes,
      phone: phone,
      affiliateId: affiliateId,
      enrollmentUrl: `${url}/quoting/${plan.carrier.name.toLowerCase().split(' ').join('-')}/${plan.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('').split('+').join('-').split('/').join('-').split(',').join('')}?requestId=${requestId}&isFlag=true` //`${this.appSettings.ENROLLMENT_WEB_URL}?planId=${plan.planId}&quoteRequestId=${requestId}`

    }).subscribe();
  }

  public emailDentalPlanCompareDetails(email: string, plans: DentalPlan[], requestId: string, phone: string, affiliateId: number, url: string) {
    this.http.post(`${this.appSettings.API_ENDPOINT_ENROLLMENT}/enrollments/EmailPlanCompareDetails`, {
      email: email,
      plans: plans, 
      phone: phone,
      affiliateId: affiliateId,
      requestId: requestId,
      url: url
    }).subscribe();
  }

  public emailVisionPlanDetails(email: string, plan: VisionPlan, requestId: string, phone: string | undefined, affiliateId: number | undefined, url: string) {
    this.http.post(`${this.appSettings.API_ENDPOINT_ENROLLMENT}/enrollments/EmailVisionPlanDetails`, {
      carrierLogo: plan.carrier.logoUrl,
      email: email,
      planName: plan.name,
      planType: plan.planType.name,
      premium: plan.premium,
      eyeExam: plan.eyeExams,
      rxFrames: plan.rxFrames,
      rxLenses: plan.rxLenses,
      cLenses: plan.contactLenses,
      effectiveDate: plan.effectiveDateDetails,
      benefitsNotes: plan.benefitsNotes,
      phone: phone,
      affiliateId: affiliateId,
      enrollmentUrl: `${url}/quoting/${plan.carrier.name.toLowerCase().split(' ').join('-')}/${plan.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('').split('+').join('-').split('/').join('-').split(',').join('')}?requestId=${requestId}&isFlag=true` //`${this.appSettings.ENROLLMENT_WEB_URL}?planId=${plan.planId}&quoteRequestId=${requestId}`

    }).subscribe();
  }

  public emailVisionPlanCompareDetails(email: string, plans: VisionPlan[], requestId: string, phone: string | null | undefined, affiliateId: number | undefined, url: string) {
    this.http.post(`${this.appSettings.API_ENDPOINT_ENROLLMENT}/enrollments/EmailVisionPlanCompareDetails`, {
      email: email,
      visionplans: plans, 
      phone: phone,
      affiliateId: affiliateId,
      requestId: requestId,
      url: url
    }).subscribe();
  }

  public getDefaultState(planName: string): Observable<string> {
    return this.http.get(`${this.appSettings.API_ENDPOINT}/quotes/getdefaultstate?planName=${planName}`)
      .pipe(
        map((result: any) => {
          return result.stateAbbr;
        }));
  }

  public getDefaultPlanGeo(planName: string): Observable<any> {
    return this.http.get(`${this.appSettings.API_ENDPOINT}/quotes/getdefaultplangeo?planName=${planName}`)
      .pipe(
        map((result: any) => {
          return { stateAbbr: result.stateAbbr, zip: result.zip }
        }));
  }

  public getDentalPlanDetailsFromAPI(planNames: string[], census: Census): Observable<DentalPlan[]> {
    const alreadyLoadedPlanDetails: DentalPlan[] = new Array<DentalPlan>();
    const planNamesToLoad: string[] = new Array<string>();
    planNames.forEach(planName => {
      let plan: DentalPlan;
      if (this.plans) {
        // tslint:disable-next-line: max-line-length
        plan = <DentalPlan>this.plans.find(x => (x.carrier.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('').split(',').join('') + '/' + x.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('').split('+').join('-').split('/').join('-').split(',').join('')) === planName);
        if (plan && plan.planBrochureUrl) {
          alreadyLoadedPlanDetails.push(plan);
        } else {
          planNamesToLoad.push(planName);
        }
      } else {
        planNamesToLoad.push(planName);
      }
    });

    if (planNamesToLoad.length === 0) {
      return of(alreadyLoadedPlanDetails);
    }


    return this.http.post(this.appSettings.API_ENDPOINT + '/quotes/dental-details', { census: census, planNames: planNamesToLoad })
      .pipe(
        map(response => {
          let planDetails: DentalPlan[] = <DentalPlan[]>response;

          if (this.plans) {
            planDetails.forEach(details => {
              const planSummary = this.plans.find(x => x.planId === details.planId);
              if (planSummary) {
                details.premium = planSummary.premium;
                details.planHighlightsSummary = (planSummary as DentalPlanSummary).planHighlightsSummary
                details.order = planSummary.order;
              } else {
                details.premium = 0;
              }
              if (planNames && planNames.length > 1) {
                details.isCheckedForCompare = true;
              }
              if (planSummary) {
                this.plans[this.plans.indexOf(planSummary)] = details;
              }
            });
            planDetails = planDetails.concat(alreadyLoadedPlanDetails);
          }
          return planDetails;
        }));
  }

  public getVisionPlanDetailsFromAPI(planNames: string[], census: Census): Observable<VisionPlan[]> {
    const alreadyLoadedPlanDetails: VisionPlan[] = new Array<VisionPlan>();
    const planNamesToLoad: string[] = new Array<string>();
    planNames.forEach(planName => {
      let plan: VisionPlan;
      if (this.plans) {
        // tslint:disable-next-line: max-line-length
        plan = <VisionPlan>this.plans.find(x => x.name.toLowerCase().split(' ').join('-').split(')').join('').split('(').join('').split('+').join('-').split('/').join('-').split(',').join('') === planName);
        if (plan && plan.planBrochureUrl) {
          alreadyLoadedPlanDetails.push(plan);
        } else {
          planNamesToLoad.push(planName);
        }
      } else {
        planNamesToLoad.push(planName);
      }
      
    });

    if (planNamesToLoad.length === 0) {
      return of(alreadyLoadedPlanDetails);
    }


    return this.http.post(this.appSettings.API_ENDPOINT + '/quotes/vision-details', { census: census, planNames: planNamesToLoad })
      .pipe(
        map(response => {
          let planDetails: VisionPlan[] = <VisionPlan[]>response;

          if (this.plans) {
            planDetails.forEach(details => {
              const planSummary = this.plans.find(x => x.planId === details.planId);
              if (planSummary) {
                details.premium = planSummary.premium;
                details.planHighlightsSummary = (planSummary as VisionPlanSummary).planHighlightsSummary
                details.order = planSummary.order;
              } else {
                details.premium = 0;
              }
              if (planNames && planNames.length > 1) {
                details.isCheckedForCompare = true;
              }
              if (planSummary) {
                this.plans[this.plans.indexOf(planSummary)] = details;
              }
            });
            planDetails = planDetails.concat(alreadyLoadedPlanDetails);
          }
          return planDetails;
        }));
  }


  private getCarrierDisclaimers(plans: Plan[]): Array<string> {
    const processedCarriersIds = new Array<string>();
    const carrierDisclaimers = new Array<string>();

    plans.forEach(plan => {
      if (processedCarriersIds.indexOf(plan.carrier.carrierId) === -1) {
        processedCarriersIds.push(plan.carrier.carrierId);
        if (plan.carrier.disclaimers) {
          carrierDisclaimers.push(plan.carrier.disclaimers);
        }
      }
    });
    return carrierDisclaimers;
  }

  public requestEmailQuote(email: string, plans: Plan[], requestId: string, source: string) {
    this.http.post(`${this.appSettings.API_ENDPOINT}/quotes/requestemailquote`,  {

      emailAddress: email,
      plans: plans,
      quoteRequestId: requestId,
      agent: this.tracking != undefined ? this.tracking.utm_term : '',
      affiliateId: this.tracking != undefined ? (this.tracking.utm_campaign != null ? this.tracking.utm_campaign : 1019) : 1019,
      pageSource: source
    }).subscribe();
  }

 public getSubsetPlans(affiliateId: number, stateAbbr: string | null) : Observable<any>  {
    
    return this.http.get(`${this.appSettings.API_ENDPOINT}/quotes/getsubsetplans?affiliateId=${affiliateId}&stateAbbr=${stateAbbr}`) 
    .pipe(
      map(response => { this.subset = <SubsetPlan[]>response; 
        return this.subset;
      }));
       
  }

}
