import { Pipe, PipeTransform } from '@angular/core';
import { Plan } from '../models/plan.model';
import { SortOption } from '../models/sort-option.model';

export function compareByOrder(plan1: Plan, plan2: Plan): number {
    if (plan1.order === plan2.order)
        return 0;
    else if (plan1.order && plan2.order === null)
        return -1
    else if (plan1.order === null && plan2.order)
        return 1;
    else if (plan1.order! > plan2.order!)
        return 1;
    else
        return -1;
}

export function compareByRecommended(plan1: Plan, plan2: Plan): number {
    const orderCompareResult = compareByOrder(plan1, plan2);
    if (orderCompareResult === 0) {
        return compareByPremium(plan1, plan2);
    } else { return orderCompareResult; }
}

export function compareByCarrier(plan1: Plan, plan2: Plan): number {
    const carrierCompareResult = plan1.carrier.name.localeCompare(plan2.carrier.name);
    if (carrierCompareResult === 0) {
        return compareByPremium(plan1, plan2);
    } else { return carrierCompareResult; }
}

export function compareByPlanType(plan1: Plan, plan2: Plan): number {
    if (plan1.planType.planTypeId > plan2.planType.planTypeId) {
        return 1;
    } else if (plan1.planType.planTypeId < plan2.planType.planTypeId) {
        return -1;
    } else {
        return compareByPremium(plan1, plan2);
    }
}

export function compareByPremium(plan1: Plan, plan2: Plan): number {
    if (plan1.premium > plan2.premium) {
        return 1;
    } else if (plan1.premium < plan2.premium) {
        return -1;
    } else { return compareByPlanId(plan1, plan2); }
}
export function compareByPremiumHigh(plan1: Plan, plan2: Plan): number {
    if (plan1.premium < plan2.premium) {
        return 1;
    } else if (plan1.premium > plan2.premium) {
        return -1;
    } else { return compareByPlanId(plan1, plan2); }
}

export function compareByPlanId(plan1: Plan, plan2: Plan): number {
    return plan1.planId.localeCompare(plan2.planId);
}
@Pipe({
    name: 'plansort',
    pure: false,
    standalone: true
})

export class PlanSortPipe implements PipeTransform {
    transform(plans: Plan[], sortBy: SortOption): Plan[] {

        if (!plans) {
            return plans;
        }

        plans = plans.sort((plan1, plan2): number => {

            // featured plan - on top
            if (plan1.isCarrierFeatured || plan2.isCarrierFeatured) {
                if (plan1.isCarrierFeatured === plan2.isCarrierFeatured) {
                    return compareByCarrier(plan1, plan2);
                }
                else if (plan1.isCarrierFeatured) { return -1; }
                else if (plan2.isCarrierFeatured) { return 1; }
            }

            switch (sortBy) {
                case SortOption.RECOMMENDED:
                    return compareByRecommended(plan1, plan2);
                case SortOption.CARRIER:
                    return compareByCarrier(plan1, plan2);
                case SortOption.PLAN_TYPE:
                    return compareByPlanType(plan1, plan2);
                case SortOption.PREMIUM:
                    return compareByPremium(plan1, plan2);
                case SortOption.PREMIUMHIGH:
                    return compareByPremiumHigh(plan1, plan2);
            }
        });

        return plans;
    }





}
