import { Gender } from './gender.enum';
import { FamilyRelationship } from './family-relationship.enum';

export class FamilyMember {

    public firstName!: string;
    public middleInitial!: string;
    public lastName!: string;
    public ssn!: string;

    public constructor(
        public familyRelationship: FamilyRelationship,
        public gender: Gender | null,
        public birthdate: string | null,
        public isSmoker: boolean,
        public isStudent: boolean,
        public isDisabled: boolean
    ) {
    }
}
