<!-- <header class="top-fixed">
    <div class="container top-header-container">
        <div class="logo">
            <a href="/">
                <img [src]="logoUrl$ | async" alt="logo" width="240" height="48" />
            </a>
        </div>
        <div class="phone">
            <a [attr.href]="'tel:' + (phone$ | async)">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="phone-icon" alt="support phone">
                    <path
                        d="M493.4 24.6l-104-24c-11.3-2.6-22.9 3.3-27.5 13.9l-48 112c-4.2 9.8-1.4 21.3 6.9 28l60.6 49.6c-36 76.7-98.9 140.5-177.2 177.2l-49.6-60.6c-6.8-8.3-18.2-11.1-28-6.9l-112 48C3.9 366.5-2 378.1.6 389.4l24 104C27.1 504.2 36.7 512 48 512c256.1 0 464-207.5 464-464 0-11.2-7.7-20.9-18.6-23.4z" />
                </svg>
            </a>
            <div class="phone-info">
                <a [attr.href]="'tel:' + (phone$ | async)">{{phone$ | async}}</a>
            </div>
            <button *ngIf="!careington" class="menu-toggler" (click)="menuTogglerClick()">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="menu-toggler-icon"
                    alt="support phone">
                    <rect y="35" width="512" height="100"></rect>
                    <rect y="205" width="512" height="100"></rect>
                    <rect y="375" width="512" height="100"></rect>
                </svg>
            </button>
        </div>
    </div>
    <nav *ngIf="!careington" class="main-navigation" [ngClass]="showMenu ? 'show-flex' : ''">
        <ul class="menu">
            <li><a href="/">Home</a></li>
            <li class="menu-item-has-children"><a>Dental and Vision Rate Quotes <svg xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" class="menu-icon">
                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                    </svg></a>
                <ul class="sub-menu">

                    <li><a href="/quoting/">Get A Quote</a>
                    <li class="menu-item-has-children"><a>Dental Insurance Companies <svg
                                xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                class="menu-icon">
                                <path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z" />
                            </svg></a>
                        <ul class="sub-menu">
                            <li><a *ngIf="(isCarrierAvailable('ameritas') || isCarrierAvailable('ameritasny')) | async"
                                    href="/ameritas/">Ameritas Dental</a></li>
                            <li><a *ngIf="isCarrierAvailable('anthembcbs') | async" href="/anthem/">Anthem Dental</a>
                            </li>
                            <li><a *ngIf="isCarrierAvailable('californiadental') | async"
                                    href="/california-dental/">California Dental Network</a></li>
                            <li><a *ngIf="isCarrierAvailable('deltadental') | async" href="/delta-dental/">Delta Dental
                                    Insurance</a></li>
                            <li><a *ngIf="isCarrierAvailable('dhs') | async" href="/dental-health-services/">Dental
                                    Health Services</a></li>
                            <li><a *ngIf="isCarrierAvailable('dominion') | async" href="/dominion-national/">Dominion
                                    National</a></li>
                            <li><a *ngIf="isCarrierAvailable('guardian') | async" href="/guardian/">Guardian Dental
                                    Insurance</a></li>
                            <li><a *ngIf="isCarrierAvailable('humana') | async" href="/humana/">Humana Dental</a>
                            </li>
                            <li><a *ngIf="isCarrierAvailable('metlife') | async" href="/metlife/">MetLife Dental</a>
                            </li>
                            <li><a *ngIf="(isCarrierAvailable('ncd') || isCarrierAvailable('nationwide')) | async"
                                    href="/nationwide/">Nationwide Dental Insurance</a></li>
                            <li><a *ngIf="(isCarrierAvailable('renaissance') || isCarrierAvailable('renaissancemw')) | async"
                                    href="/renaissance/">Renaissance Dental</a></li>
                        </ul>
                    </li>
                    <li *ngIf="isCarrierAvailable('careington') | async"><a>Discount Dental
                            Plans <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                class="menu-icon">
                                <path d="M6.028 0v6.425l5.549 5.575-5.549 5.575v6.425l11.944-12z" />
                            </svg></a>
                        <ul class="sub-menu">
                            <li><a title="Careington" href="/careington/">Careington</a>
                            </li>
                        </ul>
                    </li>
                    <li><a *ngIf="(this.showVision$) | async" href="/vision-plans/">Vision Plans</a></li>
                </ul>
            </li>
            <li><a *ngIf="showPages$ | async">Plans by State <svg xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" viewBox="0 0 24 24" class="menu-icon">
                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                    </svg></a>
                <ul class="sub-menu">
                    <li><a href="/dental-insurance-california/">California dental insurance</a></li>
                    <li><a href="/dental-insurance-florida/">Florida dental insurance</a></li>
                    <li><a href="/dental-insurance-new-york/">New York dental insurance</a></li>
                    <li><a href="/dental-insurance-north-carolina/">North Carolina dental insurance</a></li>
                    <li><a href="/dental-insurance-texas/">Texas dental insurance</a></li>
                    <li><a href="/dental-plans-by-state/">See All States</a></li>
                </ul>
            </li>
            <li><a *ngIf="this.showPages$ | async">Learning Center <svg xmlns="http://www.w3.org/2000/svg" width="24"
                        height="24" viewBox="0 0 24 24" class="menu-icon">
                        <path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z" />
                    </svg></a>
                <ul class="sub-menu">
                    <li><a href="/resources/dental-plans/ppo-dental-insurance/">PPO Dental Insurance</a></li>
                    <li><a href="/resources/dental-plans/indemnity-dental-insurance/">Indemnity Dental Insurance</a>
                    </li>
                    <li><a href="/resources/dental-plans/hmo-dental-insurance/">HMO Dental Insurance</a></li>
                    <li><a href="/benefits/no-waiting-period/">Dental Insurance No Waiting Periods</a></li>
                    <li><a href="/benefits/covers-implants/">Dental Insurance that Covers Implants</a></li>
                    <li><a href="/resources/specialty-dentistry/braces-dental-coverage/">Will my Dental Insurance Cover
                            Braces?</a></li>
                    <li><a href="/benefits/immediate-coverage/">Immediate Dental Insurance</a></li>
                    <li><a href="/benefits/full-coverage-dental-insurance-plans/">Full Coverage Dental Insurance</a>
                    </li>
                    <li><a href="/resources/">Learning Center Home</a></li>
                </ul>
            </li>
        </ul>
    </nav>
</header> -->

<header class="header-holder">
    <nav>
        <div class="wrapper">
            <div class="logo">                
                <a href="/"><img [src]="logoUrl$ | async" alt="Brand" /></a>
            </div>

            <input type="radio" name="slider" id="menu-btn" />
            <input type="radio" name="slider" id="close-btn" />

            
            <ul class="nav-links">
                <div class="gray-button">
                    <label for="close-btn" class="btn close-btn back-btn">
                        <span><i class="fas fa-chevron-left"></i>Back</span>
                    </label>
                </div>
                <li class="variant-mega" id="find-plan">
                    <a class="desktop-item">Find My Plan</a>
                    <label for="showMega" class="mobile-item" id="plans-mobile">Find My Plan
                        <i class="fas fa-chevron-right" id="plans-closed"></i>
                        <i class="fas fa-chevron-down" id="plans-opened"></i>
                    </label>
                    <div class="mega-box" id="find-my-plan">
                        <div class="content desktop">
                            <div class="row">
                                <h2>Find My Plan</h2>
                            </div>

                            <div class="row">
                                <div class="one_half">
                                    <header>Get a Quote</header>
                                    <ul class="mega-links">
                                        <li>
                                            <a href="/static-census/" title="Link to start a quote for a dental plan.">Dental
                                                Plans</a>
                                        </li>
                                        <li *ngIf="(this.showVision$) | async">
                                            <a href="/static-census/" title="Link to start a quote for a dental plan.">Vision
                                                Plans</a>
                                        </li>
                                        <li *ngIf="(this.showBundled$) | async">
                                            <a href="/static-census/" title="Link to start a quote for a dental plan.">Dental,
                                                Vision &amp; Hearing (Bundled) Plans</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="one_half last">
                                    <header>Insurance Companies</header>
                                    <ul class="mega-links-img">
                                        <li *ngIf="(isCarrierAvailable('ameritas') || isCarrierAvailable('ameritasny')) | async">
                                            <a href="/ameritas/" title="Link to the company page for Ameritas."><img
                                                    src="../../assets/img/carrier-logos/ameritas-small.png"
                                                    alt="Company logo for Ameritas" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('anthembcbs') | async">
                                            <a href="/anthem/"
                                                title="Link to the company page for Anthem Bluecross Blueshield."><img
                                                    src="../../assets/img/carrier-logos/anthembcbs-small.png"
                                                    alt="Company logo for Anthem Bluecross Blueshield" /></a>
                                        </li>                                        
                                        <li *ngIf="(isCarrierAvailable('cigna') | async)">
                                            <a href="/cigna/"
                                                title="Link to the company page for Cigna."><img
                                                    src="../../assets/img/carrier-logos/cigna-small.png"
                                                    alt="Company logo for Cigna" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('deltadental') | async">
                                            <a href="/delta-dental/"
                                                title="Link to the company page for Delta Dental."><img
                                                    src="../../assets/img/carrier-logos/deltadental-small.png"
                                                    alt="Company logo for Delta Dental" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('guardian') | async">
                                            <a href="/guardian/" title="Link to the company page for Guardian."><img
                                                    src="../../assets/img/carrier-logos/guardian-small.png"
                                                    alt="Company logo for Guardian" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('humana') | async">
                                            <a href="/humana/" title="Link to the company page for Humana."><img
                                                    src="../../assets/img/carrier-logos/humana-small.png"
                                                    alt="Company logo for Humana" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('metlife') | async">
                                            <a href="/metlife/" title="Link to the company page for MetLife."><img
                                                    src="../../assets/img/carrier-logos/metlife-small.png"
                                                    alt="Company logo for MetLife" /></a>
                                        </li>
                                        <li *ngIf="(isCarrierAvailable('renaissance') | async) || (isCarrierAvailable('renaissancemw') | async)">
                                            <a href="/renaissance/"
                                                title="Link to the company page for Renaissance."><img
                                                    src="../../assets/img/carrier-logos/renaissance-small.png"
                                                    alt="Company logo for Renaissance" /></a>
                                        </li>
                                        <li *ngIf="isCarrierAvailable('vsp') | async">
                                            <a href="/vsp/"
                                                title="Link to the company page for VSP."><img
                                                    src="../../assets/img/carrier-logos/vsp-small.png"
                                                    alt="Company logo for VSP" /></a>
                                        </li>
                                    </ul>
                                    <a class="extra-link" href="/coverage/dental-insurance-companies/" *ngIf="(showPages$ | async)"
                                        title="Link to page displaying all available Carriers and Plans on the site.">All
                                        Companies</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li [style.display]="(showPages$ | async) ? 'block': 'none' "  class="variant-mega-2">
                    <a class="desktop-item">Learn</a>
                    <label for="showDrop" class="mobile-item" id="learn-mobile">Learn
                        <i class="fas fa-chevron-right" id="learn-closed"></i>
                        <i class="fas fa-chevron-down" id="learn-opened"></i>
                    </label>

                    <div class="mega-box" id="learn">
                        <div class="content desktop">
                            <div class="row">
                                <h2>Learn</h2>
                            </div>

                            <div class="row">
                                <div class="col-1">
                                    <header>Top Articles</header>

                                    <ul class="mega-links">
                                        <li>
                                            <a href="/resources/dental-plans/ppo-dental-insurance/"
                                                title="Link to article on PPO plans.">What is a PPO Plan</a>
                                        </li>
                                        <li>
                                            <a href="/coverage/dppo-dhmo/"
                                                title="Link to information on the difference between PPO and HMO plans.">PPO
                                                vs HMO Plans</a>
                                        </li>
                                        <li>
                                            <a href="/resources/specialty-dentistry/out-of-network-dentist/"
                                                title="Link to article on cost differences between in-network and out-of-network plans..">Out
                                                of Network Dentist Costs</a>
                                        </li>
                                        <li>
                                            <a href="/benefits/waiting-period/"
                                                title="Link to information on how waiting periods work.">Insurance and
                                                Waiting Periods</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-2">
                                    <header>Topics</header>
                                    <ul class="mega-links">
                                        <li>
                                            <a href="/benefits/dental-insurance-101/"
                                                title="Link to basic information on how dental insurance works.">Dental
                                                Insurance 101</a>
                                        </li>
                                        <li>
                                            <a href="/resources/oral-health/choosing-a-dentist/"
                                                title="Link to a guide on how to choose a dentist.">Choosing a
                                                Dentist</a>
                                        </li>
                                        <li>
                                            <a href="/resources/dental-care-and-your-budget/"
                                                title="Link to an article about managing finances when seeking dental care.">Dental
                                                Care and Your Budget</a>
                                        </li>
                                        <li>
                                            <a href="/frequently-asked-questions/"
                                                title="Link to Frequently Asked Question on oral health care.">FAQs</a>
                                        </li>
                                        <li>
                                            <a href="/resources/"
                                                title="Link to our full list of Learning Center Resources.">More…</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-3">
                                    <header>State Specific Information</header>
                                    <ul class="mega-links">
                                        <li>
                                            <a href="/dental-insurance-california/"
                                                title="Link to information on dental options in California.">California</a>
                                        </li>
                                        <li>
                                            <a href="/dental-insurance-florida/"
                                                title="Link to information on dental options in Florida.">Florida</a>
                                        </li>
                                        <li>
                                            <a href="/dental-insurance-texas/"
                                                title="Link to information on dental options in Texas.">Texas</a>
                                        </li>
                                        <li>
                                            <a href="/dental-insurance-new-york/"
                                                title="Link to information on dental options in New York.">New York</a>
                                        </li>
                                        <li>
                                            <a href="/dental-insurance-north-carolina/"
                                                title="Link to information on dental options in North Carolina.">North
                                                Carolina</a>
                                        </li>
                                        <li>
                                            <a href="/dental-plans-by-state/"
                                                title="Link to information on dental options in All States.">All
                                                States</a>
                                        </li>
                                    </ul>
                                </div>

                                <div class="col-4">
                                    <header>Who we are</header>
                                    <ul class="mega-links">
                                        <li>
                                            <a href="/about/" title="Information on our Company.">About Us</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>

                <li class="phone">
                    <a [attr.href]="'tel:' + (phone$ | async)">{{phone$ | async}}</a>
                </li>
            </ul>

            <div class="btn menu-btn phone-mobile"><a [attr.href]="'tel:' + (phone$ | async)" id="phone_text_mobile"><i class="fas fa-phone"></i></a></div>
            <label for="menu-btn" class="btn menu-btn hamburger" id="hamburger"><i class="fas fa-bars"></i></label>
        </div>
    </nav>
</header>