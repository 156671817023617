import { Component, OnInit, Input } from '@angular/core';
import { Census } from '../../shared/models/census.model';
import { Gender } from '../../shared/models/gender.enum';
import { FamilyRelationship } from '../../shared/models/family-relationship.enum';
import { FamilyMember } from '../../shared/models/family-member.model';
import { NgFor, NgIf, NgSwitch, NgSwitchCase } from '@angular/common';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'app-census-widget',
    templateUrl: './census-widget.component.html',
    styleUrls: ['./census-widget.component.scss'],
    standalone: true,
    imports: [RouterLink, NgFor, NgIf, NgSwitch, NgSwitchCase, RouterLink]
})
export class CensusWidgetComponent implements OnInit {

  @Input() public census!: Census;

  public genders = Gender;
  public familyRelationships = FamilyRelationship;

  constructor() { }

  ngOnInit() {
  }

  getDependentCount(): number {
    return this.census.familyMembers.filter(familyMember => familyMember.familyRelationship !== FamilyRelationship.APPLICANT).length;
  }

  getApplicantAge(): number {
    return this.getAge(this.census.familyMembers.filter(familyMember => familyMember.familyRelationship === FamilyRelationship.APPLICANT)[0].birthdate!);
  }

  getAge(dateString: string) {
    var today = new Date();
    var birthDate = new Date(dateString);
    var age = today.getFullYear() - birthDate.getFullYear();
    var m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}

}
