<section class="result-card" [ngClass]="{selected: plan.isCheckedForCompare}">
    <div class="result-card-header">
        <div class="filter-item">
            <label>
                <input name="compare" type="checkbox" [(ngModel)]="plan.isCheckedForCompare"
                    [disabled]="!compareEnabled && !plan.isCheckedForCompare">
                Compare</label>
        </div>
        <div class="recommended-badge-container" *ngIf="plan.planLabel">
            <span>{{plan.planLabel}}</span>
            <img src="assets/img/svg/stars.svg" alt="stars">
        </div>
    </div>
    <div class="result-card-body">
        <div class="first-grid">
            <img src="{{plan.carrier.logoUrl}}" alt="carrierlogo" />
            <div class="price-container price-container-mobile">
                <span class="price">{{plan.premium | currency:'USD':'symbol'}}</span>
                <span class="month">/mo.</span>
            </div>
            <a *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist Search</a>
        </div>
        <div class="second-grid">
            <div class="plan-name-container">
                <span class="plan-name">{{plan.name}}</span>
            </div>
            <div class="plan-specs">
                <div class="plan-type-container">
                    <span class="plan-type">Plan Type:</span>
                    <span class="ppo">{{plan.planType.name}}</span>
                </div>
                <div class="plan-max">
                    <span class="max">Plan Max: </span>
                    <span class="price">{{plan.planMaximum}}</span>
                </div>
            </div>
            <div class="warning">
                <span [innerHTML]="plan.effectiveDateDetails"></span>
            </div>
            <a *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist Search</a>
        </div>
        <div class="third-grid">
            <div class="price-container price-container-desktop">
                <span class="price">{{plan.premium | currency:'USD':'symbol'}}</span>
                <span class="month">/mo.</span>
                <!-- <span>ORDER: {{plan.order}}</span> -->
            </div>
            <a class="enroll" href="{{enrollmentWebUrl}}?planId={{plan.planId}}&quoteRequestId={{requestId}}">
                <span>Enroll Now</span>
            </a>
        </div>
    </div>

    <div class="plan-body-expanded" #planBody>
        
        <div class="result-card-footer" (click)="togglePlanBody(planBody, spanElement, imgElement)">
            <span class="mt-10">Close Details</span>
            <img src="../../../assets/img/svg/arrow-up.svg" alt="up">
        </div>

        <!-- <section>
            <p *ngIf="plan.carrier.name.toLowerCase().includes('ncd nationwide')">Underwritten by Nationwide</p>
            <p *ngIf="plan.name === 'Advantage Diamond'">This plan includes a teeth whitening benefit. Click Plan
                Details for more information. </p>
            <p *ngIf="plan.carrier.name.toLowerCase() === 'renaissance' && plan.name.toLowerCase().includes('waiting')">
                The rates displayed are subject to change based upon regulatory approval. </p>
            <p
                *ngIf="plan.carrier.name.toLowerCase() === 'guardian' && plan.name.includes('Advantage') && plan.waitingPeriods.includes('Basic-None')">
                <strong>Access benefits like fillings, simple extractions, cleanings & more on day 1 of
                    yourcoverage.</strong>
            </p>
        </section> -->

        <section class="common-procedures">
            <div class="plan-subtitles-w-caret-container">
                <span class="subtitle-text">Common Procedures</span>
            </div>
            <div class="procedures-table">
                <div class="procedures-item">
                    <span class="procedure">Cleaning:</span>
                    <span class="description" [innerHTML]="plan.cleaning"></span>
                </div>
                <div class="procedures-item">
                    <span class="procedure">White Filling:</span>
                    <span class="description" [innerHTML]="plan.filling"></span>
                </div>
                <div class="procedures-item">
                    <span class="procedure">Crown:</span>
                    <span class="description" [innerHTML]="plan.crown"></span>
                </div>                
                <div class="procedures-item">
                    <span class="procedure">Root Canals:</span>
                    <span class="description" [innerHTML]="plan.rootCanal"></span>
                </div>     
                <div class="procedures-item">
                    <span class="procedure">Simple Extractions:</span>
                    <span class="description" [innerHTML]="plan.simpleExtractions"></span>
                </div>                                              
                <div class="procedures-item">
                    <span class="procedure">Plan Maximum:</span>
                    <span class="description" [innerHTML]="plan.planMaximumDetails"></span>
                </div>
                <div class="procedures-item">
                    <span class="procedure">Waiting Periods:</span>
                    <span class="description" [innerHTML]="plan.waitingPeriods"></span>
                </div>                
                <div class="procedures-item">
                    <span class="procedure">Deductible:</span>
                    <span class="description" [innerHTML]="plan.deductible"></span>
                </div>
            </div>
            <div class="observations-section">
                <span [innerHTML]="plan.benefitsNotes"></span>
            </div>
        </section>

        <!-- <section class="plan-subtitles plan-highlights">
            <div class="subtitle-name">
                <span>Plan Highlights</span>
            </div>
            <div class="highlights-list" [innerHTML]="plan.planHighlightsSummary"></div>
        </section> -->

        <div class="single-button-container">
            <a class="button-holder" [routerLink]="getPlanUrl(plan)"><span>See Full Plan Information</span></a>
        </div>
    </div>

    <div class="result-card-footer" (click)="togglePlanBody(planBody, spanElement, imgElement)">
        <span class="mt-10" #spanElement>More Details</span>
        <img src="../../../assets/img/svg/arrow-down.svg" alt="down" #imgElement>
    </div>

</section>