import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class AppSettingsService {

  constructor(@Inject(DOCUMENT) private document:any) { }
  public get API_ENDPOINT(): string { return environment.apiEndpoint; }
  public get API_ENDPOINT_ENROLLMENT(): string { return environment.apiEndpointEnrollment; }
  public get ENROLLMENT_WEB_URL(): string {
    if (this.document.location.hostname.indexOf('yourdentalexchange') !== -1) {
      return environment.enrollmentYDEWebUrl;
    } else if (this.document.location.hostname.indexOf('retireedentalexchange') !== -1) {
      return environment.enrollmentRDEWebUrl;
    }
    else if (this.document.location.hostname.indexOf('dentalsavants') !== -1) {
      return environment.enrollmentDentalSavantsWebUrl;
    }
    else if (this.document.location.hostname.indexOf('quotesavantdental') !== -1) {
      return environment.enrollmentQuoteSavantDentalWebUrl;
    }
    else if (this.document.location.hostname.indexOf('visionsavants') !== -1) {
      return environment.enrollmentVisionSavantsWebUrl;
    }
    else if (this.document.location.hostname.indexOf('quotesavantvision') !== -1) {
      return environment.enrollmentQuoteSavantVisionWebUrl;
    }
    else {
      return environment.enrollmentWebUrl;
    }
  }
  public get GEO_API_ENDPOINT(): string { return environment.geoApiEndpoint; }
  public get QUOTES_URL(): string { return environment.quotesWebUrl; }
  public get AFF_QUOTES_URL(): string { return environment.affQuotesWebUrl; }
}
