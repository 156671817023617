import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Tracking } from 'src/app/shared/models/tracking.model';
import { CensusService } from 'src/app/shared/services/census.service';
import { PlansService } from 'src/app/shared/services/plans.service';
import { ExchangeService } from '../exchange.service';

@Component({
    selector: 'app-amwins',
    templateUrl: './amwins.component.html',
    styleUrls: ['./amwins.component.scss'],
    standalone: true
})
export class AmwinsComponent implements OnInit {

  constructor(private router: Router,
    private censusService: CensusService,
    private plansService: PlansService,
    private exchangeService: ExchangeService) {
    this.censusService.exchange = true;
    this.censusService.exchObs.next(true);

    this.exchangeService.getCompanyByNameFromAPI('amwins').subscribe(x => {

      const found = x.find(xx => xx.name === 'amwins');

      if (!this.plansService.tracking) {
        this.plansService.tracking = new Tracking();
      }
      this.plansService.tracking.exchangeCompanyId = found?.exchangeCompany.exchangeCompanyId!;
      this.plansService.tracking.exchangeCompanyName = found?.exchangeCompany.name!;

      this.censusService.exchange = true;
      this.censusService.exchangeDental = true;
      this.censusService.exchangeVision = false;
      this.censusService.exchangeCompany = found?.exchangeCompany!;
      this.censusService.exchCompany.next(this.censusService.exchangeCompany);
      this.router.navigateByUrl('/census');
    });
  }

  ngOnInit(): void {
  }

}
