<form #GeoForm="ngForm" class="geo">

  <div class="geo-zip">
    <label for="ZipCode">Your zip code?</label>
    <app-zip name="ZipCode" [(ngModel)]="geo.zip" required [disabled]="disabled"
      (input)="onZipInput(zip.value)" #zip="ngModel"
      [ngClass]="{'showValidation': showValidation == true}"></app-zip>
    <div *ngIf="(wrongZip || !zip.valid) && showValidation" class="invalid-feedback">
      Enter valid zip code
    </div>
  </div>

  <div class="geo-county" *ngIf="geos && geos.length > 0">
    <label for="County">County:</label>
    <span *ngIf="geos.length === 1">
      <input type="text" name="County" value=" {{titleCase(geo.county)}}, {{geo.stateAbbr}}" disabled>
    </span>
    <div *ngIf="geos.length > 1">
      <select name="CountySelection" [(ngModel)]="countyIndex" (ngModelChange)="onModelChange($event)" id="county"
        [ngClass]="{'showValidation': showValidation == true}" [disabled]="disabled" #county="ngModel" required>
        <option [ngValue]="null" selected disabled>Please select</option>
        <option *ngFor="let geo of geos; let i = index;" [ngValue]="i">
          {{titleCase(geo.county) + ', ' + geo.stateAbbr}}
        </option>
      </select>
      <div *ngIf="!county.valid && showValidation" class="invalid-feedback">
        Select valid county
      </div>
    </div>

  </div>

</form>