import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

/** Service used to wrap the angular Http service.
 * Automatically appends headers to requests.
 */
 @Injectable({
    providedIn: 'root'
  })
export class HttpService {

    private headers: HttpHeaders = new HttpHeaders({ 'Content-Type': 'application/json' });

    public constructor(
        private http: HttpClient
    ) { }

    /** Execute post request, appending headers required by
     * the endpoint.
     */
    public post(url: string, body: any) {
        return this.http.post(url, body, { headers: this.headers });
    }

    /** Execute get request, appending headers required by
     * the endpoint.
     */
    public get(url: string) {
        return this.http.get(url, { headers: this.headers });
    }
}
