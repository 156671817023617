export const environment = {
  production: false,
  prepopulateCensus: false,
  apiEndpoint: 'https://quotesapidevelop.azurewebsites.net/api',
  apiEndpointEnrollment: 'https://enrollmentapidevelop.azurewebsites.net/api',
  enrollmentWebUrl: 'https://enrollmentwebdevelop.azurewebsites.net/start',
  enrollmentRDEWebUrl: 'https://dev-enrollment.retireedentalexchange.com/start',
  enrollmentYDEWebUrl: 'https://dev-enrollment.yourdentalexchange.com/start',
  enrollmentDentalSavantsWebUrl: 'https://enroll-dev.dentalsavants.com/start',
  enrollmentQuoteSavantDentalWebUrl: 'https://enroll-dev.quotesavantdental.com/start',
  enrollmentVisionSavantsWebUrl: 'https://enroll-dev.visionsavants.com/start',
  enrollmentQuoteSavantVisionWebUrl: 'https://enroll-dev.quotesavantvision.com/start',
  geoApiEndpoint: 'https://kelsey-geo.azurewebsites.net/api/v1',  
  quotesWebUrl: 'https://dev.dentalinsurance.com',
  affQuotesWebUrl: 'https://aff-dev.dentalinsurance.com',
  featured6PlansAffIds: [940, 950, 951, 952, 953, 954, 1019],
  showEmailAffiliates: [ 940, 941,950, 951, 952, 953, 954, 986, 987, 988, 989, 990, 991, 992, 993, 994, 995, 996, 997, 998, 999, 1019, 1965, 2558],
  hideEmailAffiliates: [ 2461, 2462, 2550, 2551, 2552, 2553, 2572, 2573, 2574, 2575, 2576, 2584, 2585 ],
  hideFeaturedPlansAffIds: [960, 961, 962, 963, 964, 965, 2525, 2542, 2544, 2558, 2557, 2570, 2571],
  sortByPremiumAffIds: [940, 950, 951, 952, 953, 954, 960, 962, 965, 1019],
  showSubsetAffIds: [940, 950, 951, 952, 953, 954, 1019]
};
