<section class="result-card"><!--TODO: need to add class 'selected' if user choose compare-->
  <div class="result-card-header">
    <div class="filter-item">
      <label><input name="compare" type="checkbox" [(ngModel)]="plan.isCheckedForCompare"
          [disabled]="!compareEnabled && !plan.isCheckedForCompare">
        Compare
      </label>
    </div>
    <div class="recommended-badge-container" *ngIf="plan.planLabel">
      <span>{{plan.planLabel}}</span>
      <img src="assets/img/svg/stars.svg" alt="stars">
    </div>
  </div>
  <div class="result-card-body">
    <div class="first-grid">
      <img src="{{plan.carrier.logoUrl}}" alt="carrierlogo">
      <div class="price-container price-container-mobile">
        <span class="price">{{plan.premium | currency:'USD':'symbol'}}</span>
        <span class="month">/mo.</span>
      </div>
      <a *ngIf="plan.doctorSearchUrl" href="{{plan.doctorSearchUrl}}" target="_blank">Provider Search</a>
    </div>

    <div class="second-grid">
      <div class="plan-name-container">
        <span class="plan-name">{{plan.name}}</span>
      </div>
      <div class="plan-specs">
        <div class="plan-type-container">
          <span class="plan-type">Plan Type:</span>
          <span class="ppo">{{plan.planType.name}}</span>
        </div>
        <div class="plan-max">
          <span class="max">Plan Max:</span>
          <span class="price">{{plan.planMaximum}}</span>
        </div>
      </div>
      <div class="warning">
        <span [innerHTML]="plan.effectiveDateDetails"></span>
      </div>
      <a *ngIf="plan.doctorSearchUrl" href="{{plan.doctorSearchUrl}}" target="_blank">Provider Search</a>
    </div>

    <div class="third-grid">
      <div class="price-container price-container price-container-desktop">
        <span class="price">{{plan.premium | currency:'USD':'symbol'}}</span>
        <span class="month">/mo.</span>
      </div>
      <a class="enroll" href="{{enrollmentWebUrl}}?planId={{plan.planId}}&quoteRequestId={{requestId}}">
        <span>Enroll Now</span>
      </a>
    </div>
  </div>

  <div class="plan-body-expanded" #planBody>

    <div class="result-card-footer" (click)="togglePlanBody(planBody, spanElement, imgElement)">
      <span class="mt-10">Close Details</span>
      <img src="../../../assets/img/svg/arrow-up.svg" alt="up">
    </div>

    <section class="common-procedures">
      <div class="plan-subtitles-w-caret-container">
        <span class="subtitle-text">Common Procedures</span>
      </div>
      <div class="procedures-table">
        <div class="procedures-item">
          <span class="procedure">Eye Exams:</span>
          <span class="description">{{plan.eyeExams}}</span>
        </div>
        <div class="procedures-item">
          <span class="procedure">Rx Frames:</span>
          <span class="description">{{plan.rxFrames}}</span>
        </div>
        <div class="procedures-item">
          <span class="procedure">Rx Lenses:</span>
          <span class="description">{{plan.rxLenses}}</span>
        </div>
        <div class="procedures-item">
          <span class="procedure">Contact Lenses:</span>
          <span class="description">{{plan.contactLenses}}</span>
        </div>

      </div>
      <div class="observations-section">
        <span [innerHTML]="plan.benefitsNotes"></span>
      </div>
    </section>

    <!-- <section class="plan-subtitles plan-highlights">
      <div class="subtitle-name">
        <span>Plan Highlights</span>
      </div>
      <div class="highlights-list" [innerHTML]="plan.planHighlightsSummary"></div>
    </section> -->

    <div class="single-button-container">
      <a class="button-holder" [routerLink]="getPlanUrl(plan)"><span>See Full Plan Information</span></a>
    </div>
  </div>

  <div class="result-card-footer" (click)="togglePlanBody(planBody, spanElement, imgElement)">
    <span class="mt-10" #spanElement>More Details</span>
    <img src="../../../assets/img/svg/arrow-down.svg" alt="up" #imgElement>
  </div>
</section>