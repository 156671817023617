import { Routes } from '@angular/router';
import { CensusComponent } from './census/census.component';
import { PlanListComponent } from './plan-info/plan-list/plan-list.component';
import { PlanCompareComponent } from './plan-info/plan-compare/plan-compare.component';
import { CustomerValidationComponent } from './exch/customer-validation/customer-validation.component';
import { AmwinsComponent } from './exch/amwins/amwins.component';
import { PlanNotAvailableComponent } from './plan-info/plan-not-available/plan-not-available.component';
import { PlanDetailsComponent } from './plan-info/plan-details/plan-details.component';
import { NoPlansAvailableComponent } from './plan-info/no-plans-available/no-plans-available.component';

export const routes: Routes = [{
    path: '',
    component: CensusComponent,
  },
  {
    path: 'census',
    component: CensusComponent,
  },
  {
    path: 'plans',
    component: PlanListComponent,
  },
  {
    path: 'plans/compare',
    component: PlanCompareComponent,
  },
  {
    path: 'exchange',
    component: CustomerValidationComponent,
  },
  {
    path: 'exchange/amwins',
    component: AmwinsComponent,
  },
  {
    path: 'plan-not-available',
    component: PlanNotAvailableComponent,
  },
  {
    path: ':carrierName/:planName',
    component: PlanDetailsComponent,
  },
  {
    path: 'no-plans-available',
    component: NoPlansAvailableComponent,
  },
  {
    path: '**',
    redirectTo: '',
  }];
