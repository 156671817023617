import { Pipe, PipeTransform } from '@angular/core';
import { PlanFilter } from '../models/plan-filter.model';
import { FilterOption } from '../models/filter-option.model';
import { Plan } from '../models/plan.model';
import { ComparablePlan } from '../models/comparable-plan.model';


export function filterByProductId(plans: ComparablePlan[], productsFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by productIds
    if (productsFilter && productsFilter.length > 0 && productsFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => productsFilter.findIndex(x => x.value === item.planType.productId && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByCarriers(plans: ComparablePlan[], carriersFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by carriers
    if (carriersFilter && carriersFilter.length > 0 && carriersFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => carriersFilter.findIndex(x => (item.carrier.name.includes(x.value) || x.value === item.carrier.name) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByPlanTypes(plans: ComparablePlan[], planTypesFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by types
    if (planTypesFilter && planTypesFilter.length > 0 && planTypesFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => planTypesFilter.findIndex(x => (item.planType.name.endsWith(x.value) || x.value === item.planType.name ) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByPremium(plans: ComparablePlan[], premiumFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by premium
    if (premiumFilter && premiumFilter.length > 0 && premiumFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => premiumFilter.findIndex(x =>
            (item.premium >= x.value.min) &&
            (x.value.max === -1 || item.premium <= x.value.max) &&
            x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByPlanMax(plans: ComparablePlan[], planMaxFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by planmax
    if (planMaxFilter && planMaxFilter.length > 0 && planMaxFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => planMaxFilter.findIndex(x =>
            (item.planMax >= x.value.min) &&
            (x.value.max === -1 || item.planMax <= x.value.max) &&
            x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByImplantBenefit(plans: ComparablePlan[], implantBenefitFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by planmax
    if (implantBenefitFilter && implantBenefitFilter.length > 0 && implantBenefitFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => implantBenefitFilter.findIndex(x => (item.hasImplants) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByChildOrthoBenefit(plans: ComparablePlan[], childOrthoBenefitFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by planmax
    if (childOrthoBenefitFilter && childOrthoBenefitFilter.length > 0 && childOrthoBenefitFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => childOrthoBenefitFilter.findIndex(x => (item.hasChildOrtho) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByAdultOrthoBenefit(plans: ComparablePlan[], adultdOrthoBenefitFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by planmax
    if (adultdOrthoBenefitFilter && adultdOrthoBenefitFilter.length > 0 && adultdOrthoBenefitFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => adultdOrthoBenefitFilter.findIndex(x => (item.hasAdultOrtho) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterByTeethWhiteningBenefit(plans: ComparablePlan[], teethWhiteningBenefitFilter: FilterOption[]): ComparablePlan[] {
    // filter plans by planmax
    if (teethWhiteningBenefitFilter && teethWhiteningBenefitFilter.length > 0 && teethWhiteningBenefitFilter.findIndex(x => x.isChecked) !== -1) {
        return plans.filter(item => teethWhiteningBenefitFilter.findIndex(x => (item.hasTeethWhitening) && x.isChecked) !== -1);
    } else {
        return plans;
    }
}

export function filterPlans(plans: ComparablePlan[], filter: PlanFilter): ComparablePlan[] {

    if (!plans || !filter) {
        return plans;
    }

    plans = filterByProductId(plans, filter.productFilter);

    plans = filterByCarriers(plans, filter.carriersFilter);

    plans = filterByPlanTypes(plans, filter.planTypesFilter);

    plans = filterByPremium(plans, filter.premiumFilter);

    plans = filterByPlanMax(plans, filter.planMaxFilter);
    plans = filterByImplantBenefit(plans, filter.implantBenefitFilter);
    plans = filterByChildOrthoBenefit(plans, filter.childOrthoBenefitFilter);
    plans = filterByAdultOrthoBenefit(plans, filter.adultOrthoBenefitFilter);
    plans = filterByTeethWhiteningBenefit(plans, filter.teethWhiteningBenefitFilter);


    return plans;
}
@Pipe({
    name: 'planfilter',
    pure: false,
    standalone: true
})


export class PlanFilterPipe implements PipeTransform {
    transform(plans: ComparablePlan[], filter: PlanFilter): ComparablePlan[] {
        return filterPlans(plans, filter);
    }


}
