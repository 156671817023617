import { FamilyMember } from './family-member.model';

export class Census {
    public constructor(
        public zip: string | null,
        public county: string | null,
        public stateAbbr: string | null,
        public requestedEffectiveDate: string | null,
        public email: string | null | undefined,
        public familyMembers: FamilyMember[]) {
    }
}
