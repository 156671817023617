import { Component, forwardRef, Input } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, FormsModule } from '@angular/forms';

@Component({
    selector: 'app-zip',
    templateUrl: './zip.component.html',
    styleUrls: ['./zip.component.scss'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => ZipComponent), multi: true },
        { provide: NG_VALIDATORS, useExisting: forwardRef(() => ZipComponent), multi: true }
    ],
    standalone: true,
    imports: [FormsModule]
})

export class ZipComponent implements ControlValueAccessor {
  @Input() disabled = false;
  private _value!: string;

  private onChanged: any = () => { };
  private onTouched: any = () => { };
  private onValidationChange: any = () => { };

  onInput(target: any) {
    target.value = this.stripNonDigits(target.value);
    this._value = target.value;
  }

  get value() {
    return this._value;
  }

  set value(value: any) {
    this._value = value;
    this.onChanged(this._value);
    this.onValidationChange();
  }

  writeValue(obj: any): void {
    this._value = obj;
  }

  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    const isIncorrectZip = this._value && this._value.length !== 5;

    return isIncorrectZip ? { invalidZip: isIncorrectZip } : null;
  }

  registerOnValidatorChange?(fn: () => void): void {
    this.onValidationChange = fn;
  }

  stripNonDigits(value: string): string {
    let newValue = '';
    for (let i = 0; i <= value.length; i++) {
      if (value[i] >= '0' && value[i] <= '9') {
        newValue += value[i];
      }
    }
    return newValue;
  }
}
