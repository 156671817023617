import { Component, OnInit, Input } from '@angular/core';
import { DentalPlan } from 'src/app/shared/models/dental-plan.model';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';
import { PlansService } from 'src/app/shared/services/plans.service';
import { NgIf, NgFor, NgClass, CurrencyPipe } from '@angular/common';

@Component({
    selector: 'app-dental-plan-compare-details',
    templateUrl: './dental-plan-compare-details.component.html',
    styleUrls: ['./dental-plan-compare-details.component.scss'],
    standalone: true,
    imports: [NgIf, NgFor, NgClass, CurrencyPipe]
})
export class DentalPlanCompareDetailsComponent implements OnInit {
  @Input() plans!: any[];

  public enrollmentWebUrl: string;
  public requestId: string;

  constructor(private appSettingsService: AppSettingsService, private plansService: PlansService) {
    this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
    this.requestId = this.plansService.quoteResponse.requestId;
  }

  ngOnInit() {
  }

  togglePlanDetailsElement(
    divPlanBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divPlanBodyExpanded.style.display === 'none';
    divPlanBodyExpanded.style.display = isShowed ? 'block' : 'none';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }

}
