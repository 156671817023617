import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';

import { PlanFilter } from '../../shared/models/plan-filter.model';
import { SortOption } from '../../shared/models/sort-option.model';
import { PlansService } from '../../shared/services/plans.service';
import { CensusService } from '../../shared/services/census.service';
import { Census } from '../../shared/models/census.model';
import { Plan } from '../../shared/models/plan.model';
import { ComparablePlan } from 'src/app/shared/models/comparable-plan.model';
import { FilterOption } from 'src/app/shared/models/filter-option.model';
import { Product } from 'src/app/shared/models/product.enum';
import { AffiliateService } from 'src/app/shared/services/affiliate.service';
import { Observable } from 'rxjs';
import { Affiliate } from 'src/app/shared/models/affiliate.model';
import { StateBestPlan } from 'src/app/shared/models/state-best-plan.model';
import { ExchangeCompany } from 'src/app/exch/models/exchange-company.model';
import { Tracking } from 'src/app/shared/models/tracking.model';
import { SubsetPlan } from 'src/app/shared/models/subset-plan.model';
import { compareByCarrier, compareByPlanType, compareByPremium, compareByPremiumHigh, compareByRecommended, PlanSortPipe } from '../../shared/pipes/plan-sort.pipe';
import { filterPlans, PlanFilterPipe } from '../../shared/pipes/plan-filter.pipe';
import { VisionPlanSummaryComponent } from '../vision-plan-summary/vision-plan-summary.component';
import { PlanSummaryComponent } from '../plan-summary/plan-summary.component';
import { FormsModule } from '@angular/forms';
import { CensusWidgetComponent } from '../census-widget/census-widget.component';
import { NgClass, NgIf, NgFor, AsyncPipe, CurrencyPipe } from '@angular/common';
import { CensusPopupComponent } from '../census-popup/census-popup.component';
import { AppSettingsService } from 'src/app/shared/services/app-settings.service';

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss'],
  standalone: true,
  imports: [
    NgClass,
    CensusWidgetComponent,
    NgIf,
    NgFor,
    FormsModule,
    RouterLink,
    PlanSummaryComponent,
    VisionPlanSummaryComponent,
    AsyncPipe,
    CurrencyPipe,
    PlanFilterPipe,
    PlanSortPipe,
    CensusPopupComponent
  ],
})
export class PlanListComponent implements OnInit {
  public maxPlansToCompare = 3;
  public loading!: boolean;
  public census!: Census;
  public plans!: ComparablePlan[];
  public disclaimers!: string[];
  public filter!: PlanFilter;
  public dentalFilter!: PlanFilter;
  public visionFilter!: PlanFilter;
  public sortBy!: SortOption;
  public requestId!: string;
  public sortOptions = SortOption;
  public productsAvailable!: Product[];
  public products = Product;
  public affiliate!: Observable<Affiliate | null>;
  public bestSellingPlans$!: Observable<StateBestPlan[]>;
  public enrollmentWebUrl!: string;
  public exchangeCompany!: ExchangeCompany | null;
  public maxFeaturedPlans = 3;
  public showRecommendedPlans = false;
  public hideFeaturedPlans = false;
  public showMobileFilter = false;
  public sortByPremium = false;
  public showAllPlans = false;
  public subsetPlans!: ComparablePlan[];
  public subsetFilter!: PlanFilter;
  public sortByCarrierFeature = false;
  public carrierPage!: string;
  public careington = false;
  public showCensusPopup = false;
  public defaultNumberOfPlans = 6;

  constructor(
    public router: Router,
    public plansService: PlansService,
    private censusService: CensusService,
    private affiliateService: AffiliateService,
    private route: ActivatedRoute,
    private appSettingsService: AppSettingsService
  ) {

  }

  getPlanCountByCarrier(carrier: string): number {
    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: [],
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);

    if (carrier === 'NCD') {
      return filteredPlans.filter(
        (x) =>
          x.carrier.name === 'NCD by MetLife' ||
          x.carrier.name === 'NCD Nationwide'
      ).length;
    } else if (carrier === 'MetLife') {
      return filteredPlans.filter(
        (x) =>
          x.carrier.name === 'NCD by MetLife' ||
          x.carrier.name === 'MetLife'
      ).length;
    } else if (carrier === 'Nationwide') {
      return filteredPlans.filter(
        (x) =>
          x.carrier.name === 'NCD Nationwide' ||
          x.carrier.name === 'Nationwide'
      ).length;
    } else {
      return filteredPlans.filter(
        (x) =>
          x.carrier.name === carrier &&
          x.planType.productId ===
          this.plansService.selectedFilter.productFilter[0].value
      ).length;
    }
  }

  filterSelected() {
    //this.plans.forEach(x => x.isCheckedForCompare = false);
    this.sortAndFilterPlans();
  }

  getPlanCountByType(planType: string): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: [],
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => x.planType.name === planType || x.planType.name.endsWith(planType)).length;
  }

  getPlanCountByPlanMax(min: number, max: number): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: [],
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.planMax >= min) && (max === -1 || x.planMax <= max)).length;
  }

  getPlanCountByPremium(min: number, max: number): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: [],
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.premium >= min) && (max === -1 || x.premium <= max)).length;
  }

  getPlanCountByImplantBenefit(): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: [],
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.hasImplants)).length;
  }

  getPlanCountByChildOrthoBenefit(): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: [],
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.hasChildOrtho)).length;
  }

  getPlanCountByAdultOrthoBenefit(): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: [],
      teethWhiteningBenefitFilter: this.filter.teethWhiteningBenefitFilter
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.hasAdultOrtho)).length;
  }

  getPlanCountByTeethWhiteningBenefit(): number {

    let filter: PlanFilter = {
      productFilter: this.filter.productFilter,
      planTypesFilter: this.filter.planTypesFilter,
      carriersFilter: this.filter.carriersFilter,
      premiumFilter: this.filter.premiumFilter,
      planMaxFilter: this.filter.planMaxFilter,
      implantBenefitFilter: this.filter.implantBenefitFilter,
      childOrthoBenefitFilter: this.filter.childOrthoBenefitFilter,
      adultOrthoBenefitFilter: this.filter.adultOrthoBenefitFilter,
      teethWhiteningBenefitFilter: []
    }

    let filteredPlans = filterPlans(this.plansService.plans, filter);
    return filteredPlans.filter((x) => (x.hasTeethWhitening)).length;
  }



  clearAllFilters() {
    this.filter.carriersFilter.forEach((x) => (x.isChecked = false));
    this.filter.planTypesFilter.forEach((x) => (x.isChecked = false));
    this.filter.planMaxFilter.forEach((x) => (x.isChecked = false));
    this.filter.premiumFilter.forEach((x) => (x.isChecked = false));
    this.filter.implantBenefitFilter.forEach((x) => (x.isChecked = false));
    this.filter.childOrthoBenefitFilter.forEach((x) => (x.isChecked = false));
    this.filter.adultOrthoBenefitFilter.forEach((x) => (x.isChecked = false));
    this.filter.teethWhiteningBenefitFilter.forEach((x) => (x.isChecked = false));
    //this.plans.forEach((x) => (x.isCheckedForCompare = false));
    // this.sortBy = this.sortOptions.RECOMMENDED;
    // this.plansService.sortBy = this.sortBy;
    this.sortAndFilterPlans();
  }

  seeAllPlans() {
    this.showAllPlans = true;
    this.clearAllFilters();
  }

  ngOnInit() {
    const ua = navigator.userAgent;

    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(
        ua
      )
    ) {
      this.maxPlansToCompare = 2;
    }

    const requestId = this.route.snapshot.queryParamMap.get('requestId');
    const censusEdit = this.route.snapshot.queryParamMap.get('censusEdit');


    console.log('req: ' + requestId);
    if (requestId) {
      this.censusService.getCensusFromAPI(requestId).subscribe((data) => {
        let dat = data;
        dat.census.requestedEffectiveDate = data.census.requestedEffectiveDate;
        const tracking = data.tracking;
        this.plansService.tracking = tracking;
        this.censusService.census = data.census;
        this.census = this.censusService.census;
        this.censusService.defaultCensus = false;
        this.censusService.updated = true;
        this.censusService.saveToLocalStorage();

        if (!localStorage.getItem('tracking')) {
          localStorage.setItem(
            'tracking',
            JSON.stringify(this.plansService.tracking)
          );
          localStorage.setItem('source', this.plansService.tracking.utm_source!);
          localStorage.setItem('medium', this.plansService.tracking.utm_medium!);
          localStorage.setItem('agentId', this.plansService.tracking.utm_term!);
          localStorage.setItem('content', this.plansService.tracking.utm_content!);
        }

        if (tracking && tracking.utm_campaign) {
          const affiliateId = +tracking.utm_campaign;
          this.affiliateService.affiliate_id = affiliateId;
        }
        if (censusEdit) { this.showCensusPopup = true; }
        this.init();
      });
    } else 
    {
      this.init();
    }
   
  }

  init() {
    this.census = this.censusService.census;
    this.exchangeCompany = this.censusService.exchangeCompany;

    this.affiliate = this.affiliateService.affiliate$;

    this.enrollmentWebUrl = this.appSettingsService.ENROLLMENT_WEB_URL;
    this.affiliateService.affiliate$.subscribe((x) => {
      if (x?.affiliateId! >= 2572 && x?.affiliateId! <= 2576)
        this.careington = true;
    });


    if (this.census.zip === null) {
      let storedCensus = null;
      if (localStorage.getItem('census')) {
        storedCensus = <Census>JSON.parse(localStorage.getItem('census')!);
      }

      if (!storedCensus) {
        this.router.navigateByUrl('/census');
      } else {
        this.census = storedCensus;
        this.censusService.loadFromLocalStorage();
        this.exchangeCompany = this.censusService.exchangeCompany;
        this.censusService.updated = true;

        if (localStorage.getItem('tracking')) {
          const tracking = <Tracking>(
            JSON.parse(localStorage.getItem('tracking')!)
          );
          this.plansService.tracking = tracking;

          if (tracking.utm_campaign) {
            if (window !== undefined) {
              this.affiliateService.affiliate$.subscribe((x) => {
                window.affiliate_name = x?.name;
                window.utm_campaign = tracking.utm_campaign;
              });
            }

            this.affiliateService.affiliate_id = +tracking.utm_campaign;
          }
        }
      }
    }

    if (!this.plansService.quoteResponse || this.censusService.updated) {
      this.sortBy = this.sortOptions.RECOMMENDED;
      this.plansService.sortBy = this.sortBy;
      this.showAllPlans = false;
      this.fetchPlans();
    } else {
      console.log(this.plansService.sortBy);
      this.sortBy = this.plansService.sortBy;
      this.filter = this.plansService.selectedFilter;
      this.initialize();
    }

    this.toggleCompareSections();
    this.toggleBackButton();
    this.toggleFilterButton();
  }

  getPlanCountOfCurrentProduct(): number {
    return this.plansService.plans.filter(
      (x) => x.planType.productId === this.filter.productFilter[0].value
    ).length;
  }

  fetchPlans() {
    this.loading = true;
    this.plansService.getQuoteFromAPI(this.census).subscribe(() => {
      this.initialize();
    });
  }

  isFilterApplied() {
    return (this.filter.carriersFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.planTypesFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.planMaxFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.premiumFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.implantBenefitFilter.filter(x => x.isChecked).length > 0) ||
      this.filter.childOrthoBenefitFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.adultOrthoBenefitFilter.filter(x => x.isChecked).length > 0 ||
      this.filter.teethWhiteningBenefitFilter.filter(x => x.isChecked).length > 0;
  }

  getFilteredPlanCount() {
    if (this.isFilterApplied()) {
      return filterPlans(this.plansService.plans, this.filter).length;
    } else {
      return this.plansService.plans.filter(x => x.planType.productId === this.filter.productFilter[0].value).length;
    }
  }

  sortAndFilterPlans() {
    let allPlans = this.plansService.plans;

    allPlans = filterPlans(allPlans, this.filter);
    allPlans = allPlans.sort((plan1, plan2): number => {

      // featured plan - on top
      if (plan1.isCarrierFeatured || plan2.isCarrierFeatured) {
        if (plan1.isCarrierFeatured === plan2.isCarrierFeatured) {
          return compareByCarrier(plan1, plan2);
        }
        else if (plan1.isCarrierFeatured) { return -1; }
        else if (plan2.isCarrierFeatured) { return 1; }
      }

      switch (this.sortBy) {
        case SortOption.RECOMMENDED:
          return compareByRecommended(plan1, plan2);
        case SortOption.CARRIER:
          return compareByCarrier(plan1, plan2);
        case SortOption.PLAN_TYPE:
          return compareByPlanType(plan1, plan2);
        case SortOption.PREMIUM:
          return compareByPremium(plan1, plan2);
        case SortOption.PREMIUMHIGH:
          return compareByPremiumHigh(plan1, plan2);
      }
    });

    if (!this.showAllPlans) {
      this.plans = [];
      for (let i = 0; i < this.defaultNumberOfPlans && i < allPlans.length; i++)
        this.plans.push(allPlans[i]);
    }
    else {
      this.plans = allPlans;
    }
  }

  initialize() {
    this.censusService.showLinks.next(true);

    if (this.plansService.plans.length == 0) this.router.navigate(['/no-plans-available']);

    this.checkforCarrierLandingPage();
    if (this.sortByCarrierFeature) {
      if (
        this.censusService.census.stateAbbr === 'NY' &&
        this.carrierPage == 'anthem'
      )
        this.carrierPage = 'empire';
      let shufflePlans = this.plans.filter((x) =>
        x.carrier.name.toLowerCase().includes(this.carrierPage)
      );
      shufflePlans.forEach((plan) => {
        plan.isCarrierFeatured = true;
      });
    }
    //this.showAllPlans = this.plansService.displayAllPlans;
    this.requestId = this.plansService.quoteResponse.requestId;
    this.productsAvailable = new Array<Product>();
    if (
      this.plansService.plans.find(
        (x) => x.planType.productId === Product.DENTAL
      )
    ) {
      if (this.exchangeCompany?.exchangeCompanyId != 236) {
        this.productsAvailable.push(Product.DENTAL);
        this.dentalFilter = this.createFilter(
          this.plansService.plans,
          Product.DENTAL
        );
        this.dentalFilter.productFilter.find(
          (x) => x.value === Product.DENTAL
        )!.isChecked = true;
      }
    }

    if (
      this.plansService.plans.find(
        (x) => x.planType.productId === Product.VISION
      )
    ) {
      this.productsAvailable.push(Product.VISION);
      this.visionFilter = this.createFilter(
        this.plansService.plans,
        Product.VISION
      );
      this.visionFilter.productFilter.find(
        (x) => x.value === Product.VISION
      )!.isChecked = true;
    }

    if (
      this.plansService.selectedFilter &&
      !this.censusService.updated &&
      !this.plansService.displayAllPlans
    ) {
      this.filter = this.plansService.selectedFilter;
    } else {
      this.filter = this.dentalFilter;
      this.plansService.selectedFilter = this.filter;
    }

    this.disclaimers = this.plansService.disclaimers;



    this.sortAndFilterPlans();
    this.censusService.updated = false;
    this.loading = false;

    if (
      this.exchangeCompany &&
      this.censusService.exchangeVision
    ) {
      this.censusService.exchangeVision = true;
      this.censusService.exchangeDental = false;
      this.onSelectVisionClick();
    }
  }

  comparePlansCount(): number {
    return this.plansService.plans
      ? this.plansService.plans.filter((p) => p.isCheckedForCompare).length
      : 0;
  }

  onSelectDentalClick() {
    if (
      this.exchangeCompany &&
      this.censusService.exchangeExistingCoverage === undefined
    ) {
      this.censusService.exchangeDental = true;
      this.censusService.exchangeVision = true;
      this.router.navigate(['/census'], { fragment: 'PreviousCoverage' });
    }
    //this.showAllPlans = true;
    this.plans = this.plansService.plans;
    this.filter = this.dentalFilter;
    this.plansService.selectedFilter = this.filter;
    this.sortBy = this.plansService.sortBy;
    this.plans.forEach((x) => (x.isCheckedForCompare = false));
    this.sortAndFilterPlans();
  }

  onSelectVisionClick() {
    this.filter = this.visionFilter;
    this.plansService.selectedFilter = this.filter;
    this.plans.forEach((x) => (x.isCheckedForCompare = false));
    this.sortBy = this.sortOptions.RECOMMENDED;
    this.sortAndFilterPlans();
  }

  onSortByModelChange(event: Event) {
    this.plansService.sortBy = this.sortBy;

    this.sortAndFilterPlans();
  }

  private createFilter(plans: Plan[], productId: number): PlanFilter {
    const filter = new PlanFilter();
    plans = plans.filter((x) => x.planType.productId === productId);

    filter.productFilter = new Array<FilterOption>();
    plans.forEach((plan) => {
      if (
        filter.productFilter.filter((x) => x.value === plan.planType.productId)
          .length === 0
      ) {
        filter.productFilter.push(
          new FilterOption(
            plan.planType.productId.toString(),
            plan.planType.productId,
            false
          )
        );
      }
    });

    filter.carriersFilter = new Array<FilterOption>();
    plans.forEach((plan) => {
      if (
        filter.carriersFilter.filter((x) => plan.carrier.name.includes(x.value))
          .length === 0
      ) {
        if (plan.carrier.shortName.includes('NCD'))
          filter.carriersFilter.push(new FilterOption('NCD', 'NCD', false));
        else
          filter.carriersFilter.push(
            new FilterOption(plan.carrier.name, plan.carrier.name, false)
          );
      } else {
        if (
          filter.carriersFilter.filter((x) =>
            plan.carrier.name.startsWith(x.value)
          ).length === 0
        ) {
          if (plan.carrier.name.startsWith('NCD'))
            filter.carriersFilter.push(new FilterOption('NCD', 'NCD', false));
        }
      }
    });

    filter.carriersFilter.sort((a, b) => a.label.localeCompare(b.label));

    filter.planTypesFilter = new Array<FilterOption>();
    plans.forEach((plan) => {
      if (
        filter.planTypesFilter.filter(
          (x) => plan.planType.name.endsWith(x.value)
        ).length === 0
      ) {
        if (plan.planType.name.includes('+Vision'))
          filter.planTypesFilter.push(new FilterOption('Bundled', '+Vision', false));
        else filter.planTypesFilter.push(new FilterOption(plan.planType.name, plan.planType.name, false))
      }
    });

    filter.premiumFilter = new Array<FilterOption>();
    filter.premiumFilter.push(
      new FilterOption('Under $30', { min: 0, max: 29.99 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$30 - $50', { min: 30, max: 50 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$50 - $75', { min: 50, max: 75 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$75 - $125', { min: 75, max: 125 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$125 - $175', { min: 125, max: 175 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('Over $175 ', { min: 175.01, max: -1 }, false)
    );

    filter.planMaxFilter = new Array<FilterOption>();
    filter.planMaxFilter.push(
      new FilterOption('Under $2000', { min: 0, max: 1999 }, false)
    );
    filter.planMaxFilter.push(
      new FilterOption('$2000 - $5000', { min: 2000, max: 5000 }, false)
    );
    filter.planMaxFilter.push(
      new FilterOption('$5000 and over', { min: 5000, max: -1 }, false)
    );

    filter.implantBenefitFilter = new Array<FilterOption>();
    filter.implantBenefitFilter.push(
      new FilterOption('Implants', 'Implants', false)
    );
    filter.childOrthoBenefitFilter = new Array<FilterOption>();
    filter.childOrthoBenefitFilter.push(
      new FilterOption('Child Orthodontia', 'Child Orthodontia', false)
    );
    filter.adultOrthoBenefitFilter = new Array<FilterOption>();
    filter.adultOrthoBenefitFilter.push(
      new FilterOption('Adult Orthodontia', 'Adult Orthodontia', false)
    );
    filter.teethWhiteningBenefitFilter = new Array<FilterOption>();
    filter.teethWhiteningBenefitFilter.push(
      new FilterOption('Teeth Whitening', 'Teeth Whitening', false)
    );

    return filter;
  }

  getPlanUrl(plan: Plan | undefined) {
    // tslint:disable-next-line: max-line-length
    return (
      '../' +
      plan?.carrier.name.toLowerCase().split(' ').join('-') +
      '/' +
      plan?.name
        .toLowerCase()
        .split(' ')
        .join('-')
        .split(')')
        .join('')
        .split('(')
        .join('')
        .split('+')
        .join('-')
        .split('/')
        .join('-')
        .split(',')
        .join('')
    );
  }

  getCarrierLogoPath(carrierShortName: string): string {
    return `assets/img/carrier-logos/${carrierShortName.toLowerCase()}-small.png`;
  }

  getPlanForBestseller(
    bestSellerPlan: StateBestPlan | undefined
  ): ComparablePlan | undefined {
    return this.plansService.plans.find(
      (p) => p.planId === bestSellerPlan?.planId
    );
  }

  filterTogglerClick() {
    this.showMobileFilter = !this.showMobileFilter;
  }

  onSelectAllPlans(): void {
    this.plansService.displayAllPlans = true;
    this.showAllPlans = this.plansService.displayAllPlans;
    this.sortAndFilterPlans();
  }

  onSelectLessPlans(): void {
    this.plansService.displayAllPlans = false;
    this.showAllPlans = this.plansService.displayAllPlans;
    this.sortAndFilterPlans();

    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const isFirefox = navigator.userAgent.toLowerCase().includes('firefox');
    if (isFirefox) {
      window.scroll(0, 0);
    }
  }

  getSubSetPlans(affiliateId: number, stateAbbr: string | null) {
    this.plansService
      .getSubsetPlans(affiliateId, stateAbbr)
      .subscribe((set) => {
        this.subsetPlans = set;
        this.plansService.subset = this.subsetPlans;
      });
  }

  private createSubFilter(
    plans: Plan[],
    subsetplans: SubsetPlan[]
  ): PlanFilter {
    const filter = new PlanFilter();
    //plans = plans.filter(array => subsetplans.some(filter => filter.planId === array.planId));

    filter.carriersFilter = new Array<FilterOption>();
    plans.forEach((plan) => {
      if (
        filter.carriersFilter.filter((x) => plan.carrier.name.includes(x.value))
          .length === 0
      ) {
        // if (plan.carrier.name.includes("Nationwide"))
        //   filter.carriersFilter.push(new FilterOption("Nationwide", "Nationwide", false));
        if (plan.carrier.name.includes('NCD'))
          filter.carriersFilter.push(new FilterOption('NCD', 'NCD', false));
        else
          filter.carriersFilter.push(
            new FilterOption(plan.carrier.name, plan.carrier.name, false)
          );
      }
    });

    filter.carriersFilter.sort((a, b) => a.label.localeCompare(b.label));

    filter.planTypesFilter = new Array<FilterOption>();
    plans.forEach((plan) => {
      if (
        filter.planTypesFilter.filter(
          (x) => x.value === plan.planType.planTypeId
        ).length === 0
      ) {
        filter.planTypesFilter.push(
          new FilterOption(plan.planType.name, plan.planType.planTypeId, false)
        );
      }
    });

    filter.premiumFilter = new Array<FilterOption>();
    filter.premiumFilter.push(
      new FilterOption('Under $30', { min: 0, max: 29.99 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$30 - $50', { min: 30, max: 50 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$50 - $75', { min: 50, max: 75 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$75 - $125', { min: 75, max: 125 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('$125 - $175', { min: 125, max: 175 }, false)
    );
    filter.premiumFilter.push(
      new FilterOption('Over $175 ', { min: 175.01, max: -1 }, false)
    );

    filter.planMaxFilter = new Array<FilterOption>();
    filter.planMaxFilter.push(
      new FilterOption('Under $2000', { min: 0, max: 1999 }, false)
    );
    filter.planMaxFilter.push(
      new FilterOption('$2000 - $5000', { min: 2000, max: 5000 }, false)
    );
    filter.planMaxFilter.push(
      new FilterOption('$5000 and over', { min: 5000, max: -1 }, false)
    );

    return filter;
  }

  checkforCarrierLandingPage() {
    if (this.plansService.previousPage && !this.showRecommendedPlans) {
      if (this.plansService.previousPage.length > 0) {
        const availableCarrierPages = [
          'ameritas',
          'anthem',
          'careington',
          'california-dental',
          'delta-dental',
          'dental-health-services',
          'dominion-national',
          'guardian',
          'humana',
          'metlife',
          'nationwide',
          'renaissance',
        ];
        let page = this.plansService.previousPage;
        let checker = page.split('=');
        checker.splice(0, 1);
        if (checker.length == 1) {
          if (availableCarrierPages.includes(checker[0])) {
            this.carrierPage = checker[0].split('-').join(' ');
            this.sortByCarrierFeature = true;
          }
        } else if (
          availableCarrierPages.includes(this.plansService.carrierPage)
        ) {
          this.carrierPage = this.plansService.carrierPage.split('-').join(' ');
          this.sortByCarrierFeature = true;
        }
      }
    }
  }

  //Menu body toggled(show/hide) if user click on arrow up/down
  toggleMenuBody(
    divMenuBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isHidden = divMenuBodyExpanded.style.display === 'none';
    divMenuBodyExpanded.style.display = isHidden ? 'block' : 'none';
    imgElement.src = isHidden
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }

  toggleCompareSections() {
    const closedWrapper = document.getElementById('compare-wrapper-closed');
    const openedWrapper = document.getElementById('compare-wrapper-opened');

    const compOpenImage = document.getElementById('comp-open');
    const compCloseDesktopImage = document.getElementById('comp-close-desktop');
    const compCloseMobileImage = document.getElementById('comp-close');
    const compareWrapperClosed = document.getElementById('compare-wrapper-closed');
    const compareWrapperOpened = document.getElementById('compare-wrapper-opened');
    const closeComparisonToolMobile = document.getElementById('opened-header');
    const openComparisonToolMobile = document.getElementsByClassName('mobile-open')


    openedWrapper!.style.display = 'none';

    if (
      closedWrapper &&
      openedWrapper &&
      compOpenImage &&
      compCloseDesktopImage &&
      compCloseMobileImage
    ) {
      compOpenImage.addEventListener('click', () => {
        closedWrapper.style.display = 'none';
        openedWrapper.style.display = 'flex';
      });

      compCloseDesktopImage.addEventListener('click', () => {
        openedWrapper.style.display = 'none';
        closedWrapper.style.display = 'flex';
      });

      compCloseMobileImage.addEventListener('click', () => {
        openedWrapper.style.display = 'none';
        closedWrapper.style.display = 'flex';
      });

      for (let i = 0; i < openComparisonToolMobile.length; i++) {
        openComparisonToolMobile[i].addEventListener('click', () => {
          compareWrapperClosed!.style.display = 'none';
          compareWrapperOpened!.style.display = 'flex';
        }, false);
      }

      closeComparisonToolMobile!.addEventListener('click', () => {
        compareWrapperClosed!.style.display = 'flex';
        compareWrapperOpened!.style.display = 'none';
      });
    }
  }

  toggleBackButton() {
    const backButton = document.getElementById('back-button');
    const resultsSection = document.getElementById('results');
    const filtersSection = document.getElementById('filter-fixed');

    if (backButton && resultsSection && filtersSection) {
      backButton.addEventListener('click', () => {
        resultsSection.style.display = 'block';
        filtersSection.style.display = 'none';
      });
    }
  }

  toggleFilterButton() {
    const filterButton = document.getElementById('filter-btn');
    const resultsSection = document.getElementById('results');
    const filtersSection = document.getElementById('filter-fixed');

    if (filterButton && resultsSection && filtersSection) {
      filterButton.addEventListener('click', () => {
        resultsSection.style.display = 'none';
        filtersSection.style.display = 'block';
      });
    }
  }

  toggleDisclaimerBody(
    divDisclaimerBodyExpanded: HTMLElement,
    imgElement: HTMLImageElement
  ) {
    const isShowed = divDisclaimerBodyExpanded.style.display === 'none';
    divDisclaimerBodyExpanded.style.display = isShowed ? 'block' : 'none';
    imgElement.src = isShowed
      ? '../../../assets/img/svg/arrow-up.svg'
      : '../../../assets/img/svg/arrow-down.svg';
  }

  plansForCompare(): ComparablePlan[] {
    return this.plansService.plans.filter((x) => x.isCheckedForCompare === true);
  }

  removePlanFromCompare(plan: ComparablePlan) {
    plan.isCheckedForCompare = false;
  }

  getSelectedFilters(): FilterOption[] {
    let filters: FilterOption[] = new Array();
    this.filter.carriersFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.planTypesFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.premiumFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.planMaxFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.implantBenefitFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.childOrthoBenefitFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.adultOrthoBenefitFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });

    this.filter.teethWhiteningBenefitFilter
      .filter((y) => y.isChecked)
      .forEach((x) => {
        filters.push(x);
      });
    return filters;
  }

  removeFilter(option: FilterOption) {
    option.isChecked = false;
    this.sortAndFilterPlans();
  }

  comparePlans() {
    this.router.navigateByUrl('/plans/compare');
  }

  censusPopupClick() {
    if (this.exchangeCompany) {
      this.router.navigateByUrl('/census');
    } else {
      this.showCensusPopup = true;
    }
  }

  onCensusPopupClosed(): void {
    this.showCensusPopup = false;
    if (this.censusService.updated) {
      this.census = this.censusService.census;
      this.fetchPlans();
    }
  }


}
