<section class="common-procedures mt-10">
  <div class="plan-subtitles-w-caret-container"
    (click)="togglePlanDetailsElement(planBodyCommonProcedure, imgElementCommonProcedure)">
    <span class="subtitle-text">Common Procedures</span>
    <img src="assets/img/svg/arrow-down.svg" alt="up" #imgElementCommonProcedure />
  </div>
  <div class="procedures-table" #planBodyCommonProcedure [style.display]="'none'">
    <div class="procedures-item">
      <span class="procedure">Cleaning:</span>
      <span class="description">{{plan.cleaning}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.cleaning !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">White filling:</span>
      <span class="description">{{plan.filling}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.filling !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Crown:</span>
      <span class="description">{{plan.crown}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.crown !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Root Canals:</span>
      <span class="description">{{plan.rootCanal}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.rootCanal !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Simple Extraction:</span>
      <span class="description">{{plan.simpleExtractions}} <span
          *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo' && plan.simpleExtractions !== 'Not covered'">of
          maximum allowable charge*</span></span>
    </div>
    <div class="procedures-item">
      <span class="procedure">Plan Maximum:</span>
      <div class="description" [innerHTML]="plan.planMaximumDetails">
      </div>
    </div>
    <div class="procedures-item">
      <span class="procedure">Waiting Periods:</span>
      <div class="description">
        <strong><span [innerHTML]="plan.waitingPeriods"></span></strong>
        <p *ngIf="plan.carrier.shortName.toLowerCase() === 'metlife' && plan.planType.name.toLowerCase() === 'ppo'">
          <em>Waiting Periods: For Vermont residents, any applicable waiting periods are limited to a maximum of 6
            months. For Maine
            residents, waiting periods do not apply to children under 19 years of age. Once enrolled, this will be
            reflected in your policy.</em>
        </p>
      </div>
    </div>
    <div class="procedures-item">
      <span class="procedure">Deductible</span>
      <span class="description" [innerHTML]="plan.deductible"></span>
    </div>
    <div class="observations-section">
      <span [innerHTML]="plan.benefitsNotes"></span>
    </div>
  </div>
</section>
<section class="additional-details mt-10">
  <div class="plan-subtitles-w-caret-container" (click)="togglePlanDetailsElement(planBodyDetails, imgElementDetails)">
   
    <span  *ngIf="plan.planType.name.includes('+Vision')" class="subtitle-text">Dental Details</span>
   
    <span   *ngIf="!plan.planType.name.includes('+Vision')" class="subtitle-text">Details</span>
    <img src="assets/img/svg/arrow-down.svg" #imgElementDetails />
  </div>

  <div #planBodyDetails [style.display]="'none'">
    <a class="dentist-search" *ngIf="plan.feeScheduleUrl"
      href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/fee_schedules/{{plan.feeScheduleUrl}}"
      target="_blank">{{(plan.carrier.shortName.toLowerCase()==="metlife") ? "Full Schedule of Benefits" : "Fee
      Schedule"}}</a>
    <span *ngIf="!plan.feeScheduleUrl">Fee Schedule: N/A</span>&nbsp;&nbsp;|&nbsp;&nbsp;<a class="dentist-search"
      *ngIf="plan.dentistSearchUrl" href="{{plan.dentistSearchUrl}}" target="_blank">Dentist Search</a><span
      *ngIf="!plan.dentistSearchUrl">Dentist
      Search: N/A</span>

    <div class="procedures-table">
      <div class="procedures-item">
        <span class="procedure">Exams:</span>
        <span class="description" [innerHTML]="plan.examsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Cleanings:</span>
        <span class="description" [innerHTML]="plan.regularCleaningsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">X-Rays:</span>
        <span class="description" [innerHTML]="plan.xRaysDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Fillings:</span>
        <span class="description" [innerHTML]="plan.fillingsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Extractions:</span>
        <span class="description" [innerHTML]="plan.extractionsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Crowns:</span>
        <span class="description" [innerHTML]="plan.crownsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Root Canals:</span>
        <span class="description" [innerHTML]="plan.rootCanalDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Dentures:</span>
        <span class="description" [innerHTML]="plan.denturesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Deep Cleanings:</span>
        <span class="description" [innerHTML]="plan.deepCleaningsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Implants:</span>
        <span class="description" [innerHTML]="plan.implantsDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Braces / Orthodontia:</span>
        <span class="description" [innerHTML]="plan.bracesDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Teeth Whitening:</span>
        <span class="description" [innerHTML]="plan.teethWhiteningDetails"></span>
      </div>
      <div class="procedures-item">
        <span class="procedure">Additional Network Information:</span>
        <span class="description">
          <span [innerHTML]="plan.dentistNetworkDetails"></span>
          <ng-container *ngIf="plan.carrier.shortName.toLowerCase() === 'cigna' || plan.carrier.shortName.toLowerCase() === 'aetna'">
            <a *ngIf="plan.exclusionsAndLimitationsUrl && plan.exclusionsAndLimitationsUrl.toLowerCase().startsWith('http'); else internalExclusions"
            href="{{plan.exclusionsAndLimitationsUrl}}" target="_blank">Exclusions and Limitations / Outline of Coverage</a>           
          </ng-container> 
          <ng-container *ngIf="plan.carrier.shortName.toLowerCase() != 'cigna' && plan.carrier.shortName.toLowerCase() != 'aetna'">
          <a *ngIf="plan.exclusionsAndLimitationsUrl && plan.exclusionsAndLimitationsUrl.toLowerCase().startsWith('http'); else internalExclusions"
           href="{{plan.exclusionsAndLimitationsUrl}}" target="_blank">Exclusions and Limitations</a>
          </ng-container>       
          <ng-template #internalExclusions>
            <a *ngIf="plan.exclusionsAndLimitationsUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/exclusions_and_limitations/{{plan.exclusionsAndLimitationsUrl}}"
              target="_blank">Exclusions and Limitations</a>
            <span *ngIf="!plan.exclusionsAndLimitationsUrl">Exclusions and Limitations: N/A</span>
          </ng-template>
          <br />
          <ng-container *ngIf="plan.carrier.shortName.toLowerCase() === 'deltadentaltn'">
            <a href="/assets/pdfs/deltadentaltn/privacy_policies/hipaa_notice.pdf" target="_blank">HIPAA Privacy
              Notice</a><br>
          </ng-container>
          <ng-container
            *ngIf="!(plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'ameritasny')">
            <a *ngIf="plan.privacyPolicyUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/privacy_policies/{{plan.privacyPolicyUrl}}"
              target="_blank">Privacy Policy</a>
            <span *ngIf="!plan.privacyPolicyUrl">Privacy
              Policy: N/A</span>
          </ng-container>
          <br />
          <a *ngIf="plan.planBrochureUrl && plan.planBrochureUrl.toLowerCase().startsWith('http'); else internalBrochure"
            href="{{plan.planBrochureUrl}}" target="_blank">Plan Brochure</a>
          <ng-template #internalBrochure>
            <a *ngIf="plan.planBrochureUrl"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/plan_brochures/{{plan.planBrochureUrl}}"
              target="_blank">Plan Brochure</a>
            <span *ngIf="!plan.planBrochureUrl">Plan Brochure: N/A</span>
          </ng-template>
          <br />
          <ng-container
            *ngIf="plan.carrier.shortName.toLowerCase() === 'ameritas' || plan.carrier.shortName.toLowerCase() === 'humana' || plan.carrier.shortName.toLowerCase() === 'metlife' 
            || plan.carrier.shortName.toLowerCase() === 'guardian' || plan.carrier.shortName.toLowerCase() === 'ameritasny'">
            <a *ngIf="plan.dentalBenefitsUrl && plan.carrier.shortName.toLowerCase() != 'ameritasny'"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/state_matrix/{{plan.dentalBenefitsUrl}}"
              target="_blank">Summary of Dental Benefits and Coverage Disclosure Matrix</a>
             <a *ngIf="plan.dentalBenefitsUrl && plan.carrier.shortName.toLowerCase() === 'ameritasny'"
              href="/assets/pdfs/{{plan.carrier.shortName.toLowerCase()}}/state_matrix/{{plan.dentalBenefitsUrl}}"
              target="_blank">NY Sample Policy</a>
              <br>
          </ng-container>
        </span>
      </div>
      <div *ngIf="plan.benefitsDetailsNotes" class="procedures-item">
        <span class="procedure">Notes:</span>
        <span class="description" [innerHTML]="plan.benefitsDetailsNotes"></span>
      </div>
    </div>
  </div>
</section>