import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ExchangeService } from '../exchange.service';
import { ExchangeCompanyVariation } from '../models/exchange-company-variation.model'
import { ExchangeCompany } from '../models/exchange-company.model';
import { AffiliateService } from 'src/app/shared/services/affiliate.service';
import { Affiliate } from 'src/app/shared/models/affiliate.model';
import { CensusService } from 'src/app/shared/services/census.service';
import { PlansService } from 'src/app/shared/services/plans.service';
import { Tracking } from 'src/app/shared/models/tracking.model';
import { FormsModule } from '@angular/forms';
import { NgIf } from '@angular/common';

@Component({
    selector: 'app-customer-validation',
    templateUrl: './customer-validation.component.html',
    styleUrls: ['./customer-validation.component.scss'],
    standalone: true,
    imports: [NgIf, FormsModule]
})
export class CustomerValidationComponent implements OnInit {

  constructor(private router: Router, private activatedRoute: ActivatedRoute,
    private exchangeService: ExchangeService, private affiliateService: AffiliateService,
    private censusService: CensusService,
    private plansService: PlansService) { }

  public hideFooter = false;
  public hideHeader = false;
  public companyVariation!: string;
  public allCompanyVariations!: ExchangeCompanyVariation[];
  public showNotFoundError!: boolean;
  public selectedCompany!: ExchangeCompany | null;
  public logo!: string | null;
  public phone!: string | null;
  public hoursOfOperation!: string | null;
  public loading!: boolean;
  public agentName!: string;
  public callCenterId!: number;
  public noInputError!: boolean;
  public previousUrl!: string;
  public showNotAvailableError!: boolean;

  ngOnInit(): void {

    if (this.plansService.tracking && this.plansService.tracking.utm_term) {
      this.loading = true;
      this.agentName = this.plansService.tracking.utm_term;
      this.exchangeService.getCallCenterId(this.agentName).subscribe(x => {
        this.loading = false;
        this.callCenterId = x;
      });
    }
    this.previousUrl = this.plansService.previousPage; console.log('previous url: ', this.previousUrl);
    this.censusService.showLinks.next(false);
    this.censusService.exchange = true;
    this.censusService.exchObs.next(true);
    this.btnResetClick();
  }

  btnContinueClick(): void {
    this.showNotFoundError = false;
    this.showNotAvailableError = false;
    this.noInputError = false;
    const entered = this.toLowerRemoveSpaces(this.companyVariation);
    if (entered.length > 0){
    this.loading = true;
    this.exchangeService.getCompanyByNameFromAPI(entered).subscribe(x => {
      this.loading = false;

      let found: ExchangeCompanyVariation | null | undefined = x.find(xx =>
        this.toLowerRemoveSpaces(xx.exchangeCompany.name) === entered ||
        this.toLowerRemoveSpaces(xx.name) === entered);

      if (this.callCenterId !== undefined && this.callCenterId != null) {
        if (found?.exchangeCompany.exchangeCampaign.callCenterId !== this.callCenterId &&
          found?.exchangeCompany.exchangeCampaign.overflowCallCenterId !== this.callCenterId) {
          found = null;
        }
      }
      if (found && (found.exchangeCompany.exchangeCampaign.name.includes("Conduent") && this.callCenterId != 4))
      {          
            found = null;
            this.showNotAvailableError = true;            
      }       
      if (!found) {
        this.showNotFoundError = true;
        if (this.previousUrl.includes("mercermarketplace.com") || this.previousUrl.includes("retiree.premedicareplans.com")
                || this.previousUrl.includes("quest.premedicareplans.com") || this.previousUrl.includes("comcastnbcu.premedicareplans.com") || this.previousUrl.includes("dev.dentalinsurance.com"))
      {
        this.plansService.tracking.exchangeCompanyName = entered;
        this.logo = 'assets/img/exchange/mercer-logo.jpg';
        this.phone = '888-434-1144';
        this.hoursOfOperation = '8AM to 5:30PM M-F EST';
        this.showNotFoundError = false;
      }
      else if (this.showNotAvailableError)
        this.showNotFoundError = false;
      } else {

        if (!this.plansService.tracking) {
          this.plansService.tracking = new Tracking();
        }
        this.plansService.tracking.exchangeCompanyId = found.exchangeCompany.exchangeCompanyId;
        this.plansService.tracking.exchangeCompanyName = found.exchangeCompany.name;
        this.selectedCompany = found.exchangeCompany;

        if (this.selectedCompany.logo) {
          this.logo = 'assets/img/exchange/' + this.selectedCompany.logo;
        } else {
          this.logo = 'assets/img/exchange/' + this.selectedCompany.exchangeCampaign.logo;
        }

        if (this.selectedCompany.phone) {
          this.phone = this.selectedCompany.phone;
        } else {
          this.phone = this.selectedCompany.exchangeCampaign.phone;
        }

        if (this.selectedCompany.hoursOfOperation) {
          this.hoursOfOperation = this.selectedCompany.hoursOfOperation;
        } else {
          this.hoursOfOperation = this.selectedCompany.exchangeCampaign.hoursOfOperation;
        }
      }
    });
  } else { this.noInputError = true; }
  }


  btnResetClick(): void {
    this.selectedCompany = null;
    this.phone = null;
    this.logo = null;
    this.hoursOfOperation = null;
    this.showNotFoundError = false;
    this.companyVariation = '';
    this.censusService.exchCompany.next(null);
  }

  btnDentalClick(): void {
    this.censusService.exchange = true;
    this.censusService.exchangeDental = true;
    this.censusService.exchangeVision = false;
    this.censusService.exchangeCompany = this.selectedCompany;
    this.censusService.callCenterId = this.callCenterId;
    this.censusService.exchCompany.next(this.censusService.exchangeCompany);
    this.censusService.saveToLocalStorage();
    this.router.navigateByUrl('/census');
  }

  btnVisionClick(): void {
    this.censusService.exchange = true;
    this.censusService.exchangeDental = false;
    this.censusService.exchangeVision = true;
    this.censusService.exchangeCompany = this.selectedCompany;
    this.censusService.callCenterId = this.callCenterId;
    this.censusService.exchCompany.next(this.censusService.exchangeCompany);
    this.censusService.saveToLocalStorage();
    this.router.navigateByUrl('/census');
  }

  toLowerRemoveSpaces(input: string): string {
    return input.replace(/[^0-9a-z]/gi, '').toLowerCase();
  }

}
