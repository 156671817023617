import { AbstractControl, ValidationErrors, ValidatorFn } from "@angular/forms";

// The AgeRangeValidator function takes two parameters: 
// - min: The minimum age allowed.
// - max (optional): The maximum age allowed. If not provided, there's no upper age limit.

export function AgeRangeValidator(min: number, max?: number): ValidatorFn {

  // The outer function returns an inner function, which is the actual validator function.
  return (control: AbstractControl): ValidationErrors | null => {

    // Calculate the age based on the birthdate provided in the form control.
    const age = new Date().getFullYear() - new Date(control.value).getFullYear();

    // Check if age is not a valid number or if it's less than the minimum age or greater than the maximum age (if provided).
    if (isNaN(age) || age < min || (max && age > max)) {

      // If any of the conditions are met, return a validation error object with the key 'ageRange'.
      return { 'ageRange': true };
    }

    // If all conditions pass, return null, indicating that the input is valid.
    return null;
  };
}